/* ====================================================
Font
==================================================== */
$font-gothic: -apple-system, blinkMacSystemFont, Meiryo, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$font-mincho: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;

@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}

/* ====================================================
Position & transform
==================================================== */
@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

/* ====================================================
Color
==================================================== */

/* ====================================================
Sizing
==================================================== */
$base-size: 16px;
@function px-em($target, $context: $base-size) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

@for $i from 0 through 100 {
	@media screen and (min-width: 768px) {
		.mtpc-#{$i} {
			margin-top: $i + px !important;
		}
		.mlpc-#{$i} {
			margin-left: $i + px !important;
		}
		.mrpc-#{$i} {
			margin-right: $i + px !important;
		}
		.mbpc-#{$i} {
			margin-bottom: $i + px !important;
		}
		.ptpc-#{$i} {
			padding-top: $i + px !important;
		}
		.pbpc-#{$i} {
			padding-bottom: $i + px !important;
		}
	}
	@media screen and (max-width: 767px) {
		.mtsp-#{$i} {
			margin-top: $i + px !important;
		}
		.mbsp-#{$i} {
			margin-bottom: $i + px !important;
		}
		.ptsp-#{$i} {
			padding-top: $i + px !important;
		}
		.pbsp-#{$i} {
			padding-bottom: $i + px !important;
		}
		.plsp-#{$i} {
			padding-left: $i + px !important;
		}
		.prsp-#{$i} {
			padding-right: $i + px !important;
		}
	}
}

@for $i from 0 through 40 {
	.p-tb-#{$i} {
		padding-top: $i + px !important;
		padding-bottom: $i + px !important;
	}
}

/* ====================================================
Misc
==================================================== */
@mixin no-select {
	-khtml-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
} 

