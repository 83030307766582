.ttl-main{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		width: 100%;
		height: 6px;
		background: url(../img/price/bg_title.png);
	}
	&:after{
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 6px;
		background: url(../img/price/bg_title.png);
	}
	.ttl-price{
		background: #ffe1e9;
		min-height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fb4875;
		margin: 0 0 17.5px;
		font-family: $font-gothic;
		padding: 21px 0;
		@include font-size(36);
		@media screen and (max-width: 767px) {
			min-height: 72px;
			@include font-size(24);
		}
		&:before{
			display: block;
			content: '';
			background: url(../img/price/star-pattern-left.png) no-repeat center center;
			width: 26px;
			height: 28px;
			background-size: 100% 100%;
			top: -8px;
			left: -4px;
			position: relative;
		}
		&:after{
			display: block;
			content: '';
			background: url(../img/price/star-pattern-right.png) no-repeat center center;
			width: 21px;
			height: 29px;
			background-size: 100% 100%;
			top: 8px;
			right: -4px;
			position: relative;
		}
	}
}

.main-price{
	margin-top: 65px !important;

}
.ct-pr-label{
	margin-bottom:20px;
	.ct-txt-right{
		color: #2b2b2b;
	}
	.img-center{
		text-align: center;
	}
	@media screen and (max-width: 767px) {
		margin-bottom:25px;
	}
	.ttl{
		background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
		padding-bottom: 3px;
		margin-bottom: 20px;
		@include font-size(20);
		@media screen and (max-width: 767px) {
			padding-bottom: 5px;
			margin-bottom: 7px;
			@include font-size(14);
		}
	}
	.table-pr {
		border-collapse: collapse;
		border-spacing: 0;
		width:100%;
		color: #2b2b2b;
		@include font-size(14);
		margin-bottom: 20px;
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
			@include font-size(12);
		}
		td{
			border: 1px solid #fb4875;
			padding:1px 9px;
			height:27px;
		}
		th{
			text-align: center;
			background-color: #ffe1e9;
			border: 1px solid #fb4875;
			padding:1px 9px;
			height:27px;
		}
	}
	.txt-price{
		@include font-size(16);
		color: #2b2b2b;
		@media screen and (max-width: 767px) {
			@include font-size(12);
		}
	}
	.list-txt{
		color: #2b2b2b;
		li{
			position: relative;
			@include font-size(16);
			padding-left: 10px;
			@media screen and (max-width: 767px) {
				padding-left: 5px;
			}
			@media screen and (max-width: 767px) {
				padding-left: 12px;
				@include font-size(12);
			}
			&:before{
				content: "*";
				position: absolute;
				left:0;
			}
		}
	}
	.btn{
		padding: 10px 110px;
		margin-top:30px;
		@include font-size(20);
		@media screen and (max-width: 767px) {
			padding: 10px !important;
			width: 100%;
			margin-top:15px;
			max-width: 375px;
			@include font-size(18);
		}
	}
	.link-pr{
		padding-left: 10px;
		position: relative;
		margin-top: 40px;
		margin-bottom: 60px;
		@media screen and (max-width: 767px) {
			margin-top: 20px;
			margin-bottom: 33px;
		}
		&:before{
			display: block;
			content: '';
			width: 6px;
			height: 6px;
			background-color: transparent;
			border-bottom: 2px solid #ff006e;
			border-right: 2px solid #ff006e;
			position: absolute;
			top: 50%;
			transform:translateY(-50%) rotate(-45deg);
			left: -4px;
		}
		a{
			color: #ff006e;
			text-decoration: underline;
			transition:all 0.3s;
			color: #ff006e;
			@include font-size(16);
			@media screen and (max-width: 767px) {
				@include font-size(12);
			}
			&:hover{
				text-decoration: none;
				opacity: .7;
				transition:all 0.3s;
			}
		}
	}
}

.color-txt{
	color: #fb4875;
}
.list-site-link{
	margin-bottom: 50px;
	margin-top: -4px;
	@media screen and (max-width: 767px) {
		margin-bottom: 30px;
	}
	li{
		margin-bottom: 15px;
		a{
			color: #fb4875;
			text-decoration: underline;
			font-size: 18px;
			@media screen and (max-width: 767px) {
				@include font-size(12);
			}
		}
	}
}
.color-black{
	color: #000000 !important;
}

@media screen and(min-width: 768px){
  .twoCol{
	&:after{
	  content: '';
	  display: block;
	  clear: both;
	}
	li{
	  float: left;
	  width: 50%;
	  &:nth-child(2n){
		float: right;
	  }
	}
  }
}

