.page-lineup_index {
	.dance-list-02 {
		margin-bottom: 20px;
		@media screen and (max-width: 767px) {
			margin-bottom: 0;
			padding: 0;
		}
		.txt-top {
			text-decoration: underline;
		}
		.items {
			@media screen and (min-width: 768px) {
				width: 31.33%;
				margin-right: 2%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			a {
				.img {
					@media screen and (min-width: 768px) {
						width: 48%;
					}
					/*@media screen and (min-width: 768px) and (max-width: 1039px){
						width: 200px;
					}*/
				}
				.desc {
					@media screen and (min-width: 768px) {
						width: calc(50% - 10px);
						.txt-top {
							margin-bottom: 5px;
							@include font-size(16);
						}
					}
					/*@media screen and (min-width: 768px) and (max-width: 1039px){
						/width: calc(100% - 210px);
					}*/
					.txt-bottom {
						@include font-size(14);
						@media screen and (max-width: 767px) {
							@include font-size(13);
						}
					}
				}
			}
		}
	}
	.ico-center {
		@media screen and (min-width: 768px) {
			&:before, &:after {
				@include v-align;
			}
		}
	}
	.caption-lg.h-80 {
		padding: 15px 10px;
		@media screen and (max-width: 767px) {
			padding: 17px 10px 16px;
		}
	}
	.bg_pink {
		.banner-sm {
			max-width: 335px;
			@media screen and (max-width: 767px) {
				max-width: 670px;
				padding: 0 20px;
			}
		}
	}
}

.page-lineup-cheer_index {
	.lineup-hiphop-section-01 {
		&:before {
			width: 125px;
			height: 415px;
			left: 40%;
			top: 160px;
			background: url("../img/lineup/cheer/dance-girl-img.png") no-repeat top left /100%;
			@media screen and (max-width: 900px) {
				top: 170px;
			}
			@media screen and (max-width: 767px) {
				width: 100px;
				transform: translate(0, 0);
				left: 0;
				top: 20px;
			}
			@media screen and (max-width: 660px) {
				width: 50px;
				transform: translate(0, 0);
				left: 2%;
				top: 10px;
			}
			@media screen and (max-width: 395px) {
				width: 40px;
				transform: translate(0, 0);
				left: 2%;
				top: 28px;
			}
			@media screen and (max-width: 375px) {
				width: 35px;
				left: 0;
				top: 45px;
			}
		}
		.caption-lg {
			@media screen and (max-width: 767px) {
				padding: 6px 10px 8px;
			}
			.txt-while {
				@media screen and (max-width: 767px) {
					@include font-size(19);
				}
			}
		}
	}
	.index-section-04 {
		.section-ttl {
			.caption-lg {
				small {
					@media screen and (max-width: 767px) {
						@include font-size(13);
					}
				}
			}
		}
	}
	.form-container {
		.form-cta {
			.btn {
				@media screen and (max-width: 767px) {
					@include font-size(18);
				}
			}
		}
	}
}

.page-lineup-kpop_index {
	.lineup-hiphop-section-01 {
		&:before {
			width: 170px;
			height: 500px;
			left: 60%;
			top: 110px;
			background: url("../img/lineup/kpop/dance-girl-img.png") no-repeat top left /100%;
			transform: translateX(-516%);
			@media screen and (max-width: 1330px) {
				left: -5px;
				transform: translateX(0);
			}
			@media screen and (max-width: 1000px) {
				width: 80px;
				height: 236px;
			}
			@media screen and (max-width: 767px) {
				top: 32px;
				width: 55px;
				height: 163px;
			}
			@media screen and (max-width: 414px) {
				left: -2px;
			}
			@media screen and (max-width: 345px) {
				top: 55px;
				width: 45px;
			}
		}
		.caption-lg {
			padding: 15px 140px;
			@media screen and (max-width: 767px) {
				padding: 6px 35px 8px;
			}
			small {
				@media screen and (max-width: 767px) {
					font-size: 16px !important;
					font-size: 1.6rem !important;
				}
			}
			strong {
				@media screen and (max-width: 767px) {
					font-size: 19px !important;
					font-size: 1.9rem !important;
				}
			}
		}
		.caption-sm {
			@media screen and (max-width: 430px) {
				padding: 0 70px 0 85px;
				&:after {
					right: 50px;
				}
				&:before {
					left: 65px;
				}
			}
		}
	}
	.index-section-04 {
		.section-ttl {
			.caption-lg {
				small {
					@media screen and (max-width: 767px) {
						@include font-size(13);
					}
				}
			}
		}
	}
	.form-container {
		.form-cta {
			.btn {
				@media screen and (max-width: 767px) {
					@include font-size(18);
				}
			}
		}
	}
}

.page-lineup-jazz_index {
	.lineup-hiphop-section-01 {
		&:before {
			width: 246px;
			height: 415px;
			left: 55%;
			top: 140px;
			background: url("../img/lineup/jazz/dance-girl-img-pc.png") no-repeat top left /100%;
		}
		@media screen and (max-width: 767px) {
			&:before {
				width: 100px;
				transform: translate(0, 0);
				left: 0;
				top: 20px;
				background: url("../img/lineup/jazz/dance-girl-img.png") no-repeat top left /100%;
			}
		}
		@media screen and (max-width: 370px) {
			&:before {
				width: 60px;
				height: 200px;
				transform: translate(0, 0);
				left: 0;
				top: 48px;
			}
		}
		@media screen and (max-width: 324px) {
			&:before {
				width: 55px;
				height: 200px;
				transform: translate(0, 0);
				left: 0;
				top: 45px;
			}
		}
	}
}

.page-lineup-tahitian-index {
	.lineup-hiphop-section-01 {
		&:before {
			width: 150px;
			height: 420px;
			left: 40%;
			top: 110px;
			background: url("../img/lineup/tahitian/dance-girl-img-pc.png") no-repeat top left /100%;
			@media screen and (max-width: 1330px) {
				left: -5px;
				transform: translateX(0);
			}
			@media screen and (max-width: 1000px) {
				width: 80px;
				height: 236px;
			}
			@media screen and (max-width: 767px) {
				top: 32px;
				width: 55px;
				height: 163px;
			}
			@media screen and (max-width: 414px) {
				left: -2px;
			}
			@media screen and (max-width: 345px) {
				top: 55px;
				width: 45px;
			}
		}
	}
}

.page-lineup-burlesque-index {
	.lineup-hiphop-section-01 {
		.plrsp-40 {
			@media screen and (max-width: 767px) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}
		&:before {
			content: '';
			display: block;
			width: 190px;
			height: 431px;
			left: 47%;
			top: 115px;
			background: url("../img/lineup/burlesque/dance-girl-img-pc.png") no-repeat top left /100%;
			@media screen and (max-width: 1000px) {
				left: 51%;
			}
			@media screen and (max-width: 767px) {
				width: 90px;
				height: 204px;
				transform: translate(0, 0);
				left: 0;
				top: 11px;
			}
			@media screen and (max-width: 430px) {
				width: 60px;
				height: 136px;
				transform: translate(0, 0);
				left: -2px;
				top: 46px;
			}
			@media screen and (max-width: 340px) {
				width: 47px;
				height: 115px;
				transform: translate(0, 0);
				left: 0;
				top: 48px;
			}
		}
		.caption-lg {
			@media screen and (max-width: 767px) {
				.txt-sm-sp {
					@include font-size(19);
				}
			}
		}
	}
	.lineup-hiphop-section-02 {
		.caption-lg {
			@media screen and (max-width: 767px) {
				padding: 12px 10px 13px;
			}
		}
	}
	.point-list-block {
		.items {
			.info {
				.excerpt {
					.txt-bottom {
						@include font-size(16);
						@media screen and (max-width: 767px) {
							@include font-size(11);
						}
					}
				}
			}
		}
	}
}