
.main-facility
{
    margin-top: 65px !important;
    .caption-lg.h-80
    {
        padding: 15px 10px;
        @media screen and (max-width: 767px)
        {
            padding: 17px 10px 16px;
        }
    }
    .content-facility
    {
        margin-bottom: 60px;
        @media screen and (max-width: 767px)
        {
            margin-bottom: 30px;
        }
        .map-preview
        {
            max-width: 593px;
        }
        .caption-lg
        {
            position: relative;

            display: flex;

            height: 80px;
            margin-bottom: 20px;

            color: white;
            background-color: #fb4875;

            align-items: center;
            justify-content: center;
            @include font-size(36);
            @media screen and (max-width: 767px)
            {
                height: 50px;

                @include font-size(24);
            }
            &:before
            {
                position: absolute;
                bottom: calc(100% + 1px);
                left: 0;

                display: block;

                width: 100%;
                height: 1px;

                content: '';

                background-color: #fb4875;
            }
        }
        .txt
        {
            float: left;

            width: calc(100% - 200px);

            @include font-size(16);
            @media screen and (max-width: 767px)
            {
                width: calc(100% - 125px);

                @include font-size(11);
            }
        }
        .cta
        {
            float: right;

            width: 200px;
            min-height: 40px;

            text-align: right;
            @media screen and (max-width: 767px)
            {
                width: 110px;
            }
            .btn
            {
                display: inline-block;

                padding: 10px 20px 10px 6px;

                @include font-size(16);
                @media screen and (max-width: 767px)
                {
                    display: block;

                    width: 110px;

                    text-align: center;

                    @include font-size(13);
                }
                &:after
                {
                    right: 8px;
                }
            }
        }
        .txt-02
        {
            color: #2b2b2b;
        }
    }
}

@font-face
{
    font-family: hui;

    src: url('../font/HuiFontP29.ttf');
}


.content-facility
{
    .list-info-faci
    {
        li
        {
            float: left;

            width: 50%;
            margin-bottom: 35px;
            padding-right: 60px;
            &:nth-of-type(even)
            {
                float: right;
            }
            @media screen and (max-width: 767px)
            {
                width: 100%;
                margin-bottom: 0;
                padding-right: 0;
            }
            .ttl-faci
            {
                font-weight: 600;

                margin-bottom: 11px;

                color: #fb4875;

                @include font-size(20);
                @media screen and (max-width: 767px)
                {
                    @include font-size(14);
                }
                .number
                {
                    display: table-cell;

                    width: 50px;

                    vertical-align: middle;
                    @media screen and (max-width: 767px)
                    {
                        width: 32px;
                    }
                }
                .ttl
                {
                    display: table-cell;

                    padding-left: 8px;

                    vertical-align: middle;
                }
            }
            &:last-child
            {
                .img-faci
                {
                    &:after
                    {
                        display: none;
                    }
                }
            }
            .img-faci
            {
                position: relative;
                .link-faci
                {
                    font-weight: 600;

                    position: absolute;
                    top: 52%;
                    left: 50%;

                    display: flex;

                    min-height: 30px;
                    padding: 4px 8px;

                    transition: all .3s;
                    transform: translateX(-50%);

                    color: #fff;
                    background: #ff0030;

                    align-items: center;
                    @include font-size(22);
                    &:hover
                    {
                        transition: all .3s;

                        opacity: .7;
                    }
                    @media screen and (max-width: 767px)
                    {
                        min-width: 125px;

                        @include font-size(13);
                    }
                }
                &:after
                {
                    @media screen and (min-width: 768px)
                    {
                        position: absolute;
                        right: -55px;

                        width: 50px;
                        height: 42px;

                        content: '';

                        background: url('../img/facility/img-mt-pc.png') center no-repeat;
                        background-size: cover;

                        @include v-align;
                    }
                }
            }
            .img-mt
            {
                margin: 10px 0;

                text-align: center;
                @media screen and (max-width: 767px)
                {
                    img
                    {
                        height: 30px;
                    }
                }
                @media screen and (min-width: 768px)
                {
                    display: none !important;
                }
            }
        }
    }
    .list-txt
    {
        color: #2b2b2b;
        li
        {
            @include font-size(16);
            @media screen and (max-width: 767px)
            {
                @include font-size(14);
            }
        }
    }
}

.village
{
    position: relative;

    margin-bottom: 50px;
    padding: 30px 0 35px;

    color: #fb4875;
    background: #ffd0dc;

    @include font-size(34);
    @media screen and (max-width: 767px)
    {
        margin-bottom: 20px;
        padding: 15px 0 18px;

        @include font-size(17);
    }
    .v-diew
    {
        .txt
        {
            font-family: hui;

            position: relative;

            float: left;

            width: 100%;
            margin-bottom: 12px;
            &:after
            {
                position: absolute;
                top: -21px;
                right: 0;

                content: url(../img/facility/diew.png);
                transform: scale(.8);
                @media screen and (max-width: 767px)
                {
                    right: -27px;

                    transform: scale(.5);
                }
            }
            .bg-bt
            {
                line-height: 1;

                position: relative;
                z-index: 2;

                padding-bottom: 8px;

                background: url(../img/facility/img_before.png) repeat-x;
                background-position: 56% bottom;
                background-size: 100% 20px;
            }
        }
        .v-link
        {
            position: relative;

            display: block;

            padding-left: 13px;

            transition: all .3s;
            text-decoration: underline;

            color: #fb4875;

            @include font-size(16);
            &:hover
            {
                transition: all .3s;
                text-decoration: none;

                opacity: .7;
            }
            &:before
            {
                position: absolute;
                top: 39%;
                left: 0;

                display: block;

                width: 6px;
                height: 6px;

                content: '';
                transform: rotate(-45deg);

                border-right: 2px solid #ff006e;
                border-bottom: 2px solid #ff006e;
                background-color: transparent;
                @media screen and (max-width: 767px)
                {
                    top: 27%;
                }
            }
            @media screen and (max-width: 767px)
            {
                @include font-size(11);
            }
        }
        .img
        {
            float: right;

            width: 100%;
            @media screen and (max-width: 767px)
            {
            }
        }
    }
}
