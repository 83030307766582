.dvd-box {
	@include font-size(14);
	img {
		max-width: 40%;
		@media screen and (max-width: 767px) {
			max-width: 100%;
		}
	}
	p {
		margin-bottom: 20px;
	  	font-size: 12px;
	}
}

.btn {
	&.btn-small {
		@media screen and (max-width: 767px) {
			font-size: 16px !important;
		}
	}
	&.btn-arrow-dow {
		&:after {

		}
	}
}

.dvd-list-vd {
	margin: 0 -10px;
	li {
		padding: 0 10px;
		@include font-size(16);
		float: left;
		width: 33.333%;
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 20px;
		}
		img {
			width: 100%;
			margin-bottom: 10px;
		}
		.movie{
			position:relative;
			width:100%;
			padding-top:56.25%;
			margin-bottom: 10px;
			iframe{
				position:absolute;
				top:0;
				right:0;
				width:100%;
				height:100%;
			}
		}
	}
}

.color {
	&-blue-gray {
		color: #46a8bc;
	}
	&-orange {
		color: #cf9300;
	}
	&-pink {
		color: #fb4875;
	}
}

.ttl-make-choice {
	width: 100%;
	background: url("../img/dvd/line.png") no-repeat;
	background-size: 100%;
	background-position: bottom;
	padding-bottom: 20px;
	margin-bottom: 20px;
	@include font-size(16);
	@media screen and (max-width: 767px) {
		padding-bottom: 10px;
		margin-bottom: 10px;
		@include font-size(15);
	}
	small {
		@media screen and (max-width: 767px) {
			@include font-size(12);
		}
		span {
			color: #fb4875;
			display: inline-block !important;
		}
	}
}

.make-choice-list {
	.item {
		float: left;
		width: 49%;
		@include font-size(14);
		&:nth-child(2n) {
			float: right;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 30px;
			@include font-size(12);
		}
		img {
			float: left;
			width: 30%;
		}
		.item-inner{
			width: 100%;
			display: table;
		}
		.am-frame{
			display: table-cell;
			vertical-align: top;
		}
		.txt {
			display: table-cell;
			padding-left: 15px;
			vertical-align: top;
			img {
				width: auto;
				max-width: 55%;
			}
		}
		span {
			display: block;
		}
		.color {
			&-blue-gray, &-orange {
				margin-bottom: 10px;
				@include font-size(16);
				@media screen and (max-width: 767px) {
					@include font-size(15);
				}
			}
			&-pink {
				margin: 10px 0;
			}
		}
	}
}

.index-section-02 {
	&.af-none {
		&:after {
			display: none;
		}
	}
	@media screen and (max-width: 767px) {
	  padding-bottom: 27px;
	}
}

.main-visual-box {
	background: white;
	margin-bottom: 40px;
	.container {
		padding: 0 20px !important;
	}
	.main-visual-slideshow-02 {
		@media screen and (max-width: 767px) {
			margin-bottom: 10px !important;
		}
	}
  
  .color-pink {
	font-size: 15px;
	@media(min-width: 768px) {
	  font-size: 30px;
	}
  }
}

.page-dvd_1609 {
	.index-section-02 .btn-container {
	  	width: 100%;
	  	.btn-arrow {
		  padding-left: 0;
		  padding-right: 0;
		  width: 100%;
		}
	 
	}
  
  	.txt-info {
	  font-size: 16px;
	}
 
	@media screen and (max-width: 767px) {
		.section-ttl .caption-lg {
			padding: 10px 5px;
		}
	  	.point-list-block > .items .img {
		  margin-bottom: 20px;
		}
	  
	  	.txt-bottom {
		  font-size: 11px;
		}
	  
	  .txt-info {
		  font-size: 11px;
	  }
	 
	}
  
  	@media(min-width: 768px) {
	  	.point-list-block {
		  display: block;
		  overflow: hidden;
		  margin-left: -15px;
		  margin-right: -15px;
		}
	 
		.point-list-block > .items {
		  box-sizing: border-box;
		  float: left;
		  width: 50%;
		  padding: 0 15px;
		  
		  .img {
			width: 100%;
			margin: 0 auto;
			img {
			  width: 100%
			}
		  }
		}
	 
		.section-ttl .caption-sm {
		  margin-bottom: 10px;
		  display: inline-block;
		}
	}
}
@media screen and (min-width:768px){
	.point-list-block-02{
		display: block;
		overflow: hidden;
		.items{
			float: left;
			width: 30%;
			margin-right: 5%;
			margin-bottom: 5%;
			&:nth-child(2n){
				margin-right: 0;
			}
		}
	}
}
.section-ttl{
	.caption-new{
		background: #ff5262;
		color: white;
		padding: 30px 0;
		.ttl{
			display: inline-block;
			position: relative;
			padding: 0 25px 0 35px;
			@include font-size(18);
			@media screen and (max-width: 767px) {
				padding: 0 23px 0 33px;
				@include font-size(15);
			}
			&.ttl{
				&:before,&:after{
					content: "";
					position: absolute;
					@include v-align;
				}
				&:before{
					left: 0;
					width: 30px;
					height: 30px;
					background: url("../img/hawaii/bf.png")no-repeat;
					background-size: 100%;
					@media screen and (max-width: 767px) {
						width: 25px;
						height: 25px;
						left: 5px;
					}
				}
				&:after{
					right: 0;
					width: 20px;
					height: 30px;
					background: url("../img/hawaii/af.png")no-repeat;
					background-size: 100%;
					@media screen and (max-width: 767px) {
						width: 15px;
						height: 20px;
						right: 5px;
					}
				}
			}
		}
		.ttl-bottom{
			display: inline-block;
			@include font-size(36);
			line-height: 1.2;
			background: url(../img/hawaii/under_line.png) no-repeat;
			background-position: bottom center;
			background-size: 100%;
			@media screen and (max-width: 767px) {
				@include font-size(20);
			}
			@media screen and (max-width: 350px) {
				background: none;
			}
		}
	}
}
.banner-sm {
	.txt-top{
		&.bf-top{
			&:before,&:after{
				top: 50%;
			}
		}
	}
}
.w-100{
	img{
		max-width: 100% !important;
	}
}
.fs-22{
	font-size: 12px;
	@media(min-width: 768px) {
	  font-size: 22px;
	}
}
.wd-50{
	.items{
		@media screen and (min-width: 768px){
			width: 45% !important;
		}
	}
}
#sample-movie{
	padding-top: 90px;
	margin-top: -90px;
	@media screen and (max-width: 767px) {
		padding-top: 65px;
		margin-top: -65px;
	}
}