.lineup-ballet-section-01 {
	padding-top: 50px;
	position: relative;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
		.section-ttl {
			.caption-lg {
				padding: 2px 10px;
				* {
					@include font-size(19);
				}
				small{
					letter-spacing: -1px;
				}
				strong{
					@include font-size(20);
				}
			}
		}
	}
	&:before {
		display: block;
		content: '';
		background: url("../img/lineup/ballet/dance-girl-img.png") no-repeat center center;
		width: 219px;
		height: 496px;
		background-size: 100% 100%;
		position: absolute;
		top: 45px;
		left: 49%;
		z-index: 1;
		transform: translateX(-277%);
		@media screen and (min-width: 860px) and (max-width: 1040px){
			width: 160px;
			height: 380px;
			top: 173px;
			left: 52%;
		}
		@media screen and (min-width: 768px) and (max-width: 860px){
			width: 146px;
			height: 340px;
			top: 233px;
			left: 52%;
		}
		@media screen and (max-width: 767px) {
			width: 80px;
			height: 179px;
			transform: translateX(-237%);
			top: 21px;
		}
		@media screen and (max-width: 375px) {
			width: 50px;
			height: 112px;
			left: 38%;
			top: 102px;
		}
	}
}

.page-lineup-ballet_summary {
  .dance-list-03 .items .ttl {
	margin-bottom: 10px;
  }
  
  .btn.btn-arrow-right {
	width: 100%;
  }
}
