.news-inner{
  position: relative;
  padding: 18px 0px 15px;
  @media screen and(min-width: 768px){
    padding: 36px 0px 30px;
  }
  .news-inner-item{
    display: flex;
    margin-bottom: 10px;
    padding: 7px 0px;
    @media screen and(min-width: 768px){
      padding: 14px 0px 0px;
      font-size: 24px;
      font-size: 2.4rem;
    }
    .date{
      padding-right: 12px;
      position: relative;
      /*top: -2px;*/
      @media screen and(min-width: 768px){
        padding-right: 12px;
        /*top: -4px;*/
      }
    }
    .date-content{
      a{
        text-decoration: underline;
        text-underline-position: under;
      }
      .txt-pink{
        color: #ff006e;
      }
    }
  }
}
.bg_pink00{
  padding: 4px 20px 0px;
  @media screen and(min-width: 768px){
    padding: 8px 0px 0px;
  }
}

.page-news-index {
  @media(min-width: 768px) {
    .news-inner {
      margin-left: -15px;
      margin-right: -15px;
      overflow: hidden;
    }
    
    .news-inner-item {
      width: 50%;
      padding: 0 15px;
      box-sizing: border-box;
      float: left;
    }
  
    .news-inner .news-inner-item {
      font-size: 16px;
    }
  }
  
}