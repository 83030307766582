.page-thanks {
	article {
		padding: 20px 0 50px;
		border-top: 1px solid #fb4875;
	}
}
.fix-call{
	@media(min-width: 768px){
		pointer-events: none;
	}
}