.style-ttl-pr{
	@media screen and (max-width: 767px) {
		padding: 7px 10px 4px !important;
		line-height: 25px !important;
	}
}
.form-style-pr{
	font-size: 12px !important;
	.form-control{
		font-size: 12px !important;
	}
	textarea.form-control {
		height: 78px !important;
	}
	.form dl dt {
		margin-bottom: 5px !important;
	}
}
.show-form-pc{
	.txt-basic{
		font-size: 14px;
	}
	@media screen and (min-width: 767px) {
		.btn-pr{
			padding: 15px 70px !important;
		}
		.form{
			.fl-form{
				float: left;
				width: 49%;
			}
			.fr-form{
				float: right;
				width: 49%;
			}
			.block{
				width: 100%;
			}
			dl {
				display: block;
				dt {
					width: 100%;
					display: block;
					label {
						font-size: 2rem;
						margin-bottom: 15px;
						display: block;
						span {
							font-size: 1.8rem;
							border: 2px solid #fb4875;
							padding: 4px 5px 2px;
						}
					}
				}
				dd {
					width: 100%;
					display: block;
					margin-bottom: 20px;
					&:first-child{
						margin-bottom: 40px;
					}
					.form-control {
						font-size: 1.6rem !important;
						height: 62px;
						padding: 10px 20px;
					}
					.list-time{
						li{
							span{
								height: 64px;
							}
						}
						.custom-select{
							position: relative;
							&:after{
								display: block;
								content: '';
								background-color: #cccccc;
								width: 60px;
								height: 60px;
								position: absolute;
								top: 1px;
								right: 9.4%;
								pointer-events: none;
							}
							&:before{
								display: block;
								content: '';
								border: 10px solid transparent;
								border-top: 13px solid #535353;
								border-bottom: none;
								position: absolute;
								top: 34px;
								transform: translateY(-50%);
								right: 15%;
								z-index: 1;
							}
						}
					}

				}
			}
		}
	}
}

.dvd-box-ct {
	@media (min-width: 768px){
		margin-left: -15px;
		margin-right: -15px;
		overflow: hidden;
		.w-45{
			width: 45%;
		}
		.w-55{
			width: 55%;
		}
		.image{
			float: left;
			padding: 0 15px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.content-recruit-pc{
	@media (min-width: 768px) {
		.box-recruit {
			.txt {
				padding-top: 20px !important;
				width: 100%;
				padding-left: 0px;
			}
		}
	}
}
.table-pd-recruit{
	tr,th,td {
		@media (max-width: 768px) {
			padding: 6px 17px !important;
		}
	}
	.h-85{
		@media (max-width: 768px) {
			min-height: 65px;
		}
	}
}
.list-wd-item{
	@media (max-width: 768px) {
		.item img {
			width: 31% !important;
		}
		.item p {
			width: 69% !important;
		}
	}
}