.about-choice-section-01
{
    .caption-lg.h-80
    {
        padding: 15px 10px;
        @media screen and (max-width: 767px)
        {
            padding: 17px 10px 16px;

            @include font-size(20);
        }
    }
    .intro-box
    {
        display: flex;

        align-items: flex-start;
        justify-content: space-between;
        .img
        {
            width: 30%;
            @media screen and (max-width: 767px)
            {
                width: 125px;
            }
        }
        .desc
        {
            width: 67%;
            @media screen and (max-width: 767px)
            {
                width: calc(100% - 135px);
            }
            .ttl
            {
                margin-bottom: 5px;

                color: #fb4875;

                @include font-size(16);
                span
                {
                    position: relative;

                    display: inline-block;
                    &:after
                    {
                        position: absolute;
                        z-index: -1;
                        bottom: 0;
                        left: 0;

                        display: block;

                        width: 100%;
                        height: 10px;

                        content: '';
                        transform: rotate(-2deg);

                        background-color: #ffd0dc;
                    }
                }
                @media screen and (max-width: 767px)
                {
                    @include font-size(12);
                }
            }
            .excerpt
            {
                @include font-size(14);
                @media screen and (max-width: 767px)
                {
                    @include font-size(12);
                }
            }
        }
    }
    .choice-list
    {
        display: flex;

        max-width: 1040px;
        margin: 0 auto;
        padding: 0 20px;

        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 767px)
        {
            padding: 0;
        }
        .items
        {
            width: 100%;
            margin-bottom: 2%;
            padding: 10px 20px 15px;
            @media screen and (max-width: 767px)
            {
                margin-bottom: 10px;
            }
            &.ltpink
            {
                background-color: #fee9ee;
                .ttl
                {
                    color: #f6698a;
                }
                .cta
                {
                    background-color: #f6698a;
                }
            }
            &.teal
            {
                background-color: #f2fbfb;
                .ttl
                {
                    color: #61b8ad;
                }
                .cta
                {
                    background-color: #61b8ad;
                }
            }
            &.orange
            {
                background-color: #ffede1;
                .ttl
                {
                    color: #ff8737;
                }
                .cta
                {
                    background-color: #ff8737;
                }
            }
            &.violet
            {
                background-color: #f1e7f6;
                .ttl
                {
                    color: #9e61c5;
                }
                .cta
                {
                    background-color: #9e61c5;
                }
            }
            &.ltbrown
            {
                background-color: #fff9d1;
                .ttl
                {
                    color: #c8a900;
                }
                .cta
                {
                    background-color: #c8a900;
                }
            }
            &.ltorange
            {
                background-color: #fff1db;
                .ttl
                {
                    color: #cf8800;
                }
                .cta
                {
                    background-color: #ffb94a;
                }
            }
            &.red
            {
                background-color: #ffedef;
                .ttl
                {
                    color: #ff5262;
                }
                .cta
                {
                    background-color: #ff5262;
                }
            }
            &.ltgreen
            {
                background-color: #edf8ed;
                .ttl
                {
                    color: #59b35a;
                }
                .cta
                {
                    background-color: #59b35a;
                }
            }
            &.pink
            {
                background-color: #ffeaf3;
                .ttl
                {
                    color: #ff73ae;
                }
                .cta
                {
                    background-color: #ff73ae;
                }
            }
            .ttl
            {
                margin-bottom: 10px;

                text-align: center;

                @include font-size(20);
                @media screen and (max-width: 767px)
                {
                    @include font-size(16);
                }
            }
            .intro-box
            {
                margin-bottom: 10px;
                .img
                {
                    @media screen and (min-width: 768px)
                    {
                        width: 200px;
                    }
                }
                .desc
                {
                    @media screen and (min-width: 768px)
                    {
                        width: calc(100% - 220px);
                    }
                }
            }
            .cta
            {
                position: relative;

                display: block;

                width: 100%;
                max-width: 365px;
                margin: 0 auto;
                padding: 10px 30px 10px 10px;

                text-align: center;

                color: white;

                @include font-size(16);
                @include smooth-transition;
                @media screen and (min-width: 768px)
                {
                    margin-right: 0;
                }
                &:hover
                {
                    opacity: .7;

                    @include smooth-transition;
                }
                &:before
                {
                    position: absolute;
                    right: 15px;

                    display: block;

                    width: 9px;
                    height: 14px;

                    content: '';

                    background: url('../img/about/choice/white-arrow.png') no-repeat center center;
                    background-size: 100% 100%;

                    @include v-align;
                }
                @media screen and (max-width: 767px)
                {
                    width: 100%;
                    margin: 0 auto;
                    padding: 5px 15px;
                    &:before
                    {
                        right: 10px;
                    }
                }
                small
                {
                    @include font-size(14);
                }
            }
        }
    }
    .banner-sm
    {
        .txt-top
        {
            @media screen and (max-width: 767px)
            {
                margin-bottom: 20px;
            }
            @media screen and (max-width: 580px)
            {
                margin-bottom: 5px;
            }
        }
    }
}
.page-about_voice
{
    .caption-lg.h-80
    {
        padding: 15px 10px;
        @media screen and (max-width: 767px)
        {
            padding: 17px 10px 16px;
        }
    }
    .about-list-block
    {
        .items
        {
            .img
            {
                @media screen and (min-width: 768px)
                {
                    width: 244px;
                }
            }
            .info
            {
                @media screen and (min-width: 768px)
                {
                    float: left;

                    width: calc(100% - 264px);
                    padding-left: 0;
                }
                .txt-sm
                {
                    @media screen and (min-width: 768px)
                    {
                        @include font-size(14);
                    }
                }
                .ttl
                {
                    @media screen and (min-width: 768px)
                    {
                        @include font-size(20);
                    }
                    @media screen and (max-width: 767px)
                    {
                        margin-bottom: 5px;
                    }
                }
            }
            > .txt-basic
            {
                @media screen and (min-width: 768px)
                {
                    float: left;

                    width: calc(100% - 264px);
                }
            }
        }
    }
}
