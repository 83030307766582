.page-faq
{
    .caption-lg.h-80
    {
        padding: 15px 10px;
        @media screen and (max-width: 767px)
        {
            padding: 17px 10px 16px;
        }
    }
    .accordion-faq
    {
        border-top: 1px solid #ff006e;
        @media screen and (min-width: 768px)
        {
            border-right: 1px solid #ff006e;
            border-left: 1px solid #ff006e;
        }
        .accordion-items
        {
            .accordion-label
            {
                @media screen and (max-width: 767px)
                {
                    text-align: left;

                    @include font-size(12);
                }
            }
        }
        .accordion-content
        {
            .btn.btn-faq
            {
                @include font-size(20);
                @media screen and (max-width: 767px)
                {
                    display: block;

                    width: 100%;
                    padding: 10px;

                    @include font-size(18);
                }
            }
        }
    }

    .index-section-02:after
    {
        display: none;
    }
}
