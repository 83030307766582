@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

main {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

ul li {
  list-style: none;
}

ol {
  counter-reset: number;
}

ol li {
  display: list-item;
}

ol li:before {
  counter-increment: number;
  content: counter(number) ". ";
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Position & transform
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
@media screen and (min-width: 768px) {
  .mtpc-0 {
    margin-top: 0px !important;
  }
  .mlpc-0 {
    margin-left: 0px !important;
  }
  .mrpc-0 {
    margin-right: 0px !important;
  }
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
  .ptpc-0 {
    padding-top: 0px !important;
  }
  .pbpc-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-0 {
    margin-top: 0px !important;
  }
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
  .ptsp-0 {
    padding-top: 0px !important;
  }
  .pbsp-0 {
    padding-bottom: 0px !important;
  }
  .plsp-0 {
    padding-left: 0px !important;
  }
  .prsp-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-1 {
    margin-top: 1px !important;
  }
  .mlpc-1 {
    margin-left: 1px !important;
  }
  .mrpc-1 {
    margin-right: 1px !important;
  }
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
  .ptpc-1 {
    padding-top: 1px !important;
  }
  .pbpc-1 {
    padding-bottom: 1px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-1 {
    margin-top: 1px !important;
  }
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
  .ptsp-1 {
    padding-top: 1px !important;
  }
  .pbsp-1 {
    padding-bottom: 1px !important;
  }
  .plsp-1 {
    padding-left: 1px !important;
  }
  .prsp-1 {
    padding-right: 1px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-2 {
    margin-top: 2px !important;
  }
  .mlpc-2 {
    margin-left: 2px !important;
  }
  .mrpc-2 {
    margin-right: 2px !important;
  }
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
  .ptpc-2 {
    padding-top: 2px !important;
  }
  .pbpc-2 {
    padding-bottom: 2px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-2 {
    margin-top: 2px !important;
  }
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
  .ptsp-2 {
    padding-top: 2px !important;
  }
  .pbsp-2 {
    padding-bottom: 2px !important;
  }
  .plsp-2 {
    padding-left: 2px !important;
  }
  .prsp-2 {
    padding-right: 2px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-3 {
    margin-top: 3px !important;
  }
  .mlpc-3 {
    margin-left: 3px !important;
  }
  .mrpc-3 {
    margin-right: 3px !important;
  }
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
  .ptpc-3 {
    padding-top: 3px !important;
  }
  .pbpc-3 {
    padding-bottom: 3px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-3 {
    margin-top: 3px !important;
  }
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
  .ptsp-3 {
    padding-top: 3px !important;
  }
  .pbsp-3 {
    padding-bottom: 3px !important;
  }
  .plsp-3 {
    padding-left: 3px !important;
  }
  .prsp-3 {
    padding-right: 3px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-4 {
    margin-top: 4px !important;
  }
  .mlpc-4 {
    margin-left: 4px !important;
  }
  .mrpc-4 {
    margin-right: 4px !important;
  }
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
  .ptpc-4 {
    padding-top: 4px !important;
  }
  .pbpc-4 {
    padding-bottom: 4px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-4 {
    margin-top: 4px !important;
  }
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
  .ptsp-4 {
    padding-top: 4px !important;
  }
  .pbsp-4 {
    padding-bottom: 4px !important;
  }
  .plsp-4 {
    padding-left: 4px !important;
  }
  .prsp-4 {
    padding-right: 4px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-5 {
    margin-top: 5px !important;
  }
  .mlpc-5 {
    margin-left: 5px !important;
  }
  .mrpc-5 {
    margin-right: 5px !important;
  }
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
  .ptpc-5 {
    padding-top: 5px !important;
  }
  .pbpc-5 {
    padding-bottom: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-5 {
    margin-top: 5px !important;
  }
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
  .ptsp-5 {
    padding-top: 5px !important;
  }
  .pbsp-5 {
    padding-bottom: 5px !important;
  }
  .plsp-5 {
    padding-left: 5px !important;
  }
  .prsp-5 {
    padding-right: 5px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-6 {
    margin-top: 6px !important;
  }
  .mlpc-6 {
    margin-left: 6px !important;
  }
  .mrpc-6 {
    margin-right: 6px !important;
  }
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
  .ptpc-6 {
    padding-top: 6px !important;
  }
  .pbpc-6 {
    padding-bottom: 6px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-6 {
    margin-top: 6px !important;
  }
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
  .ptsp-6 {
    padding-top: 6px !important;
  }
  .pbsp-6 {
    padding-bottom: 6px !important;
  }
  .plsp-6 {
    padding-left: 6px !important;
  }
  .prsp-6 {
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-7 {
    margin-top: 7px !important;
  }
  .mlpc-7 {
    margin-left: 7px !important;
  }
  .mrpc-7 {
    margin-right: 7px !important;
  }
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
  .ptpc-7 {
    padding-top: 7px !important;
  }
  .pbpc-7 {
    padding-bottom: 7px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-7 {
    margin-top: 7px !important;
  }
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
  .ptsp-7 {
    padding-top: 7px !important;
  }
  .pbsp-7 {
    padding-bottom: 7px !important;
  }
  .plsp-7 {
    padding-left: 7px !important;
  }
  .prsp-7 {
    padding-right: 7px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-8 {
    margin-top: 8px !important;
  }
  .mlpc-8 {
    margin-left: 8px !important;
  }
  .mrpc-8 {
    margin-right: 8px !important;
  }
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
  .ptpc-8 {
    padding-top: 8px !important;
  }
  .pbpc-8 {
    padding-bottom: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-8 {
    margin-top: 8px !important;
  }
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
  .ptsp-8 {
    padding-top: 8px !important;
  }
  .pbsp-8 {
    padding-bottom: 8px !important;
  }
  .plsp-8 {
    padding-left: 8px !important;
  }
  .prsp-8 {
    padding-right: 8px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-9 {
    margin-top: 9px !important;
  }
  .mlpc-9 {
    margin-left: 9px !important;
  }
  .mrpc-9 {
    margin-right: 9px !important;
  }
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
  .ptpc-9 {
    padding-top: 9px !important;
  }
  .pbpc-9 {
    padding-bottom: 9px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-9 {
    margin-top: 9px !important;
  }
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
  .ptsp-9 {
    padding-top: 9px !important;
  }
  .pbsp-9 {
    padding-bottom: 9px !important;
  }
  .plsp-9 {
    padding-left: 9px !important;
  }
  .prsp-9 {
    padding-right: 9px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-10 {
    margin-top: 10px !important;
  }
  .mlpc-10 {
    margin-left: 10px !important;
  }
  .mrpc-10 {
    margin-right: 10px !important;
  }
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
  .ptpc-10 {
    padding-top: 10px !important;
  }
  .pbpc-10 {
    padding-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-10 {
    margin-top: 10px !important;
  }
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
  .ptsp-10 {
    padding-top: 10px !important;
  }
  .pbsp-10 {
    padding-bottom: 10px !important;
  }
  .plsp-10 {
    padding-left: 10px !important;
  }
  .prsp-10 {
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-11 {
    margin-top: 11px !important;
  }
  .mlpc-11 {
    margin-left: 11px !important;
  }
  .mrpc-11 {
    margin-right: 11px !important;
  }
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
  .ptpc-11 {
    padding-top: 11px !important;
  }
  .pbpc-11 {
    padding-bottom: 11px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-11 {
    margin-top: 11px !important;
  }
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
  .ptsp-11 {
    padding-top: 11px !important;
  }
  .pbsp-11 {
    padding-bottom: 11px !important;
  }
  .plsp-11 {
    padding-left: 11px !important;
  }
  .prsp-11 {
    padding-right: 11px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-12 {
    margin-top: 12px !important;
  }
  .mlpc-12 {
    margin-left: 12px !important;
  }
  .mrpc-12 {
    margin-right: 12px !important;
  }
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
  .ptpc-12 {
    padding-top: 12px !important;
  }
  .pbpc-12 {
    padding-bottom: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-12 {
    margin-top: 12px !important;
  }
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
  .ptsp-12 {
    padding-top: 12px !important;
  }
  .pbsp-12 {
    padding-bottom: 12px !important;
  }
  .plsp-12 {
    padding-left: 12px !important;
  }
  .prsp-12 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-13 {
    margin-top: 13px !important;
  }
  .mlpc-13 {
    margin-left: 13px !important;
  }
  .mrpc-13 {
    margin-right: 13px !important;
  }
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
  .ptpc-13 {
    padding-top: 13px !important;
  }
  .pbpc-13 {
    padding-bottom: 13px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-13 {
    margin-top: 13px !important;
  }
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
  .ptsp-13 {
    padding-top: 13px !important;
  }
  .pbsp-13 {
    padding-bottom: 13px !important;
  }
  .plsp-13 {
    padding-left: 13px !important;
  }
  .prsp-13 {
    padding-right: 13px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-14 {
    margin-top: 14px !important;
  }
  .mlpc-14 {
    margin-left: 14px !important;
  }
  .mrpc-14 {
    margin-right: 14px !important;
  }
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
  .ptpc-14 {
    padding-top: 14px !important;
  }
  .pbpc-14 {
    padding-bottom: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-14 {
    margin-top: 14px !important;
  }
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
  .ptsp-14 {
    padding-top: 14px !important;
  }
  .pbsp-14 {
    padding-bottom: 14px !important;
  }
  .plsp-14 {
    padding-left: 14px !important;
  }
  .prsp-14 {
    padding-right: 14px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-15 {
    margin-top: 15px !important;
  }
  .mlpc-15 {
    margin-left: 15px !important;
  }
  .mrpc-15 {
    margin-right: 15px !important;
  }
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
  .ptpc-15 {
    padding-top: 15px !important;
  }
  .pbpc-15 {
    padding-bottom: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-15 {
    margin-top: 15px !important;
  }
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
  .ptsp-15 {
    padding-top: 15px !important;
  }
  .pbsp-15 {
    padding-bottom: 15px !important;
  }
  .plsp-15 {
    padding-left: 15px !important;
  }
  .prsp-15 {
    padding-right: 15px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-16 {
    margin-top: 16px !important;
  }
  .mlpc-16 {
    margin-left: 16px !important;
  }
  .mrpc-16 {
    margin-right: 16px !important;
  }
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
  .ptpc-16 {
    padding-top: 16px !important;
  }
  .pbpc-16 {
    padding-bottom: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-16 {
    margin-top: 16px !important;
  }
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
  .ptsp-16 {
    padding-top: 16px !important;
  }
  .pbsp-16 {
    padding-bottom: 16px !important;
  }
  .plsp-16 {
    padding-left: 16px !important;
  }
  .prsp-16 {
    padding-right: 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-17 {
    margin-top: 17px !important;
  }
  .mlpc-17 {
    margin-left: 17px !important;
  }
  .mrpc-17 {
    margin-right: 17px !important;
  }
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
  .ptpc-17 {
    padding-top: 17px !important;
  }
  .pbpc-17 {
    padding-bottom: 17px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-17 {
    margin-top: 17px !important;
  }
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
  .ptsp-17 {
    padding-top: 17px !important;
  }
  .pbsp-17 {
    padding-bottom: 17px !important;
  }
  .plsp-17 {
    padding-left: 17px !important;
  }
  .prsp-17 {
    padding-right: 17px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-18 {
    margin-top: 18px !important;
  }
  .mlpc-18 {
    margin-left: 18px !important;
  }
  .mrpc-18 {
    margin-right: 18px !important;
  }
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
  .ptpc-18 {
    padding-top: 18px !important;
  }
  .pbpc-18 {
    padding-bottom: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-18 {
    margin-top: 18px !important;
  }
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
  .ptsp-18 {
    padding-top: 18px !important;
  }
  .pbsp-18 {
    padding-bottom: 18px !important;
  }
  .plsp-18 {
    padding-left: 18px !important;
  }
  .prsp-18 {
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-19 {
    margin-top: 19px !important;
  }
  .mlpc-19 {
    margin-left: 19px !important;
  }
  .mrpc-19 {
    margin-right: 19px !important;
  }
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
  .ptpc-19 {
    padding-top: 19px !important;
  }
  .pbpc-19 {
    padding-bottom: 19px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-19 {
    margin-top: 19px !important;
  }
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
  .ptsp-19 {
    padding-top: 19px !important;
  }
  .pbsp-19 {
    padding-bottom: 19px !important;
  }
  .plsp-19 {
    padding-left: 19px !important;
  }
  .prsp-19 {
    padding-right: 19px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-20 {
    margin-top: 20px !important;
  }
  .mlpc-20 {
    margin-left: 20px !important;
  }
  .mrpc-20 {
    margin-right: 20px !important;
  }
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
  .ptpc-20 {
    padding-top: 20px !important;
  }
  .pbpc-20 {
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-20 {
    margin-top: 20px !important;
  }
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
  .ptsp-20 {
    padding-top: 20px !important;
  }
  .pbsp-20 {
    padding-bottom: 20px !important;
  }
  .plsp-20 {
    padding-left: 20px !important;
  }
  .prsp-20 {
    padding-right: 20px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-21 {
    margin-top: 21px !important;
  }
  .mlpc-21 {
    margin-left: 21px !important;
  }
  .mrpc-21 {
    margin-right: 21px !important;
  }
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
  .ptpc-21 {
    padding-top: 21px !important;
  }
  .pbpc-21 {
    padding-bottom: 21px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-21 {
    margin-top: 21px !important;
  }
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
  .ptsp-21 {
    padding-top: 21px !important;
  }
  .pbsp-21 {
    padding-bottom: 21px !important;
  }
  .plsp-21 {
    padding-left: 21px !important;
  }
  .prsp-21 {
    padding-right: 21px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-22 {
    margin-top: 22px !important;
  }
  .mlpc-22 {
    margin-left: 22px !important;
  }
  .mrpc-22 {
    margin-right: 22px !important;
  }
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
  .ptpc-22 {
    padding-top: 22px !important;
  }
  .pbpc-22 {
    padding-bottom: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-22 {
    margin-top: 22px !important;
  }
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
  .ptsp-22 {
    padding-top: 22px !important;
  }
  .pbsp-22 {
    padding-bottom: 22px !important;
  }
  .plsp-22 {
    padding-left: 22px !important;
  }
  .prsp-22 {
    padding-right: 22px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-23 {
    margin-top: 23px !important;
  }
  .mlpc-23 {
    margin-left: 23px !important;
  }
  .mrpc-23 {
    margin-right: 23px !important;
  }
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
  .ptpc-23 {
    padding-top: 23px !important;
  }
  .pbpc-23 {
    padding-bottom: 23px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-23 {
    margin-top: 23px !important;
  }
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
  .ptsp-23 {
    padding-top: 23px !important;
  }
  .pbsp-23 {
    padding-bottom: 23px !important;
  }
  .plsp-23 {
    padding-left: 23px !important;
  }
  .prsp-23 {
    padding-right: 23px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-24 {
    margin-top: 24px !important;
  }
  .mlpc-24 {
    margin-left: 24px !important;
  }
  .mrpc-24 {
    margin-right: 24px !important;
  }
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
  .ptpc-24 {
    padding-top: 24px !important;
  }
  .pbpc-24 {
    padding-bottom: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-24 {
    margin-top: 24px !important;
  }
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
  .ptsp-24 {
    padding-top: 24px !important;
  }
  .pbsp-24 {
    padding-bottom: 24px !important;
  }
  .plsp-24 {
    padding-left: 24px !important;
  }
  .prsp-24 {
    padding-right: 24px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-25 {
    margin-top: 25px !important;
  }
  .mlpc-25 {
    margin-left: 25px !important;
  }
  .mrpc-25 {
    margin-right: 25px !important;
  }
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
  .ptpc-25 {
    padding-top: 25px !important;
  }
  .pbpc-25 {
    padding-bottom: 25px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-25 {
    margin-top: 25px !important;
  }
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
  .ptsp-25 {
    padding-top: 25px !important;
  }
  .pbsp-25 {
    padding-bottom: 25px !important;
  }
  .plsp-25 {
    padding-left: 25px !important;
  }
  .prsp-25 {
    padding-right: 25px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-26 {
    margin-top: 26px !important;
  }
  .mlpc-26 {
    margin-left: 26px !important;
  }
  .mrpc-26 {
    margin-right: 26px !important;
  }
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
  .ptpc-26 {
    padding-top: 26px !important;
  }
  .pbpc-26 {
    padding-bottom: 26px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-26 {
    margin-top: 26px !important;
  }
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
  .ptsp-26 {
    padding-top: 26px !important;
  }
  .pbsp-26 {
    padding-bottom: 26px !important;
  }
  .plsp-26 {
    padding-left: 26px !important;
  }
  .prsp-26 {
    padding-right: 26px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-27 {
    margin-top: 27px !important;
  }
  .mlpc-27 {
    margin-left: 27px !important;
  }
  .mrpc-27 {
    margin-right: 27px !important;
  }
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
  .ptpc-27 {
    padding-top: 27px !important;
  }
  .pbpc-27 {
    padding-bottom: 27px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-27 {
    margin-top: 27px !important;
  }
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
  .ptsp-27 {
    padding-top: 27px !important;
  }
  .pbsp-27 {
    padding-bottom: 27px !important;
  }
  .plsp-27 {
    padding-left: 27px !important;
  }
  .prsp-27 {
    padding-right: 27px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-28 {
    margin-top: 28px !important;
  }
  .mlpc-28 {
    margin-left: 28px !important;
  }
  .mrpc-28 {
    margin-right: 28px !important;
  }
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
  .ptpc-28 {
    padding-top: 28px !important;
  }
  .pbpc-28 {
    padding-bottom: 28px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-28 {
    margin-top: 28px !important;
  }
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
  .ptsp-28 {
    padding-top: 28px !important;
  }
  .pbsp-28 {
    padding-bottom: 28px !important;
  }
  .plsp-28 {
    padding-left: 28px !important;
  }
  .prsp-28 {
    padding-right: 28px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-29 {
    margin-top: 29px !important;
  }
  .mlpc-29 {
    margin-left: 29px !important;
  }
  .mrpc-29 {
    margin-right: 29px !important;
  }
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
  .ptpc-29 {
    padding-top: 29px !important;
  }
  .pbpc-29 {
    padding-bottom: 29px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-29 {
    margin-top: 29px !important;
  }
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
  .ptsp-29 {
    padding-top: 29px !important;
  }
  .pbsp-29 {
    padding-bottom: 29px !important;
  }
  .plsp-29 {
    padding-left: 29px !important;
  }
  .prsp-29 {
    padding-right: 29px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-30 {
    margin-top: 30px !important;
  }
  .mlpc-30 {
    margin-left: 30px !important;
  }
  .mrpc-30 {
    margin-right: 30px !important;
  }
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
  .ptpc-30 {
    padding-top: 30px !important;
  }
  .pbpc-30 {
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-30 {
    margin-top: 30px !important;
  }
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
  .ptsp-30 {
    padding-top: 30px !important;
  }
  .pbsp-30 {
    padding-bottom: 30px !important;
  }
  .plsp-30 {
    padding-left: 30px !important;
  }
  .prsp-30 {
    padding-right: 30px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-31 {
    margin-top: 31px !important;
  }
  .mlpc-31 {
    margin-left: 31px !important;
  }
  .mrpc-31 {
    margin-right: 31px !important;
  }
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
  .ptpc-31 {
    padding-top: 31px !important;
  }
  .pbpc-31 {
    padding-bottom: 31px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-31 {
    margin-top: 31px !important;
  }
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
  .ptsp-31 {
    padding-top: 31px !important;
  }
  .pbsp-31 {
    padding-bottom: 31px !important;
  }
  .plsp-31 {
    padding-left: 31px !important;
  }
  .prsp-31 {
    padding-right: 31px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-32 {
    margin-top: 32px !important;
  }
  .mlpc-32 {
    margin-left: 32px !important;
  }
  .mrpc-32 {
    margin-right: 32px !important;
  }
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
  .ptpc-32 {
    padding-top: 32px !important;
  }
  .pbpc-32 {
    padding-bottom: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-32 {
    margin-top: 32px !important;
  }
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
  .ptsp-32 {
    padding-top: 32px !important;
  }
  .pbsp-32 {
    padding-bottom: 32px !important;
  }
  .plsp-32 {
    padding-left: 32px !important;
  }
  .prsp-32 {
    padding-right: 32px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-33 {
    margin-top: 33px !important;
  }
  .mlpc-33 {
    margin-left: 33px !important;
  }
  .mrpc-33 {
    margin-right: 33px !important;
  }
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
  .ptpc-33 {
    padding-top: 33px !important;
  }
  .pbpc-33 {
    padding-bottom: 33px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-33 {
    margin-top: 33px !important;
  }
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
  .ptsp-33 {
    padding-top: 33px !important;
  }
  .pbsp-33 {
    padding-bottom: 33px !important;
  }
  .plsp-33 {
    padding-left: 33px !important;
  }
  .prsp-33 {
    padding-right: 33px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-34 {
    margin-top: 34px !important;
  }
  .mlpc-34 {
    margin-left: 34px !important;
  }
  .mrpc-34 {
    margin-right: 34px !important;
  }
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
  .ptpc-34 {
    padding-top: 34px !important;
  }
  .pbpc-34 {
    padding-bottom: 34px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-34 {
    margin-top: 34px !important;
  }
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
  .ptsp-34 {
    padding-top: 34px !important;
  }
  .pbsp-34 {
    padding-bottom: 34px !important;
  }
  .plsp-34 {
    padding-left: 34px !important;
  }
  .prsp-34 {
    padding-right: 34px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-35 {
    margin-top: 35px !important;
  }
  .mlpc-35 {
    margin-left: 35px !important;
  }
  .mrpc-35 {
    margin-right: 35px !important;
  }
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
  .ptpc-35 {
    padding-top: 35px !important;
  }
  .pbpc-35 {
    padding-bottom: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-35 {
    margin-top: 35px !important;
  }
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
  .ptsp-35 {
    padding-top: 35px !important;
  }
  .pbsp-35 {
    padding-bottom: 35px !important;
  }
  .plsp-35 {
    padding-left: 35px !important;
  }
  .prsp-35 {
    padding-right: 35px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-36 {
    margin-top: 36px !important;
  }
  .mlpc-36 {
    margin-left: 36px !important;
  }
  .mrpc-36 {
    margin-right: 36px !important;
  }
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
  .ptpc-36 {
    padding-top: 36px !important;
  }
  .pbpc-36 {
    padding-bottom: 36px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-36 {
    margin-top: 36px !important;
  }
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
  .ptsp-36 {
    padding-top: 36px !important;
  }
  .pbsp-36 {
    padding-bottom: 36px !important;
  }
  .plsp-36 {
    padding-left: 36px !important;
  }
  .prsp-36 {
    padding-right: 36px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-37 {
    margin-top: 37px !important;
  }
  .mlpc-37 {
    margin-left: 37px !important;
  }
  .mrpc-37 {
    margin-right: 37px !important;
  }
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
  .ptpc-37 {
    padding-top: 37px !important;
  }
  .pbpc-37 {
    padding-bottom: 37px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-37 {
    margin-top: 37px !important;
  }
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
  .ptsp-37 {
    padding-top: 37px !important;
  }
  .pbsp-37 {
    padding-bottom: 37px !important;
  }
  .plsp-37 {
    padding-left: 37px !important;
  }
  .prsp-37 {
    padding-right: 37px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-38 {
    margin-top: 38px !important;
  }
  .mlpc-38 {
    margin-left: 38px !important;
  }
  .mrpc-38 {
    margin-right: 38px !important;
  }
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
  .ptpc-38 {
    padding-top: 38px !important;
  }
  .pbpc-38 {
    padding-bottom: 38px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-38 {
    margin-top: 38px !important;
  }
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
  .ptsp-38 {
    padding-top: 38px !important;
  }
  .pbsp-38 {
    padding-bottom: 38px !important;
  }
  .plsp-38 {
    padding-left: 38px !important;
  }
  .prsp-38 {
    padding-right: 38px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-39 {
    margin-top: 39px !important;
  }
  .mlpc-39 {
    margin-left: 39px !important;
  }
  .mrpc-39 {
    margin-right: 39px !important;
  }
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
  .ptpc-39 {
    padding-top: 39px !important;
  }
  .pbpc-39 {
    padding-bottom: 39px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-39 {
    margin-top: 39px !important;
  }
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
  .ptsp-39 {
    padding-top: 39px !important;
  }
  .pbsp-39 {
    padding-bottom: 39px !important;
  }
  .plsp-39 {
    padding-left: 39px !important;
  }
  .prsp-39 {
    padding-right: 39px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-40 {
    margin-top: 40px !important;
  }
  .mlpc-40 {
    margin-left: 40px !important;
  }
  .mrpc-40 {
    margin-right: 40px !important;
  }
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
  .ptpc-40 {
    padding-top: 40px !important;
  }
  .pbpc-40 {
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-40 {
    margin-top: 40px !important;
  }
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
  .ptsp-40 {
    padding-top: 40px !important;
  }
  .pbsp-40 {
    padding-bottom: 40px !important;
  }
  .plsp-40 {
    padding-left: 40px !important;
  }
  .prsp-40 {
    padding-right: 40px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-41 {
    margin-top: 41px !important;
  }
  .mlpc-41 {
    margin-left: 41px !important;
  }
  .mrpc-41 {
    margin-right: 41px !important;
  }
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
  .ptpc-41 {
    padding-top: 41px !important;
  }
  .pbpc-41 {
    padding-bottom: 41px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-41 {
    margin-top: 41px !important;
  }
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
  .ptsp-41 {
    padding-top: 41px !important;
  }
  .pbsp-41 {
    padding-bottom: 41px !important;
  }
  .plsp-41 {
    padding-left: 41px !important;
  }
  .prsp-41 {
    padding-right: 41px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-42 {
    margin-top: 42px !important;
  }
  .mlpc-42 {
    margin-left: 42px !important;
  }
  .mrpc-42 {
    margin-right: 42px !important;
  }
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
  .ptpc-42 {
    padding-top: 42px !important;
  }
  .pbpc-42 {
    padding-bottom: 42px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-42 {
    margin-top: 42px !important;
  }
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
  .ptsp-42 {
    padding-top: 42px !important;
  }
  .pbsp-42 {
    padding-bottom: 42px !important;
  }
  .plsp-42 {
    padding-left: 42px !important;
  }
  .prsp-42 {
    padding-right: 42px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-43 {
    margin-top: 43px !important;
  }
  .mlpc-43 {
    margin-left: 43px !important;
  }
  .mrpc-43 {
    margin-right: 43px !important;
  }
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
  .ptpc-43 {
    padding-top: 43px !important;
  }
  .pbpc-43 {
    padding-bottom: 43px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-43 {
    margin-top: 43px !important;
  }
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
  .ptsp-43 {
    padding-top: 43px !important;
  }
  .pbsp-43 {
    padding-bottom: 43px !important;
  }
  .plsp-43 {
    padding-left: 43px !important;
  }
  .prsp-43 {
    padding-right: 43px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-44 {
    margin-top: 44px !important;
  }
  .mlpc-44 {
    margin-left: 44px !important;
  }
  .mrpc-44 {
    margin-right: 44px !important;
  }
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
  .ptpc-44 {
    padding-top: 44px !important;
  }
  .pbpc-44 {
    padding-bottom: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-44 {
    margin-top: 44px !important;
  }
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
  .ptsp-44 {
    padding-top: 44px !important;
  }
  .pbsp-44 {
    padding-bottom: 44px !important;
  }
  .plsp-44 {
    padding-left: 44px !important;
  }
  .prsp-44 {
    padding-right: 44px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-45 {
    margin-top: 45px !important;
  }
  .mlpc-45 {
    margin-left: 45px !important;
  }
  .mrpc-45 {
    margin-right: 45px !important;
  }
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
  .ptpc-45 {
    padding-top: 45px !important;
  }
  .pbpc-45 {
    padding-bottom: 45px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-45 {
    margin-top: 45px !important;
  }
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
  .ptsp-45 {
    padding-top: 45px !important;
  }
  .pbsp-45 {
    padding-bottom: 45px !important;
  }
  .plsp-45 {
    padding-left: 45px !important;
  }
  .prsp-45 {
    padding-right: 45px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-46 {
    margin-top: 46px !important;
  }
  .mlpc-46 {
    margin-left: 46px !important;
  }
  .mrpc-46 {
    margin-right: 46px !important;
  }
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
  .ptpc-46 {
    padding-top: 46px !important;
  }
  .pbpc-46 {
    padding-bottom: 46px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-46 {
    margin-top: 46px !important;
  }
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
  .ptsp-46 {
    padding-top: 46px !important;
  }
  .pbsp-46 {
    padding-bottom: 46px !important;
  }
  .plsp-46 {
    padding-left: 46px !important;
  }
  .prsp-46 {
    padding-right: 46px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-47 {
    margin-top: 47px !important;
  }
  .mlpc-47 {
    margin-left: 47px !important;
  }
  .mrpc-47 {
    margin-right: 47px !important;
  }
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
  .ptpc-47 {
    padding-top: 47px !important;
  }
  .pbpc-47 {
    padding-bottom: 47px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-47 {
    margin-top: 47px !important;
  }
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
  .ptsp-47 {
    padding-top: 47px !important;
  }
  .pbsp-47 {
    padding-bottom: 47px !important;
  }
  .plsp-47 {
    padding-left: 47px !important;
  }
  .prsp-47 {
    padding-right: 47px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-48 {
    margin-top: 48px !important;
  }
  .mlpc-48 {
    margin-left: 48px !important;
  }
  .mrpc-48 {
    margin-right: 48px !important;
  }
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
  .ptpc-48 {
    padding-top: 48px !important;
  }
  .pbpc-48 {
    padding-bottom: 48px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-48 {
    margin-top: 48px !important;
  }
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
  .ptsp-48 {
    padding-top: 48px !important;
  }
  .pbsp-48 {
    padding-bottom: 48px !important;
  }
  .plsp-48 {
    padding-left: 48px !important;
  }
  .prsp-48 {
    padding-right: 48px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-49 {
    margin-top: 49px !important;
  }
  .mlpc-49 {
    margin-left: 49px !important;
  }
  .mrpc-49 {
    margin-right: 49px !important;
  }
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
  .ptpc-49 {
    padding-top: 49px !important;
  }
  .pbpc-49 {
    padding-bottom: 49px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-49 {
    margin-top: 49px !important;
  }
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
  .ptsp-49 {
    padding-top: 49px !important;
  }
  .pbsp-49 {
    padding-bottom: 49px !important;
  }
  .plsp-49 {
    padding-left: 49px !important;
  }
  .prsp-49 {
    padding-right: 49px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-50 {
    margin-top: 50px !important;
  }
  .mlpc-50 {
    margin-left: 50px !important;
  }
  .mrpc-50 {
    margin-right: 50px !important;
  }
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
  .ptpc-50 {
    padding-top: 50px !important;
  }
  .pbpc-50 {
    padding-bottom: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-50 {
    margin-top: 50px !important;
  }
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
  .ptsp-50 {
    padding-top: 50px !important;
  }
  .pbsp-50 {
    padding-bottom: 50px !important;
  }
  .plsp-50 {
    padding-left: 50px !important;
  }
  .prsp-50 {
    padding-right: 50px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-51 {
    margin-top: 51px !important;
  }
  .mlpc-51 {
    margin-left: 51px !important;
  }
  .mrpc-51 {
    margin-right: 51px !important;
  }
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
  .ptpc-51 {
    padding-top: 51px !important;
  }
  .pbpc-51 {
    padding-bottom: 51px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-51 {
    margin-top: 51px !important;
  }
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
  .ptsp-51 {
    padding-top: 51px !important;
  }
  .pbsp-51 {
    padding-bottom: 51px !important;
  }
  .plsp-51 {
    padding-left: 51px !important;
  }
  .prsp-51 {
    padding-right: 51px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-52 {
    margin-top: 52px !important;
  }
  .mlpc-52 {
    margin-left: 52px !important;
  }
  .mrpc-52 {
    margin-right: 52px !important;
  }
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
  .ptpc-52 {
    padding-top: 52px !important;
  }
  .pbpc-52 {
    padding-bottom: 52px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-52 {
    margin-top: 52px !important;
  }
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
  .ptsp-52 {
    padding-top: 52px !important;
  }
  .pbsp-52 {
    padding-bottom: 52px !important;
  }
  .plsp-52 {
    padding-left: 52px !important;
  }
  .prsp-52 {
    padding-right: 52px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-53 {
    margin-top: 53px !important;
  }
  .mlpc-53 {
    margin-left: 53px !important;
  }
  .mrpc-53 {
    margin-right: 53px !important;
  }
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
  .ptpc-53 {
    padding-top: 53px !important;
  }
  .pbpc-53 {
    padding-bottom: 53px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-53 {
    margin-top: 53px !important;
  }
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
  .ptsp-53 {
    padding-top: 53px !important;
  }
  .pbsp-53 {
    padding-bottom: 53px !important;
  }
  .plsp-53 {
    padding-left: 53px !important;
  }
  .prsp-53 {
    padding-right: 53px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-54 {
    margin-top: 54px !important;
  }
  .mlpc-54 {
    margin-left: 54px !important;
  }
  .mrpc-54 {
    margin-right: 54px !important;
  }
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
  .ptpc-54 {
    padding-top: 54px !important;
  }
  .pbpc-54 {
    padding-bottom: 54px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-54 {
    margin-top: 54px !important;
  }
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
  .ptsp-54 {
    padding-top: 54px !important;
  }
  .pbsp-54 {
    padding-bottom: 54px !important;
  }
  .plsp-54 {
    padding-left: 54px !important;
  }
  .prsp-54 {
    padding-right: 54px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-55 {
    margin-top: 55px !important;
  }
  .mlpc-55 {
    margin-left: 55px !important;
  }
  .mrpc-55 {
    margin-right: 55px !important;
  }
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
  .ptpc-55 {
    padding-top: 55px !important;
  }
  .pbpc-55 {
    padding-bottom: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-55 {
    margin-top: 55px !important;
  }
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
  .ptsp-55 {
    padding-top: 55px !important;
  }
  .pbsp-55 {
    padding-bottom: 55px !important;
  }
  .plsp-55 {
    padding-left: 55px !important;
  }
  .prsp-55 {
    padding-right: 55px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-56 {
    margin-top: 56px !important;
  }
  .mlpc-56 {
    margin-left: 56px !important;
  }
  .mrpc-56 {
    margin-right: 56px !important;
  }
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
  .ptpc-56 {
    padding-top: 56px !important;
  }
  .pbpc-56 {
    padding-bottom: 56px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-56 {
    margin-top: 56px !important;
  }
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
  .ptsp-56 {
    padding-top: 56px !important;
  }
  .pbsp-56 {
    padding-bottom: 56px !important;
  }
  .plsp-56 {
    padding-left: 56px !important;
  }
  .prsp-56 {
    padding-right: 56px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-57 {
    margin-top: 57px !important;
  }
  .mlpc-57 {
    margin-left: 57px !important;
  }
  .mrpc-57 {
    margin-right: 57px !important;
  }
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
  .ptpc-57 {
    padding-top: 57px !important;
  }
  .pbpc-57 {
    padding-bottom: 57px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-57 {
    margin-top: 57px !important;
  }
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
  .ptsp-57 {
    padding-top: 57px !important;
  }
  .pbsp-57 {
    padding-bottom: 57px !important;
  }
  .plsp-57 {
    padding-left: 57px !important;
  }
  .prsp-57 {
    padding-right: 57px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-58 {
    margin-top: 58px !important;
  }
  .mlpc-58 {
    margin-left: 58px !important;
  }
  .mrpc-58 {
    margin-right: 58px !important;
  }
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
  .ptpc-58 {
    padding-top: 58px !important;
  }
  .pbpc-58 {
    padding-bottom: 58px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-58 {
    margin-top: 58px !important;
  }
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
  .ptsp-58 {
    padding-top: 58px !important;
  }
  .pbsp-58 {
    padding-bottom: 58px !important;
  }
  .plsp-58 {
    padding-left: 58px !important;
  }
  .prsp-58 {
    padding-right: 58px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-59 {
    margin-top: 59px !important;
  }
  .mlpc-59 {
    margin-left: 59px !important;
  }
  .mrpc-59 {
    margin-right: 59px !important;
  }
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
  .ptpc-59 {
    padding-top: 59px !important;
  }
  .pbpc-59 {
    padding-bottom: 59px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-59 {
    margin-top: 59px !important;
  }
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
  .ptsp-59 {
    padding-top: 59px !important;
  }
  .pbsp-59 {
    padding-bottom: 59px !important;
  }
  .plsp-59 {
    padding-left: 59px !important;
  }
  .prsp-59 {
    padding-right: 59px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-60 {
    margin-top: 60px !important;
  }
  .mlpc-60 {
    margin-left: 60px !important;
  }
  .mrpc-60 {
    margin-right: 60px !important;
  }
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
  .ptpc-60 {
    padding-top: 60px !important;
  }
  .pbpc-60 {
    padding-bottom: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-60 {
    margin-top: 60px !important;
  }
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
  .ptsp-60 {
    padding-top: 60px !important;
  }
  .pbsp-60 {
    padding-bottom: 60px !important;
  }
  .plsp-60 {
    padding-left: 60px !important;
  }
  .prsp-60 {
    padding-right: 60px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-61 {
    margin-top: 61px !important;
  }
  .mlpc-61 {
    margin-left: 61px !important;
  }
  .mrpc-61 {
    margin-right: 61px !important;
  }
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
  .ptpc-61 {
    padding-top: 61px !important;
  }
  .pbpc-61 {
    padding-bottom: 61px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-61 {
    margin-top: 61px !important;
  }
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
  .ptsp-61 {
    padding-top: 61px !important;
  }
  .pbsp-61 {
    padding-bottom: 61px !important;
  }
  .plsp-61 {
    padding-left: 61px !important;
  }
  .prsp-61 {
    padding-right: 61px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-62 {
    margin-top: 62px !important;
  }
  .mlpc-62 {
    margin-left: 62px !important;
  }
  .mrpc-62 {
    margin-right: 62px !important;
  }
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
  .ptpc-62 {
    padding-top: 62px !important;
  }
  .pbpc-62 {
    padding-bottom: 62px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-62 {
    margin-top: 62px !important;
  }
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
  .ptsp-62 {
    padding-top: 62px !important;
  }
  .pbsp-62 {
    padding-bottom: 62px !important;
  }
  .plsp-62 {
    padding-left: 62px !important;
  }
  .prsp-62 {
    padding-right: 62px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-63 {
    margin-top: 63px !important;
  }
  .mlpc-63 {
    margin-left: 63px !important;
  }
  .mrpc-63 {
    margin-right: 63px !important;
  }
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
  .ptpc-63 {
    padding-top: 63px !important;
  }
  .pbpc-63 {
    padding-bottom: 63px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-63 {
    margin-top: 63px !important;
  }
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
  .ptsp-63 {
    padding-top: 63px !important;
  }
  .pbsp-63 {
    padding-bottom: 63px !important;
  }
  .plsp-63 {
    padding-left: 63px !important;
  }
  .prsp-63 {
    padding-right: 63px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-64 {
    margin-top: 64px !important;
  }
  .mlpc-64 {
    margin-left: 64px !important;
  }
  .mrpc-64 {
    margin-right: 64px !important;
  }
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
  .ptpc-64 {
    padding-top: 64px !important;
  }
  .pbpc-64 {
    padding-bottom: 64px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-64 {
    margin-top: 64px !important;
  }
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
  .ptsp-64 {
    padding-top: 64px !important;
  }
  .pbsp-64 {
    padding-bottom: 64px !important;
  }
  .plsp-64 {
    padding-left: 64px !important;
  }
  .prsp-64 {
    padding-right: 64px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-65 {
    margin-top: 65px !important;
  }
  .mlpc-65 {
    margin-left: 65px !important;
  }
  .mrpc-65 {
    margin-right: 65px !important;
  }
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
  .ptpc-65 {
    padding-top: 65px !important;
  }
  .pbpc-65 {
    padding-bottom: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-65 {
    margin-top: 65px !important;
  }
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
  .ptsp-65 {
    padding-top: 65px !important;
  }
  .pbsp-65 {
    padding-bottom: 65px !important;
  }
  .plsp-65 {
    padding-left: 65px !important;
  }
  .prsp-65 {
    padding-right: 65px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-66 {
    margin-top: 66px !important;
  }
  .mlpc-66 {
    margin-left: 66px !important;
  }
  .mrpc-66 {
    margin-right: 66px !important;
  }
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
  .ptpc-66 {
    padding-top: 66px !important;
  }
  .pbpc-66 {
    padding-bottom: 66px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-66 {
    margin-top: 66px !important;
  }
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
  .ptsp-66 {
    padding-top: 66px !important;
  }
  .pbsp-66 {
    padding-bottom: 66px !important;
  }
  .plsp-66 {
    padding-left: 66px !important;
  }
  .prsp-66 {
    padding-right: 66px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-67 {
    margin-top: 67px !important;
  }
  .mlpc-67 {
    margin-left: 67px !important;
  }
  .mrpc-67 {
    margin-right: 67px !important;
  }
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
  .ptpc-67 {
    padding-top: 67px !important;
  }
  .pbpc-67 {
    padding-bottom: 67px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-67 {
    margin-top: 67px !important;
  }
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
  .ptsp-67 {
    padding-top: 67px !important;
  }
  .pbsp-67 {
    padding-bottom: 67px !important;
  }
  .plsp-67 {
    padding-left: 67px !important;
  }
  .prsp-67 {
    padding-right: 67px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-68 {
    margin-top: 68px !important;
  }
  .mlpc-68 {
    margin-left: 68px !important;
  }
  .mrpc-68 {
    margin-right: 68px !important;
  }
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
  .ptpc-68 {
    padding-top: 68px !important;
  }
  .pbpc-68 {
    padding-bottom: 68px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-68 {
    margin-top: 68px !important;
  }
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
  .ptsp-68 {
    padding-top: 68px !important;
  }
  .pbsp-68 {
    padding-bottom: 68px !important;
  }
  .plsp-68 {
    padding-left: 68px !important;
  }
  .prsp-68 {
    padding-right: 68px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-69 {
    margin-top: 69px !important;
  }
  .mlpc-69 {
    margin-left: 69px !important;
  }
  .mrpc-69 {
    margin-right: 69px !important;
  }
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
  .ptpc-69 {
    padding-top: 69px !important;
  }
  .pbpc-69 {
    padding-bottom: 69px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-69 {
    margin-top: 69px !important;
  }
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
  .ptsp-69 {
    padding-top: 69px !important;
  }
  .pbsp-69 {
    padding-bottom: 69px !important;
  }
  .plsp-69 {
    padding-left: 69px !important;
  }
  .prsp-69 {
    padding-right: 69px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-70 {
    margin-top: 70px !important;
  }
  .mlpc-70 {
    margin-left: 70px !important;
  }
  .mrpc-70 {
    margin-right: 70px !important;
  }
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
  .ptpc-70 {
    padding-top: 70px !important;
  }
  .pbpc-70 {
    padding-bottom: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-70 {
    margin-top: 70px !important;
  }
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
  .ptsp-70 {
    padding-top: 70px !important;
  }
  .pbsp-70 {
    padding-bottom: 70px !important;
  }
  .plsp-70 {
    padding-left: 70px !important;
  }
  .prsp-70 {
    padding-right: 70px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-71 {
    margin-top: 71px !important;
  }
  .mlpc-71 {
    margin-left: 71px !important;
  }
  .mrpc-71 {
    margin-right: 71px !important;
  }
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
  .ptpc-71 {
    padding-top: 71px !important;
  }
  .pbpc-71 {
    padding-bottom: 71px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-71 {
    margin-top: 71px !important;
  }
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
  .ptsp-71 {
    padding-top: 71px !important;
  }
  .pbsp-71 {
    padding-bottom: 71px !important;
  }
  .plsp-71 {
    padding-left: 71px !important;
  }
  .prsp-71 {
    padding-right: 71px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-72 {
    margin-top: 72px !important;
  }
  .mlpc-72 {
    margin-left: 72px !important;
  }
  .mrpc-72 {
    margin-right: 72px !important;
  }
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
  .ptpc-72 {
    padding-top: 72px !important;
  }
  .pbpc-72 {
    padding-bottom: 72px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-72 {
    margin-top: 72px !important;
  }
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
  .ptsp-72 {
    padding-top: 72px !important;
  }
  .pbsp-72 {
    padding-bottom: 72px !important;
  }
  .plsp-72 {
    padding-left: 72px !important;
  }
  .prsp-72 {
    padding-right: 72px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-73 {
    margin-top: 73px !important;
  }
  .mlpc-73 {
    margin-left: 73px !important;
  }
  .mrpc-73 {
    margin-right: 73px !important;
  }
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
  .ptpc-73 {
    padding-top: 73px !important;
  }
  .pbpc-73 {
    padding-bottom: 73px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-73 {
    margin-top: 73px !important;
  }
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
  .ptsp-73 {
    padding-top: 73px !important;
  }
  .pbsp-73 {
    padding-bottom: 73px !important;
  }
  .plsp-73 {
    padding-left: 73px !important;
  }
  .prsp-73 {
    padding-right: 73px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-74 {
    margin-top: 74px !important;
  }
  .mlpc-74 {
    margin-left: 74px !important;
  }
  .mrpc-74 {
    margin-right: 74px !important;
  }
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
  .ptpc-74 {
    padding-top: 74px !important;
  }
  .pbpc-74 {
    padding-bottom: 74px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-74 {
    margin-top: 74px !important;
  }
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
  .ptsp-74 {
    padding-top: 74px !important;
  }
  .pbsp-74 {
    padding-bottom: 74px !important;
  }
  .plsp-74 {
    padding-left: 74px !important;
  }
  .prsp-74 {
    padding-right: 74px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-75 {
    margin-top: 75px !important;
  }
  .mlpc-75 {
    margin-left: 75px !important;
  }
  .mrpc-75 {
    margin-right: 75px !important;
  }
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
  .ptpc-75 {
    padding-top: 75px !important;
  }
  .pbpc-75 {
    padding-bottom: 75px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-75 {
    margin-top: 75px !important;
  }
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
  .ptsp-75 {
    padding-top: 75px !important;
  }
  .pbsp-75 {
    padding-bottom: 75px !important;
  }
  .plsp-75 {
    padding-left: 75px !important;
  }
  .prsp-75 {
    padding-right: 75px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-76 {
    margin-top: 76px !important;
  }
  .mlpc-76 {
    margin-left: 76px !important;
  }
  .mrpc-76 {
    margin-right: 76px !important;
  }
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
  .ptpc-76 {
    padding-top: 76px !important;
  }
  .pbpc-76 {
    padding-bottom: 76px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-76 {
    margin-top: 76px !important;
  }
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
  .ptsp-76 {
    padding-top: 76px !important;
  }
  .pbsp-76 {
    padding-bottom: 76px !important;
  }
  .plsp-76 {
    padding-left: 76px !important;
  }
  .prsp-76 {
    padding-right: 76px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-77 {
    margin-top: 77px !important;
  }
  .mlpc-77 {
    margin-left: 77px !important;
  }
  .mrpc-77 {
    margin-right: 77px !important;
  }
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
  .ptpc-77 {
    padding-top: 77px !important;
  }
  .pbpc-77 {
    padding-bottom: 77px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-77 {
    margin-top: 77px !important;
  }
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
  .ptsp-77 {
    padding-top: 77px !important;
  }
  .pbsp-77 {
    padding-bottom: 77px !important;
  }
  .plsp-77 {
    padding-left: 77px !important;
  }
  .prsp-77 {
    padding-right: 77px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-78 {
    margin-top: 78px !important;
  }
  .mlpc-78 {
    margin-left: 78px !important;
  }
  .mrpc-78 {
    margin-right: 78px !important;
  }
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
  .ptpc-78 {
    padding-top: 78px !important;
  }
  .pbpc-78 {
    padding-bottom: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-78 {
    margin-top: 78px !important;
  }
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
  .ptsp-78 {
    padding-top: 78px !important;
  }
  .pbsp-78 {
    padding-bottom: 78px !important;
  }
  .plsp-78 {
    padding-left: 78px !important;
  }
  .prsp-78 {
    padding-right: 78px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-79 {
    margin-top: 79px !important;
  }
  .mlpc-79 {
    margin-left: 79px !important;
  }
  .mrpc-79 {
    margin-right: 79px !important;
  }
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
  .ptpc-79 {
    padding-top: 79px !important;
  }
  .pbpc-79 {
    padding-bottom: 79px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-79 {
    margin-top: 79px !important;
  }
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
  .ptsp-79 {
    padding-top: 79px !important;
  }
  .pbsp-79 {
    padding-bottom: 79px !important;
  }
  .plsp-79 {
    padding-left: 79px !important;
  }
  .prsp-79 {
    padding-right: 79px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-80 {
    margin-top: 80px !important;
  }
  .mlpc-80 {
    margin-left: 80px !important;
  }
  .mrpc-80 {
    margin-right: 80px !important;
  }
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
  .ptpc-80 {
    padding-top: 80px !important;
  }
  .pbpc-80 {
    padding-bottom: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-80 {
    margin-top: 80px !important;
  }
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
  .ptsp-80 {
    padding-top: 80px !important;
  }
  .pbsp-80 {
    padding-bottom: 80px !important;
  }
  .plsp-80 {
    padding-left: 80px !important;
  }
  .prsp-80 {
    padding-right: 80px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-81 {
    margin-top: 81px !important;
  }
  .mlpc-81 {
    margin-left: 81px !important;
  }
  .mrpc-81 {
    margin-right: 81px !important;
  }
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
  .ptpc-81 {
    padding-top: 81px !important;
  }
  .pbpc-81 {
    padding-bottom: 81px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-81 {
    margin-top: 81px !important;
  }
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
  .ptsp-81 {
    padding-top: 81px !important;
  }
  .pbsp-81 {
    padding-bottom: 81px !important;
  }
  .plsp-81 {
    padding-left: 81px !important;
  }
  .prsp-81 {
    padding-right: 81px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-82 {
    margin-top: 82px !important;
  }
  .mlpc-82 {
    margin-left: 82px !important;
  }
  .mrpc-82 {
    margin-right: 82px !important;
  }
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
  .ptpc-82 {
    padding-top: 82px !important;
  }
  .pbpc-82 {
    padding-bottom: 82px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-82 {
    margin-top: 82px !important;
  }
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
  .ptsp-82 {
    padding-top: 82px !important;
  }
  .pbsp-82 {
    padding-bottom: 82px !important;
  }
  .plsp-82 {
    padding-left: 82px !important;
  }
  .prsp-82 {
    padding-right: 82px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-83 {
    margin-top: 83px !important;
  }
  .mlpc-83 {
    margin-left: 83px !important;
  }
  .mrpc-83 {
    margin-right: 83px !important;
  }
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
  .ptpc-83 {
    padding-top: 83px !important;
  }
  .pbpc-83 {
    padding-bottom: 83px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-83 {
    margin-top: 83px !important;
  }
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
  .ptsp-83 {
    padding-top: 83px !important;
  }
  .pbsp-83 {
    padding-bottom: 83px !important;
  }
  .plsp-83 {
    padding-left: 83px !important;
  }
  .prsp-83 {
    padding-right: 83px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-84 {
    margin-top: 84px !important;
  }
  .mlpc-84 {
    margin-left: 84px !important;
  }
  .mrpc-84 {
    margin-right: 84px !important;
  }
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
  .ptpc-84 {
    padding-top: 84px !important;
  }
  .pbpc-84 {
    padding-bottom: 84px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-84 {
    margin-top: 84px !important;
  }
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
  .ptsp-84 {
    padding-top: 84px !important;
  }
  .pbsp-84 {
    padding-bottom: 84px !important;
  }
  .plsp-84 {
    padding-left: 84px !important;
  }
  .prsp-84 {
    padding-right: 84px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-85 {
    margin-top: 85px !important;
  }
  .mlpc-85 {
    margin-left: 85px !important;
  }
  .mrpc-85 {
    margin-right: 85px !important;
  }
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
  .ptpc-85 {
    padding-top: 85px !important;
  }
  .pbpc-85 {
    padding-bottom: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-85 {
    margin-top: 85px !important;
  }
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
  .ptsp-85 {
    padding-top: 85px !important;
  }
  .pbsp-85 {
    padding-bottom: 85px !important;
  }
  .plsp-85 {
    padding-left: 85px !important;
  }
  .prsp-85 {
    padding-right: 85px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-86 {
    margin-top: 86px !important;
  }
  .mlpc-86 {
    margin-left: 86px !important;
  }
  .mrpc-86 {
    margin-right: 86px !important;
  }
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
  .ptpc-86 {
    padding-top: 86px !important;
  }
  .pbpc-86 {
    padding-bottom: 86px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-86 {
    margin-top: 86px !important;
  }
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
  .ptsp-86 {
    padding-top: 86px !important;
  }
  .pbsp-86 {
    padding-bottom: 86px !important;
  }
  .plsp-86 {
    padding-left: 86px !important;
  }
  .prsp-86 {
    padding-right: 86px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-87 {
    margin-top: 87px !important;
  }
  .mlpc-87 {
    margin-left: 87px !important;
  }
  .mrpc-87 {
    margin-right: 87px !important;
  }
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
  .ptpc-87 {
    padding-top: 87px !important;
  }
  .pbpc-87 {
    padding-bottom: 87px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-87 {
    margin-top: 87px !important;
  }
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
  .ptsp-87 {
    padding-top: 87px !important;
  }
  .pbsp-87 {
    padding-bottom: 87px !important;
  }
  .plsp-87 {
    padding-left: 87px !important;
  }
  .prsp-87 {
    padding-right: 87px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-88 {
    margin-top: 88px !important;
  }
  .mlpc-88 {
    margin-left: 88px !important;
  }
  .mrpc-88 {
    margin-right: 88px !important;
  }
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
  .ptpc-88 {
    padding-top: 88px !important;
  }
  .pbpc-88 {
    padding-bottom: 88px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-88 {
    margin-top: 88px !important;
  }
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
  .ptsp-88 {
    padding-top: 88px !important;
  }
  .pbsp-88 {
    padding-bottom: 88px !important;
  }
  .plsp-88 {
    padding-left: 88px !important;
  }
  .prsp-88 {
    padding-right: 88px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-89 {
    margin-top: 89px !important;
  }
  .mlpc-89 {
    margin-left: 89px !important;
  }
  .mrpc-89 {
    margin-right: 89px !important;
  }
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
  .ptpc-89 {
    padding-top: 89px !important;
  }
  .pbpc-89 {
    padding-bottom: 89px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-89 {
    margin-top: 89px !important;
  }
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
  .ptsp-89 {
    padding-top: 89px !important;
  }
  .pbsp-89 {
    padding-bottom: 89px !important;
  }
  .plsp-89 {
    padding-left: 89px !important;
  }
  .prsp-89 {
    padding-right: 89px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-90 {
    margin-top: 90px !important;
  }
  .mlpc-90 {
    margin-left: 90px !important;
  }
  .mrpc-90 {
    margin-right: 90px !important;
  }
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
  .ptpc-90 {
    padding-top: 90px !important;
  }
  .pbpc-90 {
    padding-bottom: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-90 {
    margin-top: 90px !important;
  }
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
  .ptsp-90 {
    padding-top: 90px !important;
  }
  .pbsp-90 {
    padding-bottom: 90px !important;
  }
  .plsp-90 {
    padding-left: 90px !important;
  }
  .prsp-90 {
    padding-right: 90px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-91 {
    margin-top: 91px !important;
  }
  .mlpc-91 {
    margin-left: 91px !important;
  }
  .mrpc-91 {
    margin-right: 91px !important;
  }
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
  .ptpc-91 {
    padding-top: 91px !important;
  }
  .pbpc-91 {
    padding-bottom: 91px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-91 {
    margin-top: 91px !important;
  }
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
  .ptsp-91 {
    padding-top: 91px !important;
  }
  .pbsp-91 {
    padding-bottom: 91px !important;
  }
  .plsp-91 {
    padding-left: 91px !important;
  }
  .prsp-91 {
    padding-right: 91px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-92 {
    margin-top: 92px !important;
  }
  .mlpc-92 {
    margin-left: 92px !important;
  }
  .mrpc-92 {
    margin-right: 92px !important;
  }
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
  .ptpc-92 {
    padding-top: 92px !important;
  }
  .pbpc-92 {
    padding-bottom: 92px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-92 {
    margin-top: 92px !important;
  }
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
  .ptsp-92 {
    padding-top: 92px !important;
  }
  .pbsp-92 {
    padding-bottom: 92px !important;
  }
  .plsp-92 {
    padding-left: 92px !important;
  }
  .prsp-92 {
    padding-right: 92px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-93 {
    margin-top: 93px !important;
  }
  .mlpc-93 {
    margin-left: 93px !important;
  }
  .mrpc-93 {
    margin-right: 93px !important;
  }
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
  .ptpc-93 {
    padding-top: 93px !important;
  }
  .pbpc-93 {
    padding-bottom: 93px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-93 {
    margin-top: 93px !important;
  }
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
  .ptsp-93 {
    padding-top: 93px !important;
  }
  .pbsp-93 {
    padding-bottom: 93px !important;
  }
  .plsp-93 {
    padding-left: 93px !important;
  }
  .prsp-93 {
    padding-right: 93px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-94 {
    margin-top: 94px !important;
  }
  .mlpc-94 {
    margin-left: 94px !important;
  }
  .mrpc-94 {
    margin-right: 94px !important;
  }
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
  .ptpc-94 {
    padding-top: 94px !important;
  }
  .pbpc-94 {
    padding-bottom: 94px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-94 {
    margin-top: 94px !important;
  }
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
  .ptsp-94 {
    padding-top: 94px !important;
  }
  .pbsp-94 {
    padding-bottom: 94px !important;
  }
  .plsp-94 {
    padding-left: 94px !important;
  }
  .prsp-94 {
    padding-right: 94px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-95 {
    margin-top: 95px !important;
  }
  .mlpc-95 {
    margin-left: 95px !important;
  }
  .mrpc-95 {
    margin-right: 95px !important;
  }
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
  .ptpc-95 {
    padding-top: 95px !important;
  }
  .pbpc-95 {
    padding-bottom: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-95 {
    margin-top: 95px !important;
  }
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
  .ptsp-95 {
    padding-top: 95px !important;
  }
  .pbsp-95 {
    padding-bottom: 95px !important;
  }
  .plsp-95 {
    padding-left: 95px !important;
  }
  .prsp-95 {
    padding-right: 95px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-96 {
    margin-top: 96px !important;
  }
  .mlpc-96 {
    margin-left: 96px !important;
  }
  .mrpc-96 {
    margin-right: 96px !important;
  }
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
  .ptpc-96 {
    padding-top: 96px !important;
  }
  .pbpc-96 {
    padding-bottom: 96px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-96 {
    margin-top: 96px !important;
  }
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
  .ptsp-96 {
    padding-top: 96px !important;
  }
  .pbsp-96 {
    padding-bottom: 96px !important;
  }
  .plsp-96 {
    padding-left: 96px !important;
  }
  .prsp-96 {
    padding-right: 96px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-97 {
    margin-top: 97px !important;
  }
  .mlpc-97 {
    margin-left: 97px !important;
  }
  .mrpc-97 {
    margin-right: 97px !important;
  }
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
  .ptpc-97 {
    padding-top: 97px !important;
  }
  .pbpc-97 {
    padding-bottom: 97px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-97 {
    margin-top: 97px !important;
  }
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
  .ptsp-97 {
    padding-top: 97px !important;
  }
  .pbsp-97 {
    padding-bottom: 97px !important;
  }
  .plsp-97 {
    padding-left: 97px !important;
  }
  .prsp-97 {
    padding-right: 97px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-98 {
    margin-top: 98px !important;
  }
  .mlpc-98 {
    margin-left: 98px !important;
  }
  .mrpc-98 {
    margin-right: 98px !important;
  }
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
  .ptpc-98 {
    padding-top: 98px !important;
  }
  .pbpc-98 {
    padding-bottom: 98px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-98 {
    margin-top: 98px !important;
  }
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
  .ptsp-98 {
    padding-top: 98px !important;
  }
  .pbsp-98 {
    padding-bottom: 98px !important;
  }
  .plsp-98 {
    padding-left: 98px !important;
  }
  .prsp-98 {
    padding-right: 98px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-99 {
    margin-top: 99px !important;
  }
  .mlpc-99 {
    margin-left: 99px !important;
  }
  .mrpc-99 {
    margin-right: 99px !important;
  }
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
  .ptpc-99 {
    padding-top: 99px !important;
  }
  .pbpc-99 {
    padding-bottom: 99px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-99 {
    margin-top: 99px !important;
  }
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
  .ptsp-99 {
    padding-top: 99px !important;
  }
  .pbsp-99 {
    padding-bottom: 99px !important;
  }
  .plsp-99 {
    padding-left: 99px !important;
  }
  .prsp-99 {
    padding-right: 99px !important;
  }
}

@media screen and (min-width: 768px) {
  .mtpc-100 {
    margin-top: 100px !important;
  }
  .mlpc-100 {
    margin-left: 100px !important;
  }
  .mrpc-100 {
    margin-right: 100px !important;
  }
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
  .ptpc-100 {
    padding-top: 100px !important;
  }
  .pbpc-100 {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .mtsp-100 {
    margin-top: 100px !important;
  }
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
  .ptsp-100 {
    padding-top: 100px !important;
  }
  .pbsp-100 {
    padding-bottom: 100px !important;
  }
  .plsp-100 {
    padding-left: 100px !important;
  }
  .prsp-100 {
    padding-right: 100px !important;
  }
}

.p-tb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-tb-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.p-tb-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-tb-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-tb-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-tb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-tb-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.p-tb-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p-tb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-tb-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.p-tb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-tb-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.p-tb-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p-tb-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.p-tb-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.p-tb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-tb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-tb-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.p-tb-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.p-tb-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.p-tb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-tb-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.p-tb-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.p-tb-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.p-tb-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-tb-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-tb-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.p-tb-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.p-tb-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.p-tb-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.p-tb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-tb-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.p-tb-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-tb-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.p-tb-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.p-tb-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-tb-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.p-tb-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.p-tb-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.p-tb-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.p-tb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

/* ====================================================
Misc
==================================================== */
/* ====================================================
Base style & container
==================================================== */
body {
  color: black;
  font-family: -apple-system, blinkMacSystemFont, Meiryo, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  position: relative;
  overflow-x: hidden;
}

body:before {
  display: block;
  content: '';
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  body.has-overlay:before {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
}

.container {
  display: block;
  margin: 0 auto;
}

.container.container-md {
  max-width: 1040px;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .container.container-pc {
    max-width: 1040px;
    padding: 0 20px;
  }
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

/* ====================================================
Header & footer
==================================================== */
#main-header {
  padding: 8px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  #main-header {
    padding: 0 0 5px;
    height: 65px;
  }
}

#main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #main-header .container {
    padding: 0;
    display: block;
  }
}

#main-header .logo {
  width: 272px;
}

@media screen and (max-width: 1000px) {
  #main-header .logo {
    max-width: 210px;
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  #main-header .logo {
    width: 210px;
    margin: 15px 10px 0;
    float: left;
  }
}

@media screen and (max-width: 375px) {
  #main-header .logo {
    max-width: calc(100% - 145px);
  }
}

#main-header .global-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 700px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #main-header .global-nav {
    float: right;
  }
}

#main-header .global-nav .main-menu {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 80px);
}

@media screen and (max-width: 767px) {
  #main-header .global-nav .main-menu {
    position: fixed;
    top: 60px;
    left: 100%;
    width: 100%;
    z-index: 10;
    background-color: #fb4875;
    padding: 20px 20px 0;
    flex-wrap: wrap;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  #main-header .global-nav .main-menu.is-displayed {
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  #main-header .global-nav .main-menu li {
    border-top: 1px solid white;
    margin-bottom: 2.98%;
  }
  #main-header .global-nav .main-menu li.menu-lg {
    width: 48.5%;
  }
  #main-header .global-nav .main-menu li.menu-sm {
    width: 31.34%;
  }
  #main-header .global-nav .main-menu li.row-sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
    padding: 15px 0;
  }
  #main-header .global-nav .main-menu li.row-sp span {
    display: block;
    width: calc(100% - 165px);
    text-align: left;
    color: white;
    font-size: 15px;
    font-size: 1.5rem;
  }
  #main-header .global-nav .main-menu li.row-sp a {
    display: block;
    text-align: right;
    color: white;
    width: 155px;
    letter-spacing: -1.5px;
    font-size: 23px;
    font-size: 2.3rem;
  }
}

#main-header .global-nav .main-menu > li {
  display: flex;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#main-header .global-nav .main-menu > li:hover, #main-header .global-nav .main-menu > li.active {
  opacity: .5;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#main-header .global-nav .main-menu > li > a {
  display: block;
  width: 100%;
  text-align: center;
}

#main-header .global-nav .main-menu > li > a > img {
  width: 100%;
}

@supports (-ms-ime-align: auto) {
  #main-header .global-nav .main-menu > li > a > img {
    /*----MS Edge Browser CSS Start----*/
    width: 99%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #main-header .global-nav .main-menu > li > a > img {
    width: 99%;
  }
}

@media screen and (min-width: 768px) {
  #main-header .global-nav .main-menu > li > a > img.sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #main-header .global-nav .main-menu > li > a > img.pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #main-header .global-nav .main-menu > li.row-sp {
    display: none;
  }
}

#main-header .global-nav .cta {
  width: 60px;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  #main-header .global-nav .cta {
    margin-left: 0;
    margin-right: 65px;
  }
}

#main-header .global-nav .menu-sp-btn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  #main-header .global-nav .menu-sp-btn {
    display: none;
  }
}

#main-header .global-nav .menu-sp-btn .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: none;
}

#main-footer {
  border-top: 2px solid #ff006e;
}

#main-footer .top-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer {
    align-items: flex-start;
  }
}

@media screen and (min-width: 768px) {
  #main-footer .top-footer.no-content {
    display: flex;
    justify-content: flex-end;
  }
}

#main-footer .top-footer .lang-switcher-menu {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer .lang-switcher-menu {
    flex-wrap: wrap;
  }
}

#main-footer .top-footer .lang-switcher-menu li {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer .lang-switcher-menu li {
    margin-bottom: 20px;
  }
  #main-footer .top-footer .lang-switcher-menu li:last-of-type {
    margin-right: 0;
  }
}

@media (max-width: 360px) {
  #main-footer .top-footer .lang-switcher-menu li {
    margin-right: 6px;
  }
}

#main-footer .top-footer .lang-switcher-menu .btn {
  font-weight: bold;
  padding: 0 !important;
  text-align: center;
  width: 160px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer .lang-switcher-menu .btn {
    width: 156px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 320px) {
  #main-footer .top-footer .lang-switcher-menu .btn {
    width: 136px;
  }
}

#main-footer .top-footer .back-to-top {
  width: 105px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#main-footer .top-footer .back-to-top:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer .back-to-top {
    position: absolute;
    right: -20px;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  #main-footer .top-footer.top-footer-c-sp {
    display: block;
    text-align: center;
    padding: 20px 0 60px;
  }
  #main-footer .top-footer.top-footer-c-sp .footer-bnr-sp img {
    width: 100%;
  }
}

#main-footer .sitemap {
  padding: 20px 0;
  background-color: #fff2f9;
  border-top: 1px solid #ff006e;
}

#main-footer .sitemap .sitemap-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
}

#main-footer .sitemap .sitemap-wrapper .col {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper .col {
    flex-wrap: wrap;
    margin-bottom: -10px;
  }
}

#main-footer .sitemap .sitemap-wrapper .col.col-lg {
  width: 60%;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper .col.col-lg {
    width: auto;
  }
}

#main-footer .sitemap .sitemap-wrapper .col.col-sm {
  width: 35%;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper .col.col-sm {
    width: auto;
  }
}

#main-footer .sitemap .sitemap-wrapper ul {
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper ul {
    width: 100%;
  }
}

#main-footer .sitemap .sitemap-wrapper ul li {
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper ul li {
    padding-left: 10px;
  }
}

#main-footer .sitemap .sitemap-wrapper ul li:before {
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-bottom: 2px solid #ff006e;
  border-right: 2px solid #ff006e;
  position: absolute;
  top: 5px;
  left: 0;
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper ul li:before {
    width: 4px;
    height: 4px;
  }
}

@media screen and (min-width: 768px) {
  #main-footer .sitemap .sitemap-wrapper ul li:last-of-type {
    margin-bottom: 0;
  }
}

#main-footer .sitemap .sitemap-wrapper ul li a {
  color: #ff006e;
  text-decoration: underline;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  #main-footer .sitemap .sitemap-wrapper ul li a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

#main-footer .sitemap .sitemap-wrapper ul li a:hover {
  text-decoration: none;
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#main-footer .copyright {
  border-top: 1px solid #ff1375;
  border-bottom: 1px solid #ff1375;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 16px 10px;
}

@media screen and (max-width: 767px) {
  #main-footer .copyright {
    padding: 10px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

#main-footer .footer-caption {
  padding: 20px 0 60px;
  color: #fb4875;
  line-height: 1.5;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  #main-footer .footer-caption {
    font-size: 10px;
    font-size: 1rem;
  }
}

#main-footer .footer-caption dt {
  font-weight: bold;
}

#main-footer .footer-caption dd {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  #main-footer .footer-caption dd {
    margin-bottom: 15px;
  }
}

#main-footer .footer-bnr {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (min-width: 768px) {
  #main-footer .footer-bnr img {
    max-width: 335px;
    height: auto;
  }
}

#main-footer .footer-bnr:hover {
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#main-footer .footer-bnr.fix:hover {
  cursor: inherit;
}

#main-footer.lineup-hiphop_index-footer .top-footer .back-to-top {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  #main-footer.lineup-hiphop_index-footer .top-footer .back-to-top {
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  #main-footer.lineup-hiphop_index-footer {
    border-top: none;
  }
  #main-footer.lineup-hiphop_index-footer .top-footer {
    min-height: unset;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid #ff006e;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }
}

#main-footer.schedule-footer .top-footer {
  position: relative;
  display: block;
}

#main-footer.schedule-footer .top-footer > div {
  text-align: center;
}

#main-footer.schedule-footer .top-footer > div a img {
  margin: 0 auto;
}

#main-footer.schedule-footer .top-footer .back-to-top {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  #main-footer.schedule-footer .top-footer .back-to-top {
    right: -20px;
  }
}

/* ====================================================
Components
==================================================== */
.main-visual {
  /*background-color: #ffe1e9;*/
}

.main-visual.main-visual-while {
  background-color: #fff;
}

.main-visual .container.container-md {
  padding: 0;
  max-width: 1000px;
}

.main-visual .main-visual-slideshow, .main-visual .main-visual-slideshow-02 {
  margin-bottom: 45px !important;
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow, .main-visual .main-visual-slideshow-02 {
    margin-bottom: 35px !important;
  }
}

.main-visual .main-visual-slideshow .slick-arrow, .main-visual .main-visual-slideshow-02 .slick-arrow {
  width: 38px;
  height: 38px;
  border: 1px solid #ff167b;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}

.main-visual .main-visual-slideshow .slick-arrow:before, .main-visual .main-visual-slideshow-02 .slick-arrow:before {
  display: block;
  content: '';
  width: 13px;
  height: 13px;
  background-color: transparent;
  border-bottom: 2px solid #ff167b;
  border-right: 2px solid #ff167b;
  position: absolute;
}

.main-visual .main-visual-slideshow .slick-arrow.slick-prev, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-prev {
  left: 50%;
  transform: translate(-518px, -50%);
}

@media screen and (max-width: 1040px) {
  .main-visual .main-visual-slideshow .slick-arrow.slick-prev, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-prev {
    left: 5px;
    transform: translate(0, -50%);
  }
}

.main-visual .main-visual-slideshow .slick-arrow.slick-prev:before, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-prev:before {
  left: 2px;
  transform: translate(80%, -50%) rotate(135deg);
}

.main-visual .main-visual-slideshow .slick-arrow.slick-next, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-next {
  right: 50%;
  transform: translate(518px, -50%);
}

@media screen and (max-width: 1040px) {
  .main-visual .main-visual-slideshow .slick-arrow.slick-next, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-next {
    right: 5px;
    transform: translate(0, -50%);
  }
}

.main-visual .main-visual-slideshow .slick-arrow.slick-next:before, .main-visual .main-visual-slideshow-02 .slick-arrow.slick-next:before {
  transform: translate(40%, -50%) rotate(-45deg);
  left: 1px;
}

.main-visual .main-visual-slideshow .slick-dots, .main-visual .main-visual-slideshow-02 .slick-dots {
  bottom: -30px;
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow .slick-dots, .main-visual .main-visual-slideshow-02 .slick-dots {
    bottom: -25px;
  }
}

.main-visual .main-visual-slideshow .slick-dots li, .main-visual .main-visual-slideshow-02 .slick-dots li {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #999999;
  margin: 0 16px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow .slick-dots li, .main-visual .main-visual-slideshow-02 .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 10px;
  }
}

.main-visual .main-visual-slideshow .slick-dots li.slick-active, .main-visual .main-visual-slideshow-02 .slick-dots li.slick-active {
  background-color: #ff167b;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.main-visual .main-visual-slideshow .slick-dots li button, .main-visual .main-visual-slideshow-02 .slick-dots li button {
  display: none;
}

.main-visual .main-visual-slideshow .slick-slide:not(.slick-current), .main-visual .main-visual-slideshow-02 .slick-slide:not(.slick-current) {
  opacity: .5;
}

.main-visual .main-visual-slideshow .slide-items, .main-visual .main-visual-slideshow-02 .slide-items {
  position: relative;
}

.main-visual .main-visual-slideshow .slide-items .cta, .main-visual .main-visual-slideshow-02 .slide-items .cta {
  position: absolute;
  bottom: 12px;
  right: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow .slide-items .cta, .main-visual .main-visual-slideshow-02 .slide-items .cta {
    bottom: 6px;
    right: 6px;
  }
}

.main-visual .main-visual-slideshow .slide-items .cta:hover, .main-visual .main-visual-slideshow-02 .slide-items .cta:hover {
  opacity: .8;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow .slide-items .cta img.pc, .main-visual .main-visual-slideshow-02 .slide-items .cta img.pc {
    display: none;
  }
}

.main-visual .main-visual-slideshow .slide-items .cta img.pc.img-scale, .main-visual .main-visual-slideshow-02 .slide-items .cta img.pc.img-scale {
  transform: scale(0.84);
}

.main-visual .main-visual-slideshow .slide-items .cta img.sp, .main-visual .main-visual-slideshow-02 .slide-items .cta img.sp {
  width: 140px;
  height: 45px;
}

@media screen and (min-width: 768px) {
  .main-visual .main-visual-slideshow .slide-items .cta img.sp, .main-visual .main-visual-slideshow-02 .slide-items .cta img.sp {
    display: none;
  }
}

.main-visual .main-visual-slideshow img, .main-visual .main-visual-slideshow-02 img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .main-visual .main-visual-slideshow img.sp, .main-visual .main-visual-slideshow-02 img.sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .main-visual .main-visual-slideshow img.pc, .main-visual .main-visual-slideshow-02 img.pc {
    display: none;
  }
}

.banner-sm {
  text-align: center;
}

.banner-sm .txt-top {
  display: inline-block;
  color: #2b2b2b;
  font-weight: bold;
  position: relative;
  z-index: 1;
  padding: 0 40px;
  margin-bottom: 10px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .banner-sm .txt-top {
    padding: 0 30px;
    margin-bottom: 5px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.banner-sm .txt-top:before {
  display: block;
  content: '';
  background: url("../img/index/spark-left.png") no-repeat center center;
  width: 24px;
  height: 62px;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .banner-sm .txt-top:before {
    top: 50%;
    width: 17px;
    height: 42px;
  }
}

.banner-sm .txt-top:after {
  display: block;
  content: '';
  background: url("../img/index/spark-right.png") no-repeat center center;
  width: 26px;
  height: 64px;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .banner-sm .txt-top:after {
    top: 50%;
    width: 17px;
    height: 42px;
  }
}

.banner-sm .txt-bottom {
  display: block;
  text-align: left;
  color: #666666;
  margin-top: 5px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .banner-sm .txt-bottom {
    font-size: 10px;
    font-size: 1rem;
  }
}

.banner-sm a img {
  margin: 0 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.banner-sm a img:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .banner-sm a img.pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .banner-sm a img.sp {
    display: none;
  }
}

.banner-sm.opacity-none img:hover {
  opacity: 1;
}

.box-intro {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-intro .img {
  width: 268px;
}

.box-intro .desc {
  width: calc(100% - 288px);
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .box-intro .desc {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .box-intro .img, .box-intro .desc {
    width: 100%;
    margin-bottom: 10px;
  }
}

.accordion .accordion-items {
  border: 1px solid #ff006e;
  border-bottom: none;
  margin-bottom: 40px;
}

.accordion .accordion-items:last-of-type {
  margin-bottom: 0;
}

.accordion .accordion-items .accordion-label {
  color: #ff006e;
  text-align: center;
  padding: 15px 50px;
  position: relative;
  border-bottom: 1px solid #ff006e;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .accordion .accordion-items .accordion-label {
    padding: 0px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;
    font-size: 1.8rem;
  }
  .accordion .accordion-items .accordion-label small {
    display: block;
    width: 100%;
    font-size: 13px;
    font-size: 1.3rem;
  }
  .accordion .accordion-items .accordion-label strong {
    display: block;
    width: 100%;
    margin-top: -10px;
  }
}

.accordion .accordion-items .accordion-label:after {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
  width: 15px;
  height: 10px;
  background-size: 100% 100%;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .accordion .accordion-items .accordion-label:after {
    right: 10px;
  }
}

.accordion .accordion-items .accordion-label:hover {
  cursor: pointer;
}

.accordion .accordion-items .accordion-label.is-clicked:after {
  transform: translateY(-50%) rotate(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.accordion .accordion-items .accordion-content {
  display: none;
  padding: 30px 40px;
  border-bottom: 1px solid #ff006e;
}

@media screen and (max-width: 767px) {
  .accordion .accordion-items .accordion-content {
    padding: 20px 15px;
  }
}

.accordion .accordion-items .accordion-content.style02 {
  padding: 15px 40px 10px;
  background-color: #ffd0dc;
}

@media screen and (max-width: 767px) {
  .accordion .accordion-items .accordion-content.style02 {
    padding: 15px 10px 10px;
  }
}

.accordion.accordion-faq .accordion-items {
  border: none;
  margin-bottom: 0;
}

.accordion.accordion-faq .accordion-items .accordion-content {
  padding: 10px 20px 20px 20px;
}

.accordion.accordion-faq .accordion-items .accordion-label {
  padding: 11px 25px;
}

hr.divider {
  border: none;
  margin: 15px 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background: url("../img/lineup/hiphop/line-pink.png") repeat-x center center;
  background-size: auto 3px;
}

.modal-container {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 10;
  display: none;
}

.modal-container .modal {
  max-width: 1000px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ff006e;
}

@media screen and (max-width: 1040px) {
  .modal-container .modal {
    max-width: unset;
    width: 90%;
  }
}

.modal-container .modal .modal-close {
  display: block;
  content: '';
  background: url("../img/index/modal-close.png") no-repeat center center;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-container .modal .modal-ttl {
  text-align: center;
  margin-bottom: 14px;
}

.modal-container .modal .modal-ttl span {
  display: inline-block;
  color: #fb4875;
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .modal-container .modal .modal-ttl span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.modal-container .modal .modal-ttl span:before {
  display: block;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.modal-container .modal.modal-voice .modal-content .voice-list {
  margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list {
    margin-bottom: 45px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img {
  float: left;
  width: 100px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list .items .img {
    width: 200px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img img {
  width: 100%;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag {
  display: block;
  width: 100%;
  color: white;
  background-color: #ffb94a;
  text-align: center;
  padding: 5px 10px;
  margin-top: -2px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.hiphop {
  background-color: #f6698a;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.hura {
  background-color: #ff5262;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.burlesque {
  background-color: #9e61c5;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.ballet {
  background-color: #83d3c9;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.juzz {
  background-color: #ff8737;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .img .name-tag.tahiti {
  background-color: #83cf84;
}

.modal-container .modal.modal-voice .modal-content .voice-list .items .excerpt {
  float: right;
  width: calc(100% - 110px);
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list .items .excerpt {
    width: calc(100% - 210px);
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list .slick-arrow {
  display: block;
  content: '';
  background: url("../img/index/voice-arrow-slide.png") no-repeat center center;
  background-size: 100% 100%;
  width: 16px;
  height: 27px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  top: auto;
  transform: translate(0, 0);
}

.modal-container .modal.modal-voice .modal-content .voice-list .slick-arrow:before {
  display: none;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list .slick-arrow {
    bottom: -40px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list .slick-arrow.slick-prev {
  left: 0;
  transform: translate(0, 0) rotate(180deg);
}

.modal-container .modal.modal-voice .modal-content .voice-list .slick-arrow.slick-next {
  right: 0;
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 {
  margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list-02 {
    margin-bottom: 45px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .items .img {
  text-align: center;
  position: relative;
  width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .modal-container .modal.modal-voice .modal-content .voice-list-02 .items .img {
    width: 200px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .items .img img {
  width: 100%;
  margin: 0 auto;
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .slick-arrow {
  display: block;
  content: '';
  background: url("../img/index/voice-arrow-slide.png") no-repeat center center;
  background-size: 100% 100%;
  width: 16px;
  height: 27px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  top: auto;
  transform: translate(0, 0);
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .slick-arrow:before {
  display: none;
}

@media screen and (min-width: 768px) {
  .modal-container .modal.modal-voice .modal-content .voice-list-02 .slick-arrow {
    bottom: -40px;
  }
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .slick-arrow.slick-prev {
  left: 0;
  transform: translate(0, 0) rotate(180deg);
}

.modal-container .modal.modal-voice .modal-content .voice-list-02 .slick-arrow.slick-next {
  right: 0;
}

/* ====================================================
Typography
==================================================== */
.txt-basic {
  color: #2b2b2b;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .txt-basic {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.txt-basic.txt-basic-pink {
  color: #fb4875;
}

.txt-basic.txt-black {
  color: #000000;
}

.txt-basic.lg {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .txt-basic.lg {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.txt-lead {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .txt-lead {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.txt-sm {
  font-size: 10px;
  font-size: 1rem;
  color: #2b2b2b;
}

.txt-while, .txt-white {
  color: #fff !important;
}

.txt-link {
  color: #ff006e;
  text-decoration: underline;
}

.txt-link:hover {
  text-decoration: none;
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.txt-violet {
  color: #9e61c5;
}

.txt-yellow {
  color: #cb9f00;
}

.txt-orange {
  color: #cf8800;
}

.txt-red {
  color: #ff5262;
}

.txt-green {
  color: #6dc96e;
}

.txt-pink {
  color: #ff73ae;
}

.txt-dark-pink {
  color: #fb4875;
}

.txt-light-pink {
  color: #f6698a;
}

.txt-light-blu {
  color: #61b8ad;
}

.txt-light-orange {
  color: #ff8737;
}

.txt-md {
  font-size: 24px;
  font-size: 2.4rem;
  color: #2b2b2b;
}

@media screen and (max-width: 767px) {
  .txt-md {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.txt-pink-big {
  color: #fb4875;
  font-weight: 500;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .txt-pink-big {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.title {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 17.5px;
  font-size: 1.75rem;
}

@media screen and (max-width: 767px) {
  .head.pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .head.sp {
    display: none;
  }
}

.section-ttl {
  text-align: center;
}

.section-ttl .caption-sm {
  display: inline-block;
  padding: 0 30px 0 35px;
  text-align: center;
  color: #2b2b2b;
  line-height: 1.3;
  font-size: 24px;
  font-size: 2.4rem;
  position: relative;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-sm {
    padding: 0 20px 0 25px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-sm.caption-sm-2 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.section-ttl .caption-sm:before {
  display: block;
  content: '';
  background: url("../img/index/star-pattern-left.png") no-repeat center center;
  width: 26px;
  height: 28px;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-sm:before {
    width: 18px;
    height: 19px;
  }
}

.section-ttl .caption-sm:after {
  display: block;
  content: '';
  background: url("../img/index/star-pattern-right.png") no-repeat center center;
  width: 21px;
  height: 29px;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-sm:after {
    width: 14px;
    height: 19px;
  }
}

.section-ttl .caption-sm .pinky {
  color: #fb4875;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-sm.caption-sm-02 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.section-ttl .caption-lg {
  background-color: #fb4875;
  color: white;
  padding: 15px 10px;
  font-size: 36px;
  font-size: 3.6rem;
  position: relative;
  margin-top: 3px;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg {
    padding: 16px 10px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.section-ttl .caption-lg:before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #fb4875;
  position: absolute;
  left: 0;
  bottom: calc(100% + 3px);
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg:before {
    height: 1px;
    bottom: calc(100% + 2px);
  }
}

.section-ttl .caption-lg small {
  font-size: 28px;
  font-size: 2.8rem;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg small {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.section-ttl .caption-lg strong {
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg strong {
    line-height: 1.1;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.section-ttl .caption-lg.style02 {
  background-color: #ffe1e9;
  position: relative;
  color: #fb4875;
}

.section-ttl .caption-lg.style02:before, .section-ttl .caption-lg.style02:after {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #fec2d1;
  border-top: 1px solid #fb4a77;
  border-bottom: 1px solid #fb4a77;
  position: absolute;
  left: 0;
}

.section-ttl .caption-lg.style02:before {
  bottom: 100%;
}

.section-ttl .caption-lg.style02:after {
  top: 100%;
}

.section-ttl .caption-lg.style02 span {
  display: inline-block;
  position: relative;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg.style02 span {
    padding: 0 20px 0 25px;
  }
}

.section-ttl .caption-lg.style02 span:before {
  display: block;
  content: '';
  background: url("../img/index/star-pattern-left.png") no-repeat center center;
  width: 26px;
  height: 28px;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg.style02 span:before {
    width: 21px;
    height: 22px;
  }
}

.section-ttl .caption-lg.style02 span:after {
  display: block;
  content: '';
  background: url("../img/index/star-pattern-right-02.png") no-repeat center center;
  width: 23px;
  height: 36px;
  background-size: 100% 100%;
  position: absolute;
  top: 15px;
  right: 0;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-lg.style02 span:after {
    width: 15px;
    height: 22px;
    top: 10px;
  }
}

/* ====================================================
Buttons, input, .etc
==================================================== */
.btn {
  display: inline-block;
  border: 1px solid #ff197c;
  background-color: #fff;
  color: #fb4875;
  text-decoration: none !important;
  padding: 10px 110px;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 10px 115px;
  }
}

.btn.btn-arrow-right {
  position: relative;
  border-radius: 0;
}

.btn.btn-arrow-right:after {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #fb4875;
  border-right: 2px solid #fb4875;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.btn.btn-arrow-bottom {
  position: relative;
}

.btn.btn-arrow-bottom:after {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #fb4875;
  border-right: 2px solid #fb4875;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.btn.btn-solid {
  color: white;
  background-color: #ff006e;
}

.btn.btn-solid.btn-arrow-right:after {
  border-color: white;
}

.btn.btn-faq {
  font-size: 18px;
  font-size: 1.8rem;
}

.btn:hover {
  opacity: .7;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.btn.btn-arrow {
  position: relative;
}

.btn.btn-arrow:before {
  display: block;
  content: '';
  width: 13px;
  height: 8px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/index/arrow_down.png) no-repeat center center;
  background-size: cover;
}

.btn.btn-arrow.close:before {
  background: url(../img/index/arrow_up.png) no-repeat center center;
  background-size: cover;
}

.slick-slide, .slick-slide * {
  outline: none !important;
}

.button {
  display: inline-block;
  background-color: #000;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  margin-right: 5px;
  padding: 2px 10px;
  border: 0;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  transition: all .3s ease;
}

.button.button-pink {
  background-color: #f6698a;
}

.button.button-green {
  background-color: #83d3c9;
}

.button.button-orange {
  background-color: #ffb94a;
}

.button.button-red {
  background-color: #ff5262;
}

.button.button-blu {
  background-color: #83cf84;
}

.button.button-big {
  font-size: 24px;
  font-size: 2.4rem;
}

.button.button-big02 {
  font-size: 25px;
  font-size: 2.5rem;
}

@media (max-width: 767px) {
  .button.button-big02 {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.button.button-big03 {
  font-size: 28px;
  font-size: 2.8rem;
}

@media (max-width: 767px) {
  .button.button-big03 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) {
  br.sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  br.pc {
    display: none;
  }
}

.sp {
  display: block;
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

.sp-at-820 {
  display: none;
}

@media screen and (max-width: 830px) {
  .sp-at-820 {
    display: block !important;
  }
}

.pc {
  display: block;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

.pc-in {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .pc-in {
    display: none !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

.w100 {
  width: 100%;
}

.lh-1 {
  line-height: 1;
}

.bnr-map {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .bnr-map {
    margin-top: 10px;
    /*display: flex;
		justify-content: center;
		align-items: center;
		background: #f9f2b0;
		border: 2px solid #ffc939;
		> div:first-of-type{
			width: 68.9%;
		}
		> div:last-of-type{
			width: 33.1%;
		}*/
  }
}

.point-list-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .point-list-block {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.point-list-block > .items {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .point-list-block > .items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .point-list-block > .items:last-of-type {
    margin-bottom: 0;
  }
}

.point-list-block > .items .info .ttl {
  margin-bottom: 15px;
  text-align: center;
}

.point-list-block > .items .info .ttl img {
  max-width: 188px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .point-list-block > .items .info {
    width: 60%;
    padding-right: 10px;
  }
  .point-list-block > .items .info .ttl {
    text-align: left;
  }
  .point-list-block > .items .info .ttl img {
    max-width: 170px;
  }
}

.point-list-block > .items .info .excerpt {
  margin-bottom: 15px;
}

.point-list-block > .items .info .excerpt .txt-top {
  color: #fb4875;
  line-height: 1.4;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .point-list-block > .items .info .excerpt .txt-top {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.point-list-block > .items .info .excerpt .txt-bottom {
  color: #2b2b2b;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .point-list-block > .items .info .excerpt .txt-bottom {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 767px) {
  .point-list-block > .items .info .excerpt .txt-bottom.txt-bottom-fix {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.point-list-block > .items .img {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .point-list-block > .items .img {
    width: 40%;
  }
  .point-list-block > .items .img img {
    width: 100%;
  }
}

.btn-show-hidden {
  position: relative;
  padding: 6px 28px 6px 10px;
  z-index: 2;
  font-size: 13px;
  font-size: 1.3rem;
  border: 0 !important;
  text-decoration: underline !important;
}

@media screen and (min-width: 768px) {
  .btn-show-hidden.sp {
    display: none !important;
  }
}

.btn-show-hidden.pc, .btn-show-hidden.pc-in {
  border-width: 2px;
}

.btn-show-hidden.pc.is-clicked, .btn-show-hidden.pc-in.is-clicked {
  border-bottom-color: white;
  transition: ease 0ms;
}

.btn-show-hidden.pc:hover, .btn-show-hidden.pc-in:hover {
  opacity: 1 !important;
  transition: ease 0ms;
}

@media screen and (max-width: 767px) {
  .btn-show-hidden.pc, .btn-show-hidden.pc-in {
    display: none !important;
  }
}

.btn-show-hidden:after {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
  width: 14px;
  height: 9px;
  background-size: 100% 100%;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.btn-show-hidden.is-clicked:after {
  transform: translateY(-50%) rotate(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.hidden-content {
  display: none;
}

.point-list-child-content {
  width: 100%;
  background-color: #fff;
}

.point-list-child-content.sp {
  margin-top: 15px;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .point-list-child-content.sp {
    display: none !important;
  }
}

.point-list-child-content.pc {
  /*margin-top: -2px;*/
  border: 2px solid #ff006e;
  padding: 40px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .point-list-child-content.pc {
    display: none !important;
  }
}

.point-list-child-content .ttl {
  text-align: center;
  margin-bottom: 10px;
}

.point-list-child-content .ttl .ttl-top {
  padding: 0 5px;
  font-size: 15px;
  font-size: 1.5rem;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .point-list-child-content .ttl .ttl-top {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.point-list-child-content .ttl .ttl-top:after {
  display: block;
  content: '';
  width: 100%;
  height: 10px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: -1;
  transform: skewY(-1deg);
}

.point-list-child-content .ttl .ttl-top.no-stroke:after {
  display: none;
}

.point-list-child-content .ttl .ttl-bottom {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .point-list-child-content .ttl .ttl-bottom {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.point-list-child-content .ttl .ttl-bottom.stroke {
  position: relative;
  z-index: 1;
}

.point-list-child-content .ttl .ttl-bottom.stroke:after {
  display: block;
  content: '';
  width: 100%;
  height: 10px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: -1;
  transform: skewY(-1deg);
}

.point-list-child-content .flex-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .point-list-child-content .flex-row {
    flex-wrap: wrap;
  }
}

.point-list-child-content .flex-row .boxL {
  width: 45.65%;
}

@media screen and (max-width: 767px) {
  .point-list-child-content .flex-row .boxL {
    width: 100%;
    margin-bottom: 13px;
  }
}

.point-list-child-content .flex-row .boxR {
  width: 52.72%;
}

@media screen and (max-width: 767px) {
  .point-list-child-content .flex-row .boxR {
    width: 100%;
    margin-bottom: 10px;
  }
}

.point-list-child-content .flex-row .img-list {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .point-list-child-content .flex-row .img-list.col-2 {
    padding-right: 11.5%;
  }
}

.point-list-child-content .img-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 15px 0 10px;
}

@media screen and (max-width: 767px) {
  .point-list-child-content .img-list img {
    width: 100%;
  }
}

.point-list-child-content .img-list.col-3 .items {
  width: 31.43%;
}

.point-list-child-content .img-list.col-3.col-3-fix .items {
  width: 32.3%;
}

.point-list-child-content .img-list.col-2 .items {
  width: 47.76%;
}

.point-list-child-content .img-list.col-2.col-2-fix .items {
  width: 48.76%;
}

.point-list-child-content .caption-bottom {
  color: #2b2b2b;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .point-list-child-content .caption-bottom {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.dance-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .dance-list {
    flex-wrap: wrap;
  }
}

.dance-list .items {
  width: 32%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .dance-list .items {
    width: 100%;
  }
}

.dance-list .items:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.dance-list .items img {
  width: 100%;
}

.dance-list-02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .dance-list-02 {
    padding: 0 20px;
  }
}

.dance-list-02 .items {
  width: 32%;
  margin-bottom: 20px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items {
    width: 48%;
  }
}

.dance-list-02 .items:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.dance-list-02 .items a {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items a {
    flex-wrap: wrap;
  }
}

.dance-list-02 .items a .img {
  width: 150px;
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items a .img {
    width: 100%;
    margin-bottom: 10px;
  }
  .dance-list-02 .items a .img img {
    width: 100%;
  }
}

.dance-list-02 .items a .desc {
  width: calc(100% - 160px);
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items a .desc {
    width: 100%;
  }
}

.dance-list-02 .items a .desc .txt-top {
  line-height: 1.2;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items a .desc .txt-top {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.dance-list-02 .items a .desc .txt-bottom {
  color: #000;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .dance-list-02 .items a .desc .txt-bottom {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.dance-list-04 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dance-list-04 .items {
  width: 31.34%;
  margin-bottom: 2.99%;
}

.dance-list-04 .items figure {
  margin: 0;
}

.dance-list-04 .items figure img {
  width: 100%;
}

.dance-list-04 .items figure figcaption {
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .dance-list-04 .items figure figcaption {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.dance-list-04.dance-list-04-fix .items {
  margin-bottom: 2.3%;
}

.person-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.person-list .items {
  width: 9.5%;
  margin-bottom: .6%;
}

@media screen and (max-width: 767px) {
  .person-list .items {
    width: 19%;
    margin-bottom: 1.2%;
  }
}

.person-list .items img {
  width: 100%;
}

.person-list .items a {
  display: block;
}

.page-index .scrollbar {
  /*@media screen and (min-width: 768px) {
			height: 245px;
			overflow-y: scroll;
		}*/
}

@media screen and (max-width: 768px) {
  .page-index .scrollbar {
    overflow-y: scroll;
  }
}

.gallery-list {
  padding: 0 10px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .gallery-list {
    padding: 0;
  }
}

.gallery-list .items {
  width: 12%;
  margin-bottom: 2.15%;
}

@media screen and (max-width: 767px) {
  .gallery-list .items {
    width: 32.39%;
    margin-bottom: 1.8%;
  }
}

@media screen and (min-width: 768px) {
  .gallery-list .items.sp {
    display: none;
  }
}

.quick-menu-link {
  background-color: #ffd0dc;
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .quick-menu-link {
    padding: 20px 0;
  }
}

.quick-menu-link ul {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .quick-menu-link ul {
    flex-wrap: wrap;
    margin-bottom: -2.98%;
  }
}

.quick-menu-link ul li {
  display: flex;
  margin-right: 1.2%;
}

@media screen and (max-width: 767px) {
  .quick-menu-link ul li {
    margin-right: 0;
    margin-bottom: 2.98%;
  }
}

.quick-menu-link ul li:last-of-type {
  margin-right: 0;
}

.quick-menu-link ul li a {
  display: block;
  width: 100%;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.quick-menu-link ul li a img {
  width: 100%;
}

.quick-menu-link ul li a:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .quick-menu-link ul li.menu-lg {
    width: 48.5%;
  }
  .quick-menu-link ul li.menu-sm {
    width: 31.34%;
  }
}

.index-section-01 {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .index-section-01 .container {
    padding: 0 20px;
  }
}

.index-section-02 {
  padding: 50px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .index-section-02 {
    padding: 25px 0 45px;
  }
}

.index-section-02:after {
  display: block;
  content: '';
  width: 100%;
  height: 6px;
  background: url("../img/index/stripe-divider.png") repeat-x center center;
  background-size: auto 6px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.index-section-02 .section-ttl {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .index-section-02 .section-ttl {
    margin-bottom: 15px;
  }
}

.index-section-02 .btn-container {
  margin-bottom: 45px;
}

@media screen and (max-width: 767px) {
  .index-section-02 .btn-container {
    margin-bottom: 25px;
  }
  .index-section-02 .btn-container .btn {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.index-section-03 {
  padding-top: 30px;
}

.index-section-03 .section-ttl {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .index-section-03 .section-ttl {
    margin-bottom: 15px;
  }
}

.index-section-03 .section-ttl .caption-sm {
  position: relative;
  padding: 0 25px;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 767px) {
  .index-section-03 .section-ttl .caption-sm {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 0 10px;
  }
  .index-section-03 .section-ttl .caption-sm:before {
    display: block;
    content: '';
    width: 78px;
    height: 39px;
    background: url("../img/index/dance-lineup-txt.png") no-repeat right top;
    background-size: cover;
    position: absolute;
    left: 96%;
    top: 40%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.index-section-03 .section-ttl .caption-sm:after {
  display: none;
}

@media screen and (min-width: 768px) {
  .index-section-03 .section-ttl .caption-sm:before {
    display: block;
    content: '';
    background: url("../img/index/star-pattern-left.png") no-repeat center center;
    width: 26px;
    height: 28px;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 8%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .index-section-03 .section-ttl .caption-sm:after {
    display: block;
    content: '';
    background: url("../img/index/star-pattern-right.png") no-repeat center center;
    width: 21px;
    height: 29px;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .index-section-03 .section-ttl .caption-sm .icn-sparcle {
    position: relative;
    padding: 0 20px;
  }
  .index-section-03 .section-ttl .caption-sm .icn-sparcle:before {
    display: block;
    content: '';
    background: url("../img/index/star-pattern-left.png") no-repeat center center;
    width: 20px;
    height: 22px;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 8%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .index-section-03 .section-ttl .caption-sm .icn-sparcle:after {
    display: block;
    content: '';
    background: url("../img/index/star-pattern-right.png") no-repeat center center;
    width: 18px;
    height: 26px;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.index-section-03 .section-ttl .caption-sm .pinky {
  position: relative;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .index-section-03 .section-ttl .caption-sm .pinky {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .index-section-03 .section-ttl .caption-sm .pinky:before {
    display: block;
    content: '';
    width: 116px;
    height: 58px;
    background: url("../img/index/dance-lineup-txt.png") no-repeat right top;
    background-size: cover;
    position: absolute;
    right: -165px;
    top: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .index-section-03 .container {
    padding: 0;
  }
}

.index-section-04 {
  padding-top: 30px;
  padding-bottom: 45px;
}

@media screen and (max-width: 767px) {
  .index-section-04 {
    padding-top: 15px;
    padding-bottom: 25px;
  }
}

.index-section-04 .section-ttl {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .index-section-04 .section-ttl {
    margin-bottom: 15px;
  }
  .index-section-04 .section-ttl .caption-lg {
    padding: 4px 10px 10px;
  }
  .index-section-04 .section-ttl .caption-lg small {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .index-section-04 .section-ttl .caption-lg strong {
    line-height: 1;
  }
}

.index-section-04 .btn-container {
  margin-top: 14px;
}

.index-section-05 {
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .index-section-05 {
    padding-bottom: 30px;
  }
}

.index-section-05 .section-ttl {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .index-section-05 .section-ttl {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .index-section-05 .section-ttl .caption-lg {
    padding: 6px 10px 10px;
  }
}

.index-section-05 .section-ttl .caption-lg small {
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 767px) {
  .index-section-05 .section-ttl .caption-lg small {
    font-size: 13px;
    font-size: 1.3rem;
  }
  .index-section-05 .section-ttl .caption-lg small strong {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .index-section-05 .btn-container {
    padding-top: 15px;
  }
  .index-section-05 .btn-container .btn {
    padding-left: 90px;
    padding-right: 90px;
  }
}

.index-section-extra {
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .index-section-extra {
    padding-bottom: 25px;
  }
}

.index-section-extra .quick-menu-link {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .index-section-extra .quick-menu-link {
    margin-bottom: 30px;
  }
}

.page-index .modal-container .modal {
  width: 90%;
  max-width: 320px;
}

@media (min-width: 320px) {
  .page-index .modal-container .modal {
    max-width: calc(40.17857vw + 191.42857px);
  }
}

@media (min-width: 768px) {
  .page-index .modal-container .modal {
    max-width: 500px;
  }
}

.page-lineup_index .dance-list-02 {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .page-lineup_index .dance-list-02 {
    margin-bottom: 0;
    padding: 0;
  }
}

.page-lineup_index .dance-list-02 .txt-top {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .page-lineup_index .dance-list-02 .items {
    width: 31.33%;
    margin-right: 2%;
  }
  .page-lineup_index .dance-list-02 .items:nth-child(3n) {
    margin-right: 0;
  }
}

.page-lineup_index .dance-list-02 .items a .img {
  /*@media screen and (min-width: 768px) and (max-width: 1039px){
						width: 200px;
					}*/
}

@media screen and (min-width: 768px) {
  .page-lineup_index .dance-list-02 .items a .img {
    width: 48%;
  }
}

.page-lineup_index .dance-list-02 .items a .desc {
  /*@media screen and (min-width: 768px) and (max-width: 1039px){
						/width: calc(100% - 210px);
					}*/
}

@media screen and (min-width: 768px) {
  .page-lineup_index .dance-list-02 .items a .desc {
    width: calc(50% - 10px);
  }
  .page-lineup_index .dance-list-02 .items a .desc .txt-top {
    margin-bottom: 5px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.page-lineup_index .dance-list-02 .items a .desc .txt-bottom {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .page-lineup_index .dance-list-02 .items a .desc .txt-bottom {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 768px) {
  .page-lineup_index .ico-center:before, .page-lineup_index .ico-center:after {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.page-lineup_index .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .page-lineup_index .caption-lg.h-80 {
    padding: 17px 10px 16px;
  }
}

.page-lineup_index .bg_pink .banner-sm {
  max-width: 335px;
}

@media screen and (max-width: 767px) {
  .page-lineup_index .bg_pink .banner-sm {
    max-width: 670px;
    padding: 0 20px;
  }
}

.page-lineup-cheer_index .lineup-hiphop-section-01:before {
  width: 125px;
  height: 415px;
  left: 40%;
  top: 160px;
  background: url("../img/lineup/cheer/dance-girl-img.png") no-repeat top left/100%;
}

@media screen and (max-width: 900px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01:before {
    top: 170px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01:before {
    width: 100px;
    transform: translate(0, 0);
    left: 0;
    top: 20px;
  }
}

@media screen and (max-width: 660px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01:before {
    width: 50px;
    transform: translate(0, 0);
    left: 2%;
    top: 10px;
  }
}

@media screen and (max-width: 395px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01:before {
    width: 40px;
    transform: translate(0, 0);
    left: 2%;
    top: 28px;
  }
}

@media screen and (max-width: 375px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01:before {
    width: 35px;
    left: 0;
    top: 45px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01 .caption-lg {
    padding: 6px 10px 8px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-cheer_index .lineup-hiphop-section-01 .caption-lg .txt-while {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-cheer_index .index-section-04 .section-ttl .caption-lg small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-cheer_index .form-container .form-cta .btn {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-lineup-kpop_index .lineup-hiphop-section-01:before {
  width: 170px;
  height: 500px;
  left: 60%;
  top: 110px;
  background: url("../img/lineup/kpop/dance-girl-img.png") no-repeat top left/100%;
  transform: translateX(-516%);
}

@media screen and (max-width: 1330px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01:before {
    left: -5px;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1000px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01:before {
    width: 80px;
    height: 236px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01:before {
    top: 32px;
    width: 55px;
    height: 163px;
  }
}

@media screen and (max-width: 414px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01:before {
    left: -2px;
  }
}

@media screen and (max-width: 345px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01:before {
    top: 55px;
    width: 45px;
  }
}

.page-lineup-kpop_index .lineup-hiphop-section-01 .caption-lg {
  padding: 15px 140px;
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-lg {
    padding: 6px 35px 8px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-lg small {
    font-size: 16px !important;
    font-size: 1.6rem !important;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-lg strong {
    font-size: 19px !important;
    font-size: 1.9rem !important;
  }
}

@media screen and (max-width: 430px) {
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-sm {
    padding: 0 70px 0 85px;
  }
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-sm:after {
    right: 50px;
  }
  .page-lineup-kpop_index .lineup-hiphop-section-01 .caption-sm:before {
    left: 65px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .index-section-04 .section-ttl .caption-lg small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-kpop_index .form-container .form-cta .btn {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-lineup-jazz_index .lineup-hiphop-section-01:before {
  width: 246px;
  height: 415px;
  left: 55%;
  top: 140px;
  background: url("../img/lineup/jazz/dance-girl-img-pc.png") no-repeat top left/100%;
}

@media screen and (max-width: 767px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01:before {
    width: 100px;
    transform: translate(0, 0);
    left: 0;
    top: 20px;
    background: url("../img/lineup/jazz/dance-girl-img.png") no-repeat top left/100%;
  }
}

@media screen and (max-width: 370px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01:before {
    width: 60px;
    height: 200px;
    transform: translate(0, 0);
    left: 0;
    top: 48px;
  }
}

@media screen and (max-width: 324px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01:before {
    width: 55px;
    height: 200px;
    transform: translate(0, 0);
    left: 0;
    top: 45px;
  }
}

.page-lineup-tahitian-index .lineup-hiphop-section-01:before {
  width: 150px;
  height: 420px;
  left: 40%;
  top: 110px;
  background: url("../img/lineup/tahitian/dance-girl-img-pc.png") no-repeat top left/100%;
}

@media screen and (max-width: 1330px) {
  .page-lineup-tahitian-index .lineup-hiphop-section-01:before {
    left: -5px;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1000px) {
  .page-lineup-tahitian-index .lineup-hiphop-section-01:before {
    width: 80px;
    height: 236px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-tahitian-index .lineup-hiphop-section-01:before {
    top: 32px;
    width: 55px;
    height: 163px;
  }
}

@media screen and (max-width: 414px) {
  .page-lineup-tahitian-index .lineup-hiphop-section-01:before {
    left: -2px;
  }
}

@media screen and (max-width: 345px) {
  .page-lineup-tahitian-index .lineup-hiphop-section-01:before {
    top: 55px;
    width: 45px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01 .plrsp-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.page-lineup-burlesque-index .lineup-hiphop-section-01:before {
  content: '';
  display: block;
  width: 190px;
  height: 431px;
  left: 47%;
  top: 115px;
  background: url("../img/lineup/burlesque/dance-girl-img-pc.png") no-repeat top left/100%;
}

@media screen and (max-width: 1000px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01:before {
    left: 51%;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01:before {
    width: 90px;
    height: 204px;
    transform: translate(0, 0);
    left: 0;
    top: 11px;
  }
}

@media screen and (max-width: 430px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01:before {
    width: 60px;
    height: 136px;
    transform: translate(0, 0);
    left: -2px;
    top: 46px;
  }
}

@media screen and (max-width: 340px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01:before {
    width: 47px;
    height: 115px;
    transform: translate(0, 0);
    left: 0;
    top: 48px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-01 .caption-lg .txt-sm-sp {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-burlesque-index .lineup-hiphop-section-02 .caption-lg {
    padding: 12px 10px 13px;
  }
}

.page-lineup-burlesque-index .point-list-block .items .info .excerpt .txt-bottom {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .page-lineup-burlesque-index .point-list-block .items .info .excerpt .txt-bottom {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.lineup-hiphop-section-01 {
  padding-top: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-01 {
    padding-top: 25px;
  }
}

.lineup-hiphop-section-01:before {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/dance-girl-img.png") no-repeat center center;
  width: 204px;
  height: 414px;
  background-size: 100% 100%;
  position: absolute;
  top: 130px;
  left: 50%;
  z-index: 1;
  transform: translateX(-245%);
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-01:before {
    width: 80px;
    height: 164px;
    transform: translateX(-230%);
    top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-01 span.pc {
    display: none;
  }
}

.lineup-hiphop-section-01 .section-ttl .caption-lg {
  line-height: 1.2;
  letter-spacing: -2px;
}

.lineup-hiphop-section-01 .section-ttl .caption-lg strong, .lineup-hiphop-section-01 .section-ttl .caption-lg small {
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-01 .section-ttl .caption-lg strong, .lineup-hiphop-section-01 .section-ttl .caption-lg small {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-01 .section-ttl .caption-lg .font-19 {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1000px) {
  .lineup-hiphop-section-01 .section-ttl .caption-lg .font19 {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.lineup-hiphop-section-02 {
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-02 {
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-02 .caption-lg {
    padding: 8px 10px 10px;
    line-height: 1.2;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-02.style02 .caption-lg {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.lineup-hiphop-section-03 {
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-03 {
    padding-top: 25px;
  }
}

.lineup-hiphop-section-03 .section-ttl .caption-lg {
  padding: 22px 10px 25px;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-03 .section-ttl .caption-lg {
    padding: 4px 10px 10px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-03 .section-ttl .caption-lg small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.lineup-hiphop-section-04 {
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-04 {
    padding-top: 20px;
  }
}

.lineup-hiphop-section-04 .section-ttl .caption-lg {
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-04 .section-ttl .caption-lg {
    padding: 4px 10px 12px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-04 .section-ttl .caption-lg small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.lineup-hiphop-section-05 {
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-section-05 {
    padding-bottom: 20px;
  }
}

.section-heading {
  text-align: center;
}

.section-heading p {
  display: inline-block;
  padding: 5px 10px;
  line-height: 1.2;
  position: relative;
  font-size: 40px;
  font-size: 4rem;
}

@media screen and (max-width: 767px) {
  .section-heading p {
    font-size: 20px;
    font-size: 2rem;
  }
}

.section-heading p small {
  font-size: 26px;
  font-size: 2.6rem;
}

@media screen and (max-width: 767px) {
  .section-heading p small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.section-heading p:after {
  display: block;
  content: '';
  width: 100%;
  height: 20px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: -1;
  transform: rotate(-1deg);
}

@media screen and (max-width: 767px) {
  .section-heading p:after {
    height: 10px;
  }
}

.section-heading img {
  display: block;
  margin: 20px auto;
  max-width: 510px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .section-heading img {
    margin: 10px auto 12px;
  }
}

.dance-list-03 .items {
  margin-bottom: 30px;
}

.dance-list-03 .items .item-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .item-inner {
    display: block;
  }
}

.dance-list-03 .items .item-inner .img {
  width: 350px;
}

.dance-list-03 .items .item-inner .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .item-inner .img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.dance-list-03 .items .item-inner .desc {
  width: calc(100% - 370px);
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .item-inner .desc {
    width: 100%;
  }
}

.dance-list-03 .items .item-inner .desc .excerpt {
  color: #000;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .item-inner .desc .excerpt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items {
    margin-bottom: 15px;
  }
}

.dance-list-03 .items:last-of-type {
  margin-bottom: 0;
}

.dance-list-03 .items .ttl {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .ttl {
    margin-bottom: 5px;
  }
}

.dance-list-03 .items .ttl img {
  width: 39px;
  height: 39px;
}

.dance-list-03 .items .ttl p {
  padding-left: 10px;
  color: #fb4875;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .ttl p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.dance-list-03 .items .flex-row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.dance-list-03 .items .flex-row .img {
  width: 31.34%;
}

.dance-list-03 .items .flex-row .img img {
  width: 100%;
}

.dance-list-03 .items .flex-row .desc {
  width: 64.18%;
}

.dance-list-03 .items .flex-row .desc .excerpt {
  color: #000;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .dance-list-03 .items .flex-row .desc .excerpt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.lineup-hiphop-intro .ttl-lg {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 11px 20px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .ttl-lg {
    padding: 5px;
    height: 40px;
    align-items: center;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lineup-hiphop-intro .ttl-lg.style01 {
  color: white;
  background-color: #fb4875;
}

.lineup-hiphop-intro .ttl-lg.style02 {
  color: #fb4875;
  background-color: #ffd0dc;
}

.lineup-hiphop-intro .ttl-lg img {
  margin: 0 10px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .ttl-lg img {
    width: 18px;
    height: 22px;
  }
}

.lineup-hiphop-intro .txt-basic {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .txt-basic {
    font-size: 10px;
    font-size: 1rem;
  }
}

.lineup-hiphop-intro .desc-list-lv1 {
  margin: 10px 0 0;
}

.lineup-hiphop-intro .desc-list-lv1 > dt {
  display: inline-block;
  color: #fb4875;
  margin-bottom: 5px;
  position: relative;
  font-size: 20px;
  font-size: 2rem;
  height: 28px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .desc-list-lv1 > dt {
    font-size: 15px;
    font-size: 1.5rem;
    height: 21px;
  }
}

@media screen and (max-width: 350px) {
  .lineup-hiphop-intro .desc-list-lv1 > dt {
    font-size: 13px;
    font-size: 1.3rem;
    height: 18px;
  }
}

.lineup-hiphop-intro .desc-list-lv1 > dt:after {
  display: block;
  content: '';
  width: 100%;
  height: 10px;
  background-color: #ffe2e9;
  position: absolute;
  bottom: 3px;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .desc-list-lv1 > dt:after {
    height: 7px;
  }
}

@media screen and (max-width: 350px) {
  .lineup-hiphop-intro .desc-list-lv1 > dt:after {
    height: 5px;
  }
}

.lineup-hiphop-intro .desc-list-lv1 > dd {
  margin-bottom: 15px;
}

.lineup-hiphop-intro .desc-list-lv1 > dd:last-of-type {
  margin-bottom: 0;
}

.lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 > dt, .lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 > dd {
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 > dt, .lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 > dd {
    font-size: 10px;
    font-size: 1rem;
  }
}

.lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 .img-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 .img-right {
    margin-bottom: 5px;
  }
}

.lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 .img-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro .desc-list-lv1 > dd .desc-list-lv2 .img-left {
    margin-bottom: 5px;
  }
}

.lineup-hiphop-intro-02 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-02 {
    flex-wrap: wrap;
  }
}

.lineup-hiphop-intro-02 .blockL {
  width: 56%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-02 .blockL {
    width: 100%;
    margin-bottom: 15px;
  }
}

.lineup-hiphop-intro-02 .blockL .img {
  width: 135px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-02 .blockL .img {
    width: 67px;
  }
}

.lineup-hiphop-intro-02 .blockL .excerpt {
  width: calc(100% - 150px);
  color: #2b2b2b;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-02 .blockL .excerpt {
    width: calc(100% - 82px);
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.lineup-hiphop-intro-02 .blockR {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-02 .blockR {
    width: 100%;
    text-align: center;
  }
}

.lineup-hiphop-intro-03 .map-preview {
  margin: 0;
  padding: 0;
}

.lineup-hiphop-intro-03 .map-preview figcaption {
  display: flex;
  justify-content: space-between;
}

.lineup-hiphop-intro-03 .map-preview figcaption .txt {
  width: calc(100% - 235px);
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .map-preview figcaption .txt {
    width: calc(100% - 135px);
  }
}

.lineup-hiphop-intro-03 .map-preview figcaption .cta {
  width: 220px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .map-preview figcaption .cta {
    width: 110px;
    text-align: right;
  }
}

.lineup-hiphop-intro-03 .map-preview figcaption .cta .btn {
  padding: 10px 60px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .map-preview figcaption .cta .btn {
    padding: 10px 20px 10px 8px;
    display: inline-block;
    width: 102px;
    font-size: 13px;
    font-size: 1.3rem;
  }
  .lineup-hiphop-intro-03 .map-preview figcaption .cta .btn.btn-arrow-right:after {
    right: 6px;
  }
}

.lineup-hiphop-intro-03 ul li {
  position: relative;
  padding-left: 15px;
}

.lineup-hiphop-intro-03 ul li:before {
  display: block;
  content: '＊';
  position: absolute;
  top: 0;
  left: 0;
}

.lineup-hiphop-intro-03 a {
  text-decoration: underline;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.lineup-hiphop-intro-03 a:hover {
  opacity: .7;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.lineup-hiphop-intro-03 .txt {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .txt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.lineup-hiphop-intro-03 .ttl {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .ttl {
    margin-bottom: 10px;
  }
}

.lineup-hiphop-intro-03 .ttl span {
  display: inline-block;
  position: relative;
  color: #fb4875;
  padding: 0 10px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .ttl span {
    padding: 0 5px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.lineup-hiphop-intro-03 .ttl span:after {
  display: block;
  content: '';
  width: 100%;
  height: 15px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: skewY(-3deg);
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 .ttl span:after {
    height: 10px;
  }
}

.lineup-hiphop-intro-03 table th, .lineup-hiphop-intro-03 table td {
  border: 2px solid #fb4875;
  padding: 15px 10px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .lineup-hiphop-intro-03 table th, .lineup-hiphop-intro-03 table td {
    padding: 10px;
    font-size: 11px;
    font-size: 1.1rem;
    padding: 5px 10px;
  }
}

@media screen {
  .lineup-hiphop-intro-03 table th, .lineup-hiphop-intro-03 table td {
    border-width: 1px;
  }
}

.lineup-hiphop-intro-03 table th {
  text-align: center;
  background-color: #ffe1e9;
}

.person-list-02 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .person-list-02 {
    flex-wrap: wrap;
  }
}

.person-list-02 .items {
  display: flex;
  justify-content: space-between;
  width: 49%;
}

@media screen and (max-width: 767px) {
  .person-list-02 .items {
    width: 100%;
    margin-bottom: 15px;
  }
  .person-list-02 .items:last-of-type {
    margin-bottom: 0;
  }
}

.person-list-02 .items .img {
  width: 180px;
}

@media screen and (max-width: 767px) {
  .person-list-02 .items .img {
    width: 90px;
  }
}

.person-list-02 .items .excerpt {
  text-align: justify;
  width: calc(100% - 200px);
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .person-list-02 .items .excerpt {
    width: calc(100% - 100px);
    font-size: 10px;
    font-size: 1rem;
  }
}

.steps-block {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .steps-block {
    flex-wrap: wrap;
  }
}

.steps-block .items {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .steps-block .items {
    width: 100%;
  }
}

.steps-block .items .ttl {
  display: flex;
  align-items: flex-end;
  background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
  padding-bottom: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.steps-block .items .ttl img {
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}

@media screen and (max-width: 767px) {
  .steps-block .items .ttl {
    position: relative;
    padding-left: 65px;
    padding-bottom: 5px;
  }
  .steps-block .items .ttl img {
    width: 55px;
    position: absolute;
    left: 0;
    top: 20%;
    transform: translateY(-50%);
  }
}

.steps-block .items .ttl p {
  margin-top: 5px;
  display: block;
  font-weight: bold;
  color: #fb4875;
  margin-left: 10px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .steps-block .items .ttl p {
    margin-top: 0;
    margin-left: 0;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .steps-block .items .desc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.steps-block .items .desc .img {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .steps-block .items .desc .img {
    width: 158px;
  }
}

.steps-block .items .desc .excerpt {
  margin-top: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .steps-block .items .desc .excerpt {
    margin-top: 10px;
    width: calc(100% - 168px);
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.steps-block .arrows {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .5%;
}

@media screen and (max-width: 767px) {
  .steps-block .arrows {
    width: 100%;
    margin: 15px 0 10px;
  }
  .steps-block .arrows img {
    transform: rotate(90deg);
    height: 25px;
  }
}

.steps-block .arrows:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

.steps-block.style02 .ttl {
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .steps-block.style02 .ttl {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .steps-block.style02 .desc .img {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .steps-block.style02 .desc .excerpt {
    margin-top: 0;
    width: calc(100% - 115px);
  }
}

.steps-block.style03 {
  flex-wrap: wrap;
}

.steps-block.style03 .items, .steps-block.style03 .arrows {
  width: 100%;
}

.steps-block.style03 .arrows {
  margin-top: 20px;
}

.steps-block.style03 .arrows img {
  transform: rotate(90deg);
}

.steps-block.style03 .flex-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.steps-block.style03 .flex-row .img {
  width: 200px;
}

.steps-block.style03 .flex-row .desc {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: calc(100% - 225px);
}

@media screen and (max-width: 767px) {
  .steps-block.style03 .flex-row .desc {
    flex-wrap: wrap;
    align-content: center;
  }
}

.steps-block.style03 .flex-row .desc .ttl {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 0;
}

.steps-block.style03 .flex-row .desc .ttl img {
  position: relative;
  top: auto;
  left: auto;
  margin-bottom: 5px;
  transform: translate(0, 0);
}

.steps-block.style03 .flex-row .desc .ttl p {
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .steps-block.style03 .flex-row .desc .ttl p {
    margin-left: 5px;
  }
}

.steps-block.style03 .flex-row .desc .excerpt {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .steps-block.style03 .items.sp .desc .img {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .steps-block.style03 .items.sp .desc .excerpt {
    width: calc(100% - 115px);
    margin-top: 0;
  }
}

.report-block {
  background-color: #fffaa3;
  padding: 0 40px 40px;
  position: relative;
  min-height: 240px;
}

@media screen and (max-width: 767px) {
  .report-block {
    padding: 0 20px 20px;
    min-height: auto;
  }
}

.report-block .ttl {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 0;
  width: 73.91%;
  margin-bottom: 25px;
  background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
}

@media screen and (max-width: 767px) {
  .report-block .ttl {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}

.report-block .ttl img {
  margin: 0 20px;
}

@media screen and (max-width: 767px) {
  .report-block .ttl img {
    margin: 0 15px 0;
    width: 62px;
  }
}

.report-block .ttl p {
  color: #fb4875;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .report-block .ttl p {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.report-block .excerpt {
  width: 73.91%;
  overflow: hidden;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .report-block .excerpt {
    width: 100%;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.report-block .excerpt strong {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .report-block .excerpt strong {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 768px) {
  .report-block .excerpt img {
    width: 19.9%;
    position: absolute;
    top: 10%;
    right: 4%;
  }
}

@media screen and (max-width: 767px) {
  .report-block .excerpt img {
    float: right;
    width: 100px;
    margin-left: 10px;
  }
}

.full-width-banner {
  background-color: #ffe1e9;
  padding: 20px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .full-width-banner {
    padding: 10px 0;
  }
}

.full-width-banner:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  border-top: 2px solid #ffe1e9;
  border-bottom: 2px solid white;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.full-width-banner .container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 230px;
}

@media screen and (max-width: 767px) {
  .full-width-banner .container {
    padding-right: 160px;
  }
}

@media screen and (max-width: 445px) {
  .full-width-banner .container {
    padding-right: 140px;
  }
}

.full-width-banner small {
  color: #fb4875;
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .full-width-banner small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.full-width-banner strong {
  display: inline-block;
  color: #fb4875;
  position: relative;
  z-index: 1;
  line-height: 1.2;
  padding-right: 10px;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .full-width-banner strong {
    font-size: 25px;
    font-size: 2.5rem;
    display: block;
  }
}

@media screen and (max-width: 445px) {
  .full-width-banner strong {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.full-width-banner strong:after {
  display: block;
  content: '';
  width: 100%;
  height: 20px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .full-width-banner strong:after {
    height: 15px;
  }
}

@media screen and (max-width: 445px) {
  .full-width-banner strong:after {
    height: 9px;
  }
}

@media screen and (max-width: 350px) {
  .full-width-banner strong:after {
    height: 5px;
  }
}

.full-width-banner img {
  position: absolute;
  bottom: -20px;
  right: 20px;
}

@media screen and (max-width: 767px) {
  .full-width-banner img {
    width: 130px;
    bottom: -10px;
  }
}

.form-container .form-ttl {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1040px;
  padding: 0 20px 0 65px;
}

@media screen and (max-width: 767px) {
  .form-container .form-ttl {
    padding-left: 20px;
    margin-bottom: 25px;
  }
}

.form-container .form-ttl:before {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/shield-icon.png") no-repeat center center;
  background-size: 100% 100%;
  width: 58px;
  height: 63px;
  position: absolute;
  top: -10px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .form-container .form-ttl:before {
    top: 2px;
    width: 24px;
    height: 27px;
    left: 16px;
  }
}

.form-container .form-ttl .txt-top {
  color: #fb4875;
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-ttl .txt-top {
    font-weight: bold;
    padding-left: 25px;
    padding-top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .form-container .form-ttl .txt-top.fix-fonts {
    font-size: 10px;
    font-size: 1rem;
  }
}

.form-container .form-ttl .txt-bottom {
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-ttl .txt-bottom {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.form-container .form-content {
  background-color: #fff2f9;
  padding: 40px 0;
}

@media screen and (max-width: 768px) {
  .form-container .form-content {
    padding: 20px 0;
  }
}

.form-container .form-content .form-group {
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .form-container .form-content .form-group {
    margin-bottom: 15px;
  }
}

.form-container .form-content .form-group:last-of-type {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .form-container .form-content .form-group:last-of-type {
    margin-bottom: 10px;
  }
}

.form-container .form-content .txt-sm {
  color: #000;
}

.form-container .form-content .label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  /*padding-bottom: 10px;
			background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
			background-size: auto 3px;*/
}

@media screen and (max-width: 767px) {
  .form-container .form-content .label {
    background: none;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
}

.form-container .form-content .label .tag {
  border: 2px solid;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 15px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .label .tag {
    border-width: 1px;
    margin-left: 10px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.form-container .form-content .label .tag.required {
  color: #ff006e;
  border-color: #ff006e;
}

.form-container .form-content .txt-lg {
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .txt-lg {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.form-container .form-content .txt-sm {
  margin-left: 10px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .txt-sm {
    font-size: 10px;
    font-size: 1rem;
  }
}

.form-container .form-content .form-group .row:nth-of-type(2) .txt-sm {
  margin-left: 0;
}

.form-container .form-content .flex-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-container .form-content .flex-row .col-24p {
  width: 24%;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .flex-row .col-24p {
    width: 49%;
  }
}

.form-container .form-content .flex-row .col-49p {
  width: 49%;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .flex-row .col-49p {
    width: 100%;
  }
}

.form-container .form-content .custom-radio input[type="radio"] {
  appearance: none;
  display: none;
}

.form-container .form-content .custom-radio input[type="radio"] + label {
  display: flex;
  align-items: center;
  content: '';
  height: 100%;
  color: #2b2b2b;
  background-color: white;
  border: 1px solid #999999;
  padding: 19px 15px 19px 70px;
  position: relative;
  border-radius: 0;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-radio input[type="radio"] + label {
    padding: 9px 15px 9px 45px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 374px) {
  .form-container .form-content .custom-radio input[type="radio"] + label {
    padding: 9px 15px 9px 35px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.form-container .form-content .custom-radio input[type="radio"] + label:before {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  border: 4px solid #787878;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-radio input[type="radio"] + label:before {
    border-width: 2px;
    width: 13px;
    height: 13px;
    left: 10px;
  }
}

.form-container .form-content .custom-radio input[type="radio"] + label:after {
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  background-color: #fb4875;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 28px;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-radio input[type="radio"] + label:after {
    left: 15px;
  }
}

.form-container .form-content .custom-radio input[type="radio"]:checked + label:after {
  opacity: 1;
  visibility: visible;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.form-container .form-content .custom-select {
  position: relative;
  border: 1px solid #999999;
  height: 64px;
}

@media screen and (max-width: 768px) {
  .form-container .form-content .custom-select {
    height: auto;
  }
}

.form-container .form-content .custom-select:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 13px solid #535353;
  border-bottom: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .form-container .form-content .custom-select:before {
    right: 10px;
  }
}

.form-container .form-content .custom-select:after {
  display: block;
  content: '';
  background-color: #cccccc;
  width: 62px;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-select:after {
    width: 40px;
    height: 40px;
    background-size: 14px 8px;
  }
}

.form-container .form-content .custom-select select {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  padding: 19px 15px;
  background-color: white;
  color: #2b2b2b;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-select select {
    padding: 12px 15px;
    height: 40px;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1;
    box-sizing: border-box;
  }
}

.form-container .form-content .custom-select select:focus {
  outline: none;
}

.form-container .form-content .custom-input {
  position: relative;
}

.form-container .form-content .custom-input .input-placeholder {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding: 0 20px;
  color: #2b2b2b;
  font-size: 16px;
  font-size: 1.6rem;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-input .input-placeholder {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.form-container .form-content .custom-input input[type='text'], .form-container .form-content .custom-input input[type='tel'], .form-container .form-content .custom-input input[type='email'] {
  appearance: none;
  display: block;
  width: 100%;
  background-color: white;
  padding: 19px 15px;
  border: 1px solid #999999;
  border-radius: 0;
  color: #2b2b2b;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .custom-input input[type='text'], .form-container .form-content .custom-input input[type='tel'], .form-container .form-content .custom-input input[type='email'] {
    padding: 12px 15px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.form-container .form-content .custom-input input[type='text']:focus, .form-container .form-content .custom-input input[type='tel']:focus, .form-container .form-content .custom-input input[type='email']:focus {
  outline: none;
}

.form-container .form-content .form-notice {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .form-container .form-content .form-notice {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

.form-container .form-content .form-notice a {
  text-decoration: underline;
}

.form-container .form-content .form-notice a:hover {
  text-decoration: none;
}

.form-container .form-content .form-notice.style02 li {
  position: relative;
  padding-left: 10px;
}

.form-container .form-content .form-notice.style02 li:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}

.form-container .form-cta .btn {
  padding: 15px 70px;
}

@media screen and (max-width: 767px) {
  .form-container .form-cta .btn {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

.video-container {
  display: block;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}

.video-container .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  text-align: center;
}

.video-container .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container .videoWrapper video {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .video-container img {
    padding: 0 20px;
    margin-bottom: 10px;
  }
}

.video-wrapper {
  background-color: #fffaa3;
  padding: 15px 20px 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .video-wrapper {
    padding: 15px 20px;
  }
}

.video-wrapper .ttl-top {
  width: 100%;
  text-align: center;
  color: #fb4875;
  margin-bottom: 5px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .video-wrapper .ttl-top {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.video-wrapper .ttl-bottom {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .video-wrapper .ttl-bottom {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01 .section-ttl .caption-lg {
    padding: 7px 10px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01 .section-ttl .caption-lg small {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 370px) {
  .page-lineup-jazz_index .lineup-hiphop-section-01 .section-ttl .caption-lg strong {
    letter-spacing: -3px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-jazz_index .index-section-04 .section-ttl .caption-lg {
    padding: 1px 10px 3px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-jazz_index .lineup-hiphop-section-04 .section-ttl .caption-lg {
    padding: 4px 10px 10px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-hiphop-summary .dance-list-03 .items .ttl {
    margin-bottom: 10px;
  }
  .page-lineup-hiphop-summary .dance-list-03 .items .item-inner .img,
  .page-lineup-hiphop-summary .dance-list-03 .items .item-inner .desc {
    width: 100%;
  }
  .page-lineup-hiphop-summary .dance-list-03 .items {
    margin-bottom: 20px;
  }
  .page-lineup-hiphop-summary .lineup-belly-summary-section-01 {
    margin-bottom: 30px;
  }
}

.page-lineup-hiphop_basic .list-item-basic .item {
  width: 50%;
  padding: 0 10px 0;
  float: left;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .page-lineup-hiphop_basic .list-item-basic .item {
    padding: 20px 0;
    margin: 0;
  }
  .page-lineup-hiphop_basic .section-ttl .caption-lg {
    padding: 9px 10px;
  }
  .page-lineup-hiphop_basic .list-item-basic .item {
    width: 100%;
    float: none;
  }
}

@media (min-width: 768px) {
  .page-lineup-hiphop_basic .list-item-basic .item {
    background-image: none;
  }
}

@media (max-width: 800px) {
  .page-lineup-hiphop_basic .btn-basic {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 768px) {
  .hChange .dance-list-03 .items .item-inner .desc {
    width: auto;
  }
}

.lineup-belly-section-01 {
  padding-top: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .lineup-belly-section-01 {
    padding-top: 25px;
  }
  .lineup-belly-section-01 .section-ttl .caption-lg {
    padding: 5px 10px 7px;
    font-size: 18px;
    font-size: 1.8rem;
  }
  .lineup-belly-section-01 .section-ttl .caption-lg small {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 374px) {
  .lineup-belly-section-01 .section-ttl .caption-lg small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .lineup-belly-section-01 .section-ttl .caption-lg strong {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 374px) {
  .lineup-belly-section-01 .section-ttl .caption-lg strong {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lineup-belly-section-01:before {
  display: block;
  content: '';
  background: url("../img/lineup/belly/dance-girl-img.png") no-repeat center center;
  width: 222px;
  height: 487px;
  background-size: 100% 100%;
  position: absolute;
  top: 70px;
  right: 50%;
  z-index: 1;
  transform: translateX(240%);
}

@media screen and (max-width: 1100px) {
  .lineup-belly-section-01:before {
    width: 200px;
    height: 439px;
    top: 140px;
  }
}

@media screen and (max-width: 1000px) {
  .lineup-belly-section-01:before {
    width: 140px;
    height: 307px;
    top: 190px;
    right: 40%;
  }
}

@media screen and (max-width: 840px) {
  .lineup-belly-section-01:before {
    right: 45%;
  }
}

@media screen and (max-width: 767px) {
  .lineup-belly-section-01:before {
    width: 81px;
    height: 177px;
    transform: translateX(230%);
    top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .lineup-belly-section-01:before {
    right: 45%;
  }
}

@media screen and (max-width: 400px) {
  .lineup-belly-section-01:before {
    right: 46%;
    width: 70px;
    height: 150px;
  }
}

@media screen and (max-width: 374px) {
  .lineup-belly-section-01:before {
    right: 52%;
  }
}

.lineup-belly-section-01 .accordion-items .dance-list-03 .items-brt {
  padding-top: 18px;
  border-top: 1px solid #ff006e;
}

.fix-font {
  font-size: 17px !important;
}

@media screen and (max-width: 1050px) {
  .fix-font {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .fix-font {
    font-size: 11px !important;
  }
}

.lineup-belly-summary-section-01 {
  margin-bottom: 40px;
}

.lineup-belly-summary-section-02 {
  margin-bottom: 40px;
  background-color: #ffb94a;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .lineup-belly-summary-section-02 {
    padding-left: 0;
    padding-right: 0;
  }
}

.lineup-belly-summary-section-02 .ttl {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .lineup-belly-summary-section-02 .ttl img {
    width: 500px;
    height: auto;
  }
}

.lineup-belly-summary-section-03 .btn-container, .lineup-belly-basic-section-03 .btn-container {
  display: flex;
  justify-content: center;
}

.lineup-belly-summary-section-03 .btn-container .btn, .lineup-belly-basic-section-03 .btn-container .btn {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .lineup-belly-summary-section-03 .btn-container .btn, .lineup-belly-basic-section-03 .btn-container .btn {
    margin: 5px 0;
    padding: 13px 25px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-belly-summary-section-03 .btn-container, .lineup-belly-basic-section-03 .btn-container {
    flex-wrap: wrap;
  }
  .lineup-belly-summary-section-03 .btn-container .btn, .lineup-belly-basic-section-03 .btn-container .btn {
    width: 100%;
  }
}

.page-lineup-belly_summary .pt-14 {
  padding-top: 14px;
}

.page-lineup-belly_summary .pb-8 {
  padding-bottom: 8px;
}

.page-lineup-belly_summary .lineup-belly-summary-section-02 .box-intro {
  margin-top: -16px;
}

@media screen and (min-width: 768px) {
  .dance-list-05 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.dance-list-05 .item {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .dance-list-05 .item.sm {
    width: 48%;
  }
  .dance-list-05 .item.lg {
    width: 100%;
  }
}

.dance-list-05 .item:after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
  background-size: auto 3px;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (min-width: 768px) {
  .dance-list-05 .item:after {
    display: none;
  }
}

.dance-list-05 .item:last-child {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.dance-list-05 .item:last-child:after {
  display: none;
}

.dance-list-05 .item .img {
  margin-bottom: 10px;
}

.dance-list-05 .item .img img {
  width: 100%;
}

.dance-list-05 .item .desc a {
  color: #f6698a;
  text-decoration: underline;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .dance-list-05 .item .desc a {
    font-size: 20px;
    font-size: 2rem;
    display: inline-block;
    margin-bottom: 14px;
  }
}

.dance-list-05 .item .desc p {
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .dance-list-05 .item .desc p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.dance-list-05 .item .desc .pink {
  color: #f6698a;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .dance-list-05 .item .desc .pink {
    font-size: 20px;
    font-size: 2rem;
    display: inline-block;
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-belly_basic .section-ttl .caption-lg {
    padding: 9px 10px;
  }
}

.lineup-ballet-section-01 {
  padding-top: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .lineup-ballet-section-01 {
    padding-top: 25px;
  }
  .lineup-ballet-section-01 .section-ttl .caption-lg {
    padding: 2px 10px;
  }
  .lineup-ballet-section-01 .section-ttl .caption-lg * {
    font-size: 19px;
    font-size: 1.9rem;
  }
  .lineup-ballet-section-01 .section-ttl .caption-lg small {
    letter-spacing: -1px;
  }
  .lineup-ballet-section-01 .section-ttl .caption-lg strong {
    font-size: 20px;
    font-size: 2rem;
  }
}

.lineup-ballet-section-01:before {
  display: block;
  content: '';
  background: url("../img/lineup/ballet/dance-girl-img.png") no-repeat center center;
  width: 219px;
  height: 496px;
  background-size: 100% 100%;
  position: absolute;
  top: 45px;
  left: 49%;
  z-index: 1;
  transform: translateX(-277%);
}

@media screen and (min-width: 860px) and (max-width: 1040px) {
  .lineup-ballet-section-01:before {
    width: 160px;
    height: 380px;
    top: 173px;
    left: 52%;
  }
}

@media screen and (min-width: 768px) and (max-width: 860px) {
  .lineup-ballet-section-01:before {
    width: 146px;
    height: 340px;
    top: 233px;
    left: 52%;
  }
}

@media screen and (max-width: 767px) {
  .lineup-ballet-section-01:before {
    width: 80px;
    height: 179px;
    transform: translateX(-237%);
    top: 21px;
  }
}

@media screen and (max-width: 375px) {
  .lineup-ballet-section-01:before {
    width: 50px;
    height: 112px;
    left: 38%;
    top: 102px;
  }
}

.page-lineup-ballet_summary .dance-list-03 .items .ttl {
  margin-bottom: 10px;
}

.page-lineup-ballet_summary .btn.btn-arrow-right {
  width: 100%;
}

.lineup-hula-section-01 {
  padding-top: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .lineup-hula-section-01 {
    padding-top: 25px;
  }
  .lineup-hula-section-01 .section-ttl .caption-lg {
    padding: 5px 10px 7px;
  }
  .lineup-hula-section-01 .section-ttl .caption-lg * {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.lineup-hula-section-01:before {
  display: block;
  content: '';
  background: url("../img/lineup/hula/dance-girl-img.png") no-repeat center center;
  width: 246px;
  height: 444px;
  background-size: 100% 100%;
  position: absolute;
  top: 65px;
  left: 50%;
  z-index: 1;
  transform: translateX(-237%);
}

@media screen and (max-width: 767px) {
  .lineup-hula-section-01:before {
    width: 93px;
    height: 168px;
    transform: translateX(-230%);
    top: 24px;
  }
}

@media screen and (max-width: 375px) {
  .lineup-hula-section-01:before {
    top: 40px;
    left: 52%;
  }
}

.lineup-hula-summary-section-01 {
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .lineup-hula-summary-section-01 {
    padding-bottom: 30px;
  }
}

.lineup-hula-summary-section-02 {
  background-color: #ff5f71;
  margin-bottom: 40px;
  padding: 20px;
}

.lineup-hula-summary-section-02 .ttl img {
  width: 485px;
}

@media screen and (max-width: 767px) {
  .lineup-hula-summary-section-02 .ttl img {
    width: 100%;
  }
}

.lineup-hula-summary-section-03 .btn-container, .lineup-hula-basic-section-02 .btn-container {
  display: flex;
  justify-content: center;
}

.lineup-hula-summary-section-03 .btn-container .btn, .lineup-hula-basic-section-02 .btn-container .btn {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .lineup-hula-summary-section-03 .btn-container .btn, .lineup-hula-basic-section-02 .btn-container .btn {
    margin: 5px 0;
    padding: 10px 25px;
  }
}

@media screen and (max-width: 767px) {
  .lineup-hula-summary-section-03 .btn-container, .lineup-hula-basic-section-02 .btn-container {
    flex-wrap: wrap;
  }
  .lineup-hula-summary-section-03 .btn-container .btn, .lineup-hula-basic-section-02 .btn-container .btn {
    width: 100%;
  }
}

.page-lineup-hula_summary .lineup-hula-summary-section-02 {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .page-lineup-hula_summary .dance-list-03 .items .item-inner .desc {
    width: 100%;
  }
  .page-lineup-hula_summary .dance-list-03 .items .item-inner .img {
    width: 100%;
  }
  .page-lineup-hula_summary .lineup-hula-summary-section-02 {
    padding: 20px 0;
  }
  .page-lineup-hula_summary .lineup-hula-summary-section-03 .btn-container .btn {
    padding: 13px 25px;
  }
}

@media (min-width: 768px) {
  .page-lineup-hula_summary .lineup-hula-summary-section-03 .btn {
    padding: 10px 60px;
  }
}

@media (min-width: 1000px) {
  .page-lineup-hula_summary .lineup-hula-summary-section-03 .btn {
    padding: 10px 110px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-hula_basic .section-ttl .caption-lg {
    padding: 9px 10px;
  }
  .page-lineup-hula_basic .dance-list-05 .item:after {
    display: none;
  }
  .page-lineup-hula_basic .lineup-hula-basic-section-02 .btn-container .btn {
    padding: 13px 25px;
  }
  .page-lineup-hula_basic .dance-list-05 .item {
    margin: 0;
    padding-bottom: 5px;
  }
}

.lineup-tahitians-summary-section-02 .ttl img {
  width: 485px;
}

@media screen and (max-width: 767px) {
  .lineup-tahitians-summary-section-02 .ttl img {
    width: 100%;
  }
}

.form-container-02 .form-group-wrapper {
  display: block;
  overflow: hidden;
  margin-right: -20px;
}

@supports (-ms-ime-align: auto) {
  .form-container-02 .form-group-wrapper {
    /*----MS Edge Browser CSS Start----*/
    list-style: none;
  }
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper {
    margin-right: 0;
  }
  .form-container-02 .form-group-wrapper.form-group-wrapper-block .form-group {
    width: 48% !important;
    margin: 0 !important;
  }
  .form-container-02 .form-group-wrapper.form-group-wrapper-block .form-group:nth-child(2) {
    float: right;
  }
  .form-container-02 .form-group-wrapper.form-group-wrapper-block .form-group button {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 768px) {
  .form-container-02 .form-group-wrapper.center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .form-container-02 .form-group-wrapper.center .form-group {
    float: none;
    margin: 0 5px 10px;
  }
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper.center .form-group {
    width: 31.74%;
  }
  .form-container-02 .form-group-wrapper.center .form-group:first-of-type {
    margin-right: 2.3% !important;
  }
  .form-container-02 .form-group-wrapper.center .form-group:nth-of-type(3n) {
    margin-right: 0;
  }
}

.form-container-02 .form-group-wrapper .form-group {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  list-style: none;
}

.form-container-02 .form-group-wrapper .form-group.col-md {
  width: 362px;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group.col-md {
    width: 100%;
  }
}

.form-container-02 .form-group-wrapper .form-group.col-sm {
  width: 176px;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group.col-sm {
    width: 31.74%;
    margin-right: 2.3%;
    margin-bottom: 2.3%;
  }
  .form-container-02 .form-group-wrapper .form-group.col-sm:nth-child(3n + 1) {
    margin-right: 0;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] {
  display: none;
  border: none;
  appearance: none;
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 0 15px 0 50px;
  position: relative;
  border: 2px solid transparent;
  height: 50px;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label {
    padding: 0 3px 0 20px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:before {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  border: 4px solid #787878;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:before {
    width: 10px;
    height: 10px;
    border-width: 2px;
    left: 10px;
  }
}

@media screen and (max-width: 350px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:before {
    left: 5px;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:after {
  display: block;
  content: '';
  width: 11px;
  height: 11px;
  background-color: #ff006e;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 26px;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:after {
    width: 6px;
    height: 6px;
    left: 14px;
  }
}

@media screen and (max-width: 350px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:after {
    left: 9px;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label img {
    width: 12px;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label small {
  display: block;
  width: 100%;
  font-size: 13px;
  font-size: 1.3rem;
}

@media screen and (max-width: 767px) {
  .form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"] + label small {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"]:checked + label {
  border-color: #ff006e;
  color: #ff006e;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.form-container-02 .form-group-wrapper .form-group .custom-radio input[type="radio"]:checked + label:after {
  opacity: 1;
  visibility: visible;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.form-container-02 .form-group-wrapper .form-group button {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  appearance: none;
  color: #000;
  padding: 0 15px;
  height: 50px;
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.form-container-02 .form-group-wrapper .form-group button:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

.form-container-02 .form-group-wrapper .form-group button:focus {
  outline: none;
}

.form-container-02 .form-group-wrapper .form-group button:hover {
  cursor: pointer;
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.schedule-tab .tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: -2px;
}

@media screen and (max-width: 500px) {
  .schedule-tab .tab-list.tab-list-sp {
    display: block;
    padding: 0 20px;
    overflow: hidden;
  }
  .schedule-tab .tab-list.tab-list-sp .tab {
    float: left;
    width: 48%;
  }
  .schedule-tab .tab-list.tab-list-sp .tab:nth-child(2n) {
    float: right;
  }
  .schedule-tab .tab-list.tab-list-sp .tab a {
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0;
  }
}

.schedule-tab .tab-list .tab a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 50px;
  background-color: #ffe5ec;
  color: #2b2b2b;
  margin: 0 5px;
  border: 2px solid transparent;
  font-size: 17px;
  font-size: 1.7rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.schedule-tab .tab-list .tab a.active {
  border-color: #ff006e;
  color: #ff006e;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.schedule-tab .schedule-calendar .wrapper {
  display: block;
  margin: 0 auto;
  max-width: 632px;
  padding-left: 24px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .schedule-tab .schedule-calendar .wrapper {
    padding-left: 22px;
  }
}

.schedule-tab .schedule-calendar .wrapper.wrap-scroll-date {
  max-height: 682px;
  overflow-y: auto;
  max-width: 640px;
  padding-left: 0px;
}

@media screen and (max-width: 768px) {
  .schedule-tab .schedule-calendar .wrapper.wrap-scroll-date {
    padding-left: 3px;
    padding-right: 2px !important;
  }
}

.schedule-tab .schedule-calendar .calendar-header {
  position: relative;
  margin-bottom: 3px;
}

.schedule-tab .schedule-calendar .calendar-header:before, .schedule-tab .schedule-calendar .calendar-header:after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: url("../img/lineup/hiphop/line-pink.png") repeat-x center center;
  background-size: auto 3px;
}

.schedule-tab .schedule-calendar .calendar-header:before {
  bottom: 100%;
}

.schedule-tab .schedule-calendar .calendar-header:after {
  top: 100%;
}

.schedule-tab .schedule-calendar .calendar-header table {
  width: 100%;
  table-layout: fixed;
}

.schedule-tab .schedule-calendar .calendar-header table td {
  padding: 8px 5px;
  width: 13.54%;
  line-height: 1;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .schedule-tab .schedule-calendar .calendar-header table td {
    padding: 6px 5px;
  }
}

.schedule-tab .schedule-calendar .calendar-header .sat {
  color: #1bb3ce;
}

.schedule-tab .schedule-calendar .calendar-header .sun {
  color: #f6698a;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar {
  margin-bottom: 15px;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 15px;
  position: relative;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar.line {
  margin-bottom: 30px;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar.line:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
  background-size: auto 3px;
  left: 0;
  bottom: -15px;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar .time-bg {
  width: 30px !important;
  background: #fb4875 !important;
  position: relative;
  color: #fff;
  padding-top: 8px;
  font-size: 22px;
  text-align: center;
}

@media (max-width: 640px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar .time-bg {
    width: 20px !important;
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar .time-bg .ver-rl {
  display: block;
  transform: rotate(90deg);
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td {
  width: calc(100% - 32px / 7);
  width: calc(100% / 7);
  box-sizing: border-box;
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  vertical-align: top;
  background: url("../img/schedule/cal-items-bg.png") no-repeat center center;
  background-size: 100% 100%;
}

@media screen and (max-width: 320px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td {
    border-right: 2px solid white;
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-teal .top {
  background-color: #4cbcae;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-teal .bottom {
  border: 2px solid #4cbcae;
  color: #4cbcae;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-pink .top {
  background-color: #ff5262;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-pink .bottom {
  border: 2px solid #ff5262;
  color: #ff5262;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltbrown .top {
  background-color: #e69d11;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltbrown .bottom {
  border: 2px solid #e69d11;
  color: #e69d11;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-violet .top {
  background-color: #9e61c5;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-violet .bottom {
  border: 2px solid #9e61c5;
  color: #9e61c5;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltpink .top {
  background-color: #f6698a;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltpink .bottom {
  border: 2px solid #f6698a;
  color: #f6698a;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-orange .top {
  background-color: #ff8737;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-orange .bottom {
  border: 2px solid #ff8737;
  color: #ff8737;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltgreen .top {
  background-color: #6dc96e;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-ltgreen .bottom {
  border: 2px solid #6dc96e;
  color: #6dc96e;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-cheer .top {
  background-color: #ff73ae;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-cheer .bottom {
  border: 2px solid #ff73ae;
  color: #ff73ae;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-kpop .top {
  background-color: #c8a900;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items.color-kpop .bottom {
  border: 2px solid #c8a900;
  color: #c8a900;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  padding: 8px 5px 4px;
  line-height: 1;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top {
    padding: 2px;
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top .date {
  position: relative;
  margin-top: 5px;
  line-height: 1;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 500px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top .date {
    margin-top: 3px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top .date:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background: url("../img/index/shield-sm-green-yellow.png");
  width: 16px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 500px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .top .date:after {
    height: 13px;
    width: 10px;
    top: 50%;
    right: -3px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .bottom {
  background-color: #fff;
  padding: 8px 5px 4px 3px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
  line-height: 1;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 500px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .bottom {
    padding: 4px 0 3px;
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .schedule-tab .schedule-calendar .calendar-content .table-calendar td .items .bottom {
    font-size: 9px;
    font-size: 0.9rem;
    font-family: 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
  }
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td > span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.schedule-tab .schedule-calendar .calendar-content .table-calendar td > span:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.time-bg {
  background: #fff !important;
  position: relative;
}

.time-bg .time-navi-table li > span {
  display: block;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .time-bg .time-navi-table li > span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.time-navi {
  position: absolute;
  top: 0;
  left: 5px;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .time-navi {
    left: 0;
  }
}

.time-navi li {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.time-navi li.prev {
  position: relative;
  top: 0;
  transform: translateY(0);
}

.time-navi li.prev a {
  display: block;
  min-width: 22px;
  padding-top: 40px;
}

@media screen and (max-width: 500px) {
  .time-navi li.prev a {
    padding-top: 30px;
  }
}

.time-navi li.prev:before {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
  width: 15px;
  height: 10px;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 12%;
  transform: translateX(-50%) rotate(180deg);
  pointer-events: none;
}

.time-navi li.next {
  position: absolute;
  top: auto;
  bottom: 0;
  transform: translateY(0);
}

.time-navi li.next a {
  display: block;
  min-width: 22px;
  padding-bottom: 40px;
}

@media screen and (max-width: 500px) {
  .time-navi li.next a {
    padding-bottom: 30px;
  }
}

.time-navi li.next:before {
  display: block;
  content: '';
  background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
  width: 15px;
  height: 10px;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  .time-navi li.current {
    left: 5px;
  }
}

.time-navi li a {
  display: block;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 500px) {
  .time-navi li a {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.time-navi li a span.ver-rl {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.schedule-tab .schedule-calendar #tab02 .slick-vertical .slick-slide {
  min-height: 52vh;
  overflow: auto;
}

@media screen and (min-width: 601px) {
  .schedule-tab .schedule-calendar #tab02 .slick-vertical .slick-slide {
    min-height: 458px;
  }
}

.schedule-tab .schedule-calendar #tab02 .slick-vertical .slick-slide {
  min-height: 52vh;
  overflow: auto;
}

@media screen and (min-width: 601px) {
  .schedule-tab .schedule-calendar #tab02 .slick-vertical .slick-slide {
    min-height: 458px;
  }
}

.schedule-tab .schedule-calendar .tab-content {
  width: 100%;
}

.bg_pink {
  background-color: #ffd0dc;
  padding: 24px 0;
  position: relative;
}

.bg_pink:before, .bg_pink:after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
}

.bg_pink:before {
  top: 1px;
}

.bg_pink:after {
  bottom: 1px;
}

.bg_pink .banner-sm {
  max-width: 454px;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .bg_pink .banner-sm.banner-sm-sp {
    max-width: 100%;
    padding: 0 20px;
  }
}

.bg_pink.bg_pink_fix:before, .bg_pink.bg_pink_fix:after {
  display: none;
}

.line-bottom {
  position: relative;
}

.line-bottom:after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
  background-size: auto 3px;
}

.next-month {
  display: flex;
  width: 100%;
  padding-top: 5px;
}

.next-month a {
  position: relative;
  color: #fb4875;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.next-month .prev {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 15px;
  margin-left: -45px;
}

@media screen and (max-width: 500px) {
  .next-month .prev {
    margin-left: -15px;
  }
}

.next-month .prev:before {
  display: block;
  content: '';
  background: url(../img/schedule/accor-arrow-left.png) no-repeat center center;
  width: 10px;
  height: 15px;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s ease;
}

.next-month .next {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15px;
  margin-right: 10px;
}

.next-month .next:before {
  display: block;
  content: '';
  background: url(../img/schedule/accor-arrow-right.png) no-repeat center center;
  width: 10px;
  height: 15px;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s ease;
}

.heading-month {
  background-color: #ffd0dc;
  padding: 3px 8px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
}

.table-caption {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .table-caption {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.wrap-scroll-date02 {
  max-height: 455px;
  overflow-y: auto;
  max-width: 640px !important;
  padding-left: 0px !important;
}

@media screen and (max-width: 768px) {
  .wrap-scroll-date02 {
    padding-left: 3px;
    padding-right: 2px !important;
  }
  .wrap-scroll-date02 .time-bg .time-navi-table {
    left: 3px !important;
  }
}

.page-mainv {
  display: table;
  width: 100%;
  height: 80px;
  background: url("../img/about/voice/bg-title.png") no-repeat center center/100%;
}

.page-mainv .page-mainv-txts {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.page-mainv .page-mainv-ltxt {
  position: relative;
  display: inline-block;
  color: #fb4875;
  font-size: 24px;
  font-size: 2.4rem;
}

.page-mainv .page-mainv-ltxt:before, .page-mainv .page-mainv-ltxt:after {
  position: absolute;
  display: block;
  width: 18px;
  height: 30px;
  content: '';
  background-size: 100% 100%;
}

.page-mainv .page-mainv-ltxt:before {
  top: -10px;
  right: 102%;
  background: url("../img/index/star-pattern-left.png") no-repeat center center/100%;
}

.page-mainv .page-mainv-ltxt:after {
  top: 10px;
  left: 102%;
  background: url("../img/index/star-pattern-right.png") no-repeat center center/100%;
}

.about-list-block .items {
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  list-style: none;
  background: url("../img/about/voice/icon-01.png") no-repeat bottom center/100%;
}

.about-list-block .items .img {
  float: left;
  width: 26%;
}

.about-list-block .items .info {
  width: calc(64% - 10px);
  padding-left: 10px;
}

.about-list-block .items .info .ttl {
  margin-bottom: 10px;
  color: #fb4875;
  font-size: 14px;
  font-size: 1.4rem;
}

.about-list-block .items .info .txt-sm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .about-list-block .items .img {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .about-list-block .items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .about-list-block .items .img {
    width: 36%;
  }
}

#main-footer.about_voice-footer {
  border: 0;
}

.paging-navigation {
  text-align: center;
}

.paging-navigation .pagination {
  display: flex;
  margin: 20px 0;
  padding-left: 0;
  justify-content: center;
  align-items: center;
}

.paging-navigation .pagination .page-numbers {
  line-height: 30px;
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
  color: #fb4875;
  border: 1px solid #fb4875;
  border-radius: 50%;
  background: #fff;
  align-items: center;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  font-size: 15px;
  font-size: 1.5rem;
}

.paging-navigation .pagination .page-numbers:first-child {
  margin: 0;
}

.paging-navigation .pagination .page-numbers.current {
  color: #fff;
  border: 1px solid #fb4875;
  background-color: #fb4875;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tab-content-about {
  display: none;
}

.tab-content-about.active {
  display: block;
}

.yellow-box {
  padding: 40px;
  text-align: center;
  background-color: #f9f2b0;
}

@media screen and (max-width: 767px) {
  .yellow-box {
    padding: 20px;
  }
}

.yellow-box .ttl {
  font-weight: bold;
  position: relative;
  z-index: 1;
  display: inline-block;
  color: #fb4875;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .yellow-box .ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.yellow-box .ttl:after {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 10px;
  content: '';
  background-color: #fabf9e;
}

.yellow-box .sub-ttl {
  margin-bottom: 15px;
  color: #2b2b2b;
}

@media screen and (max-width: 767px) {
  .yellow-box .sub-ttl {
    margin-bottom: 10px;
  }
}

.yellow-box .sub-ttl span {
  display: block;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .yellow-box .sub-ttl span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.yellow-box .sub-ttl small {
  display: block;
  width: 100%;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .yellow-box .sub-ttl small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.yellow-box .img-list-02 {
  display: flex;
  margin: 0 -10px;
  justify-content: center;
}

.yellow-box .img-list-02 .items {
  margin: 0 10px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .yellow-box .img-list-02 .items {
    width: 48%;
  }
}

.yellow-box .cta .btn {
  width: 100%;
  padding-right: 30px !important;
  padding-left: 45px !important;
}

.yellow-box .cta .btn:before {
  position: absolute;
  left: 15px;
  display: block;
  width: 25px;
  height: 17px;
  content: '';
  background: url("../img/about/index/mail-icn.png") no-repeat center center;
  background-size: 100% 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*about index*/
.page-about .yellow-box .cta .btn {
  font-size: 14px;
  font-size: 1.4rem;
}

.page-about .yellow-box .ttl:after {
  display: none;
}

.page-about .yellow-box .ttl .txt-bdr {
  background: -moz-linear-gradient(top, rgba(251, 72, 117, 0) 60%, rgba(251, 72, 117, 0.3) 60%, rgba(251, 72, 117, 0.3) 100%);
  background: -webkit-linear-gradient(top, rgba(251, 72, 117, 0) 60%, rgba(251, 72, 117, 0.3) 60%, rgba(251, 72, 117, 0.3) 100%);
  background: linear-gradient(to bottom, rgba(251, 72, 117, 0) 60%, rgba(251, 72, 117, 0.3) 60%, rgba(251, 72, 117, 0.3) 100%);
}

@media screen and (max-width: 430px) {
  .page-about .yellow-box .ttl .txt-bdr {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 450px) {
  .page-about .yellow-box .sub-ttl span {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 450px) {
  .page-about .yellow-box .sub-ttl small {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 768px) {
  .page-about .point-list-child-content .ttl {
    margin-bottom: 20px;
  }
  .page-about .yellow-box .yellow-box-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page-about .yellow-box .yellow-box-inner .clm-left-pc {
    width: 65%;
    padding-right: 10px;
  }
  .page-about .yellow-box .yellow-box-inner .img-list-02 {
    margin: 0;
  }
  .page-about .yellow-box .yellow-box-inner .img-list-02 .items {
    margin: 0 10px;
  }
  .page-about .yellow-box .cta .btn {
    width: 50%;
    max-width: 460px;
    margin: 0 auto;
    font-size: 16px;
    font-size: 1.6rem;
  }
  .page-about .dance-list-03 .items .flex-row .img {
    width: 150px;
  }
  .page-about .dance-list-03 .items .flex-row .desc {
    width: calc(100% - 165px);
  }
}

@media screen and (max-width: 420px) {
  .page-about .section-ttl .caption-lg {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .page-about .dance-list-03 .items .desc .excerpt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.about-choice-section-01 .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .caption-lg.h-80 {
    padding: 17px 10px 16px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.about-choice-section-01 .intro-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.about-choice-section-01 .intro-box .img {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .intro-box .img {
    width: 125px;
  }
}

.about-choice-section-01 .intro-box .desc {
  width: 67%;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .intro-box .desc {
    width: calc(100% - 135px);
  }
}

.about-choice-section-01 .intro-box .desc .ttl {
  margin-bottom: 5px;
  color: #fb4875;
  font-size: 16px;
  font-size: 1.6rem;
}

.about-choice-section-01 .intro-box .desc .ttl span {
  position: relative;
  display: inline-block;
}

.about-choice-section-01 .intro-box .desc .ttl span:after {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 10px;
  content: '';
  transform: rotate(-2deg);
  background-color: #ffd0dc;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .intro-box .desc .ttl {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.about-choice-section-01 .intro-box .desc .excerpt {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .intro-box .desc .excerpt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.about-choice-section-01 .choice-list {
  display: flex;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .choice-list {
    padding: 0;
  }
}

.about-choice-section-01 .choice-list .items {
  width: 100%;
  margin-bottom: 2%;
  padding: 10px 20px 15px;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .choice-list .items {
    margin-bottom: 10px;
  }
}

.about-choice-section-01 .choice-list .items.ltpink {
  background-color: #fee9ee;
}

.about-choice-section-01 .choice-list .items.ltpink .ttl {
  color: #f6698a;
}

.about-choice-section-01 .choice-list .items.ltpink .cta {
  background-color: #f6698a;
}

.about-choice-section-01 .choice-list .items.teal {
  background-color: #f2fbfb;
}

.about-choice-section-01 .choice-list .items.teal .ttl {
  color: #61b8ad;
}

.about-choice-section-01 .choice-list .items.teal .cta {
  background-color: #61b8ad;
}

.about-choice-section-01 .choice-list .items.orange {
  background-color: #ffede1;
}

.about-choice-section-01 .choice-list .items.orange .ttl {
  color: #ff8737;
}

.about-choice-section-01 .choice-list .items.orange .cta {
  background-color: #ff8737;
}

.about-choice-section-01 .choice-list .items.violet {
  background-color: #f1e7f6;
}

.about-choice-section-01 .choice-list .items.violet .ttl {
  color: #9e61c5;
}

.about-choice-section-01 .choice-list .items.violet .cta {
  background-color: #9e61c5;
}

.about-choice-section-01 .choice-list .items.ltbrown {
  background-color: #fff9d1;
}

.about-choice-section-01 .choice-list .items.ltbrown .ttl {
  color: #c8a900;
}

.about-choice-section-01 .choice-list .items.ltbrown .cta {
  background-color: #c8a900;
}

.about-choice-section-01 .choice-list .items.ltorange {
  background-color: #fff1db;
}

.about-choice-section-01 .choice-list .items.ltorange .ttl {
  color: #cf8800;
}

.about-choice-section-01 .choice-list .items.ltorange .cta {
  background-color: #ffb94a;
}

.about-choice-section-01 .choice-list .items.red {
  background-color: #ffedef;
}

.about-choice-section-01 .choice-list .items.red .ttl {
  color: #ff5262;
}

.about-choice-section-01 .choice-list .items.red .cta {
  background-color: #ff5262;
}

.about-choice-section-01 .choice-list .items.ltgreen {
  background-color: #edf8ed;
}

.about-choice-section-01 .choice-list .items.ltgreen .ttl {
  color: #59b35a;
}

.about-choice-section-01 .choice-list .items.ltgreen .cta {
  background-color: #59b35a;
}

.about-choice-section-01 .choice-list .items.pink {
  background-color: #ffeaf3;
}

.about-choice-section-01 .choice-list .items.pink .ttl {
  color: #ff73ae;
}

.about-choice-section-01 .choice-list .items.pink .cta {
  background-color: #ff73ae;
}

.about-choice-section-01 .choice-list .items .ttl {
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .choice-list .items .ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.about-choice-section-01 .choice-list .items .intro-box {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .about-choice-section-01 .choice-list .items .intro-box .img {
    width: 200px;
  }
}

@media screen and (min-width: 768px) {
  .about-choice-section-01 .choice-list .items .intro-box .desc {
    width: calc(100% - 220px);
  }
}

.about-choice-section-01 .choice-list .items .cta {
  position: relative;
  display: block;
  width: 100%;
  max-width: 365px;
  margin: 0 auto;
  padding: 10px 30px 10px 10px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (min-width: 768px) {
  .about-choice-section-01 .choice-list .items .cta {
    margin-right: 0;
  }
}

.about-choice-section-01 .choice-list .items .cta:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.about-choice-section-01 .choice-list .items .cta:before {
  position: absolute;
  right: 15px;
  display: block;
  width: 9px;
  height: 14px;
  content: '';
  background: url("../img/about/choice/white-arrow.png") no-repeat center center;
  background-size: 100% 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .choice-list .items .cta {
    width: 100%;
    margin: 0 auto;
    padding: 5px 15px;
  }
  .about-choice-section-01 .choice-list .items .cta:before {
    right: 10px;
  }
}

.about-choice-section-01 .choice-list .items .cta small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .about-choice-section-01 .banner-sm .txt-top {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 580px) {
  .about-choice-section-01 .banner-sm .txt-top {
    margin-bottom: 5px;
  }
}

.page-about_voice .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .page-about_voice .caption-lg.h-80 {
    padding: 17px 10px 16px;
  }
}

@media screen and (min-width: 768px) {
  .page-about_voice .about-list-block .items .img {
    width: 244px;
  }
}

@media screen and (min-width: 768px) {
  .page-about_voice .about-list-block .items .info {
    float: left;
    width: calc(100% - 264px);
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .page-about_voice .about-list-block .items .info .txt-sm {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) {
  .page-about_voice .about-list-block .items .info .ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .page-about_voice .about-list-block .items .info .ttl {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .page-about_voice .about-list-block .items > .txt-basic {
    float: left;
    width: calc(100% - 264px);
  }
}

.ttl-main {
  position: relative;
}

.ttl-main:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  background: url(../img/price/bg_title.png);
}

.ttl-main:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: url(../img/price/bg_title.png);
}

.ttl-main .ttl-price {
  background: #ffe1e9;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fb4875;
  margin: 0 0 17.5px;
  font-family: -apple-system, blinkMacSystemFont, Meiryo, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  padding: 21px 0;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .ttl-main .ttl-price {
    min-height: 72px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.ttl-main .ttl-price:before {
  display: block;
  content: '';
  background: url(../img/price/star-pattern-left.png) no-repeat center center;
  width: 26px;
  height: 28px;
  background-size: 100% 100%;
  top: -8px;
  left: -4px;
  position: relative;
}

.ttl-main .ttl-price:after {
  display: block;
  content: '';
  background: url(../img/price/star-pattern-right.png) no-repeat center center;
  width: 21px;
  height: 29px;
  background-size: 100% 100%;
  top: 8px;
  right: -4px;
  position: relative;
}

.main-price {
  margin-top: 65px !important;
}

.ct-pr-label {
  margin-bottom: 20px;
}

.ct-pr-label .ct-txt-right {
  color: #2b2b2b;
}

.ct-pr-label .img-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .ct-pr-label {
    margin-bottom: 25px;
  }
}

.ct-pr-label .ttl {
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
  padding-bottom: 3px;
  margin-bottom: 20px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .ttl {
    padding-bottom: 5px;
    margin-bottom: 7px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.ct-pr-label .table-pr {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  color: #2b2b2b;
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .table-pr {
    margin-bottom: 15px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ct-pr-label .table-pr td {
  border: 1px solid #fb4875;
  padding: 1px 9px;
  height: 27px;
}

.ct-pr-label .table-pr th {
  text-align: center;
  background-color: #ffe1e9;
  border: 1px solid #fb4875;
  padding: 1px 9px;
  height: 27px;
}

.ct-pr-label .txt-price {
  font-size: 16px;
  font-size: 1.6rem;
  color: #2b2b2b;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .txt-price {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ct-pr-label .list-txt {
  color: #2b2b2b;
}

.ct-pr-label .list-txt li {
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .list-txt li {
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .ct-pr-label .list-txt li {
    padding-left: 12px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ct-pr-label .list-txt li:before {
  content: "*";
  position: absolute;
  left: 0;
}

.ct-pr-label .btn {
  padding: 10px 110px;
  margin-top: 30px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .btn {
    padding: 10px !important;
    width: 100%;
    margin-top: 15px;
    max-width: 375px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.ct-pr-label .link-pr {
  padding-left: 10px;
  position: relative;
  margin-top: 40px;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .link-pr {
    margin-top: 20px;
    margin-bottom: 33px;
  }
}

.ct-pr-label .link-pr:before {
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-bottom: 2px solid #ff006e;
  border-right: 2px solid #ff006e;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  left: -4px;
}

.ct-pr-label .link-pr a {
  color: #ff006e;
  text-decoration: underline;
  transition: all 0.3s;
  color: #ff006e;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .ct-pr-label .link-pr a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ct-pr-label .link-pr a:hover {
  text-decoration: none;
  opacity: .7;
  transition: all 0.3s;
}

.color-txt {
  color: #fb4875;
}

.list-site-link {
  margin-bottom: 50px;
  margin-top: -4px;
}

@media screen and (max-width: 767px) {
  .list-site-link {
    margin-bottom: 30px;
  }
}

.list-site-link li {
  margin-bottom: 15px;
}

.list-site-link li a {
  color: #fb4875;
  text-decoration: underline;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .list-site-link li a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.color-black {
  color: #000000 !important;
}

@media screen and (min-width: 768px) {
  .twoCol:after {
    content: '';
    display: block;
    clear: both;
  }
  .twoCol li {
    float: left;
    width: 50%;
  }
  .twoCol li:nth-child(2n) {
    float: right;
  }
}

.main-facility {
  margin-top: 65px !important;
}

.main-facility .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .main-facility .caption-lg.h-80 {
    padding: 17px 10px 16px;
  }
}

.main-facility .content-facility {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .main-facility .content-facility {
    margin-bottom: 30px;
  }
}

.main-facility .content-facility .map-preview {
  max-width: 593px;
}

.main-facility .content-facility .caption-lg {
  position: relative;
  display: flex;
  height: 80px;
  margin-bottom: 20px;
  color: white;
  background-color: #fb4875;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-size: 3.6rem;
}

@media screen and (max-width: 767px) {
  .main-facility .content-facility .caption-lg {
    height: 50px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.main-facility .content-facility .caption-lg:before {
  position: absolute;
  bottom: calc(100% + 1px);
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #fb4875;
}

.main-facility .content-facility .txt {
  float: left;
  width: calc(100% - 200px);
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .main-facility .content-facility .txt {
    width: calc(100% - 125px);
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.main-facility .content-facility .cta {
  float: right;
  width: 200px;
  min-height: 40px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .main-facility .content-facility .cta {
    width: 110px;
  }
}

.main-facility .content-facility .cta .btn {
  display: inline-block;
  padding: 10px 20px 10px 6px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .main-facility .content-facility .cta .btn {
    display: block;
    width: 110px;
    text-align: center;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.main-facility .content-facility .cta .btn:after {
  right: 8px;
}

.main-facility .content-facility .txt-02 {
  color: #2b2b2b;
}

@font-face {
  font-family: hui;
  src: url("../font/HuiFontP29.ttf");
}

.content-facility .list-info-faci li {
  float: left;
  width: 50%;
  margin-bottom: 35px;
  padding-right: 60px;
}

.content-facility .list-info-faci li:nth-of-type(even) {
  float: right;
}

@media screen and (max-width: 767px) {
  .content-facility .list-info-faci li {
    width: 100%;
    margin-bottom: 0;
    padding-right: 0;
  }
}

.content-facility .list-info-faci li .ttl-faci {
  font-weight: 600;
  margin-bottom: 11px;
  color: #fb4875;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .content-facility .list-info-faci li .ttl-faci {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.content-facility .list-info-faci li .ttl-faci .number {
  display: table-cell;
  width: 50px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .content-facility .list-info-faci li .ttl-faci .number {
    width: 32px;
  }
}

.content-facility .list-info-faci li .ttl-faci .ttl {
  display: table-cell;
  padding-left: 8px;
  vertical-align: middle;
}

.content-facility .list-info-faci li:last-child .img-faci:after {
  display: none;
}

.content-facility .list-info-faci li .img-faci {
  position: relative;
}

.content-facility .list-info-faci li .img-faci .link-faci {
  font-weight: 600;
  position: absolute;
  top: 52%;
  left: 50%;
  display: flex;
  min-height: 30px;
  padding: 4px 8px;
  transition: all .3s;
  transform: translateX(-50%);
  color: #fff;
  background: #ff0030;
  align-items: center;
  font-size: 22px;
  font-size: 2.2rem;
}

.content-facility .list-info-faci li .img-faci .link-faci:hover {
  transition: all .3s;
  opacity: .7;
}

@media screen and (max-width: 767px) {
  .content-facility .list-info-faci li .img-faci .link-faci {
    min-width: 125px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 768px) {
  .content-facility .list-info-faci li .img-faci:after {
    position: absolute;
    right: -55px;
    width: 50px;
    height: 42px;
    content: '';
    background: url("../img/facility/img-mt-pc.png") center no-repeat;
    background-size: cover;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.content-facility .list-info-faci li .img-mt {
  margin: 10px 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .content-facility .list-info-faci li .img-mt img {
    height: 30px;
  }
}

@media screen and (min-width: 768px) {
  .content-facility .list-info-faci li .img-mt {
    display: none !important;
  }
}

.content-facility .list-txt {
  color: #2b2b2b;
}

.content-facility .list-txt li {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .content-facility .list-txt li {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.village {
  position: relative;
  margin-bottom: 50px;
  padding: 30px 0 35px;
  color: #fb4875;
  background: #ffd0dc;
  font-size: 34px;
  font-size: 3.4rem;
}

@media screen and (max-width: 767px) {
  .village {
    margin-bottom: 20px;
    padding: 15px 0 18px;
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.village .v-diew .txt {
  font-family: hui;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 12px;
}

.village .v-diew .txt:after {
  position: absolute;
  top: -21px;
  right: 0;
  content: url(../img/facility/diew.png);
  transform: scale(0.8);
}

@media screen and (max-width: 767px) {
  .village .v-diew .txt:after {
    right: -27px;
    transform: scale(0.5);
  }
}

.village .v-diew .txt .bg-bt {
  line-height: 1;
  position: relative;
  z-index: 2;
  padding-bottom: 8px;
  background: url(../img/facility/img_before.png) repeat-x;
  background-position: 56% bottom;
  background-size: 100% 20px;
}

.village .v-diew .v-link {
  position: relative;
  display: block;
  padding-left: 13px;
  transition: all .3s;
  text-decoration: underline;
  color: #fb4875;
  font-size: 16px;
  font-size: 1.6rem;
}

.village .v-diew .v-link:hover {
  transition: all .3s;
  text-decoration: none;
  opacity: .7;
}

.village .v-diew .v-link:before {
  position: absolute;
  top: 39%;
  left: 0;
  display: block;
  width: 6px;
  height: 6px;
  content: '';
  transform: rotate(-45deg);
  border-right: 2px solid #ff006e;
  border-bottom: 2px solid #ff006e;
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .village .v-diew .v-link:before {
    top: 27%;
  }
}

@media screen and (max-width: 767px) {
  .village .v-diew .v-link {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.village .v-diew .img {
  float: right;
  width: 100%;
}

.page-thanks article {
  padding: 20px 0 50px;
  border-top: 1px solid #fb4875;
}

@media (min-width: 768px) {
  .fix-call {
    pointer-events: none;
  }
}

.page-friend .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .page-friend .caption-lg.h-80 {
    padding: 17px 10px 16px;
  }
}

@media screen and (min-width: 768px) {
  .page-friend .steps-block .items .ttl {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .page-friend .steps-block .items .ttl p {
    display: inline-block;
    margin-left: 10px;
    line-height: 1;
  }
  .page-friend .steps-block .items .desc .img {
    max-width: 200px;
    margin: 0 auto;
  }
  .page-friend .steps-block .arrows {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .page-friend .steps-block {
    padding-top: 10px;
  }
  .page-friend .steps-block .arrows img {
    height: 25px;
  }
}

.ttl-item {
  color: #fb4875;
  font-size: 25px;
  font-size: 2.5rem;
  margin: 20px 0;
}

.ttl-item .small {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .ttl-item {
    margin-top: 10px;
    font-size: 20px;
    font-size: 2rem;
  }
  .ttl-item .small {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.ttl-box {
  margin-bottom: 30px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .ttl-box {
    margin-bottom: 13px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.ttl-box span {
  display: inline-block;
  position: relative;
  color: #fb4875;
}

.ttl-box span:after {
  display: block;
  content: '';
  height: 15px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: 0;
  left: -3px;
  right: -3px;
  z-index: -1;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

@media screen and (max-width: 767px) {
  .ttl-box span:after {
    height: 10px;
  }
}

.benefit-list .item {
  float: left;
  width: 48%;
}

.benefit-list .item:nth-child(2n) {
  float: right;
}

.benefit-list .item img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .benefit-list .item {
    margin-bottom: 20px;
    float: none;
    width: 100%;
    text-align: center;
  }
  .benefit-list .item img {
    max-width: 100%;
  }
  .benefit-list .item:nth-child(2n) {
    float: none;
  }
}

.box-smail .benefit-list {
  display: inline-block;
  max-width: 600px;
}

.shade-item {
  display: flex;
  justify-content: center;
}

.shade-item .item {
  padding: 0 7px;
}

.shade-item .item img {
  width: 45px;
}

@media screen and (max-width: 767px) {
  .steps-block.steps-block-02 .items .desc .img {
    width: 100px;
  }
  .steps-block.steps-block-02 .items .desc .excerpt {
    width: calc(100% - 100px);
    padding-left: 15px;
  }
}

.flow-section-01 .btn-container {
  display: flex;
  justify-content: center;
}

.flow-section-01 .btn-container .btn {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .flow-section-01 .btn-container .btn {
    margin: 5px 0;
  }
}

@media screen and (max-width: 767px) {
  .flow-section-01 .btn-container {
    flex-wrap: wrap;
  }
  .flow-section-01 .btn-container .btn {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .flow-section-01 .steps-block.style02 .arrows {
    align-items: flex-start;
    padding-top: 160px;
  }
}

.page-flow .mov-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.page-flow .mov-wrap .mov-wrap-inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.page-flow .mov-wrap .mov-wrap-inner iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 600px;
  height: 100%;
}

.page-faq .caption-lg.h-80 {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .page-faq .caption-lg.h-80 {
    padding: 17px 10px 16px;
  }
}

.page-faq .accordion-faq {
  border-top: 1px solid #ff006e;
}

@media screen and (min-width: 768px) {
  .page-faq .accordion-faq {
    border-right: 1px solid #ff006e;
    border-left: 1px solid #ff006e;
  }
}

@media screen and (max-width: 767px) {
  .page-faq .accordion-faq .accordion-items .accordion-label {
    text-align: left;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.page-faq .accordion-faq .accordion-content .btn.btn-faq {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .page-faq .accordion-faq .accordion-content .btn.btn-faq {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-faq .index-section-02:after {
  display: none;
}

.page-privacy .text,
.page-rule .text,
.page-site .text {
  font-size: 12px;
}

@media (min-width: 768px) {
  .page-privacy .text,
  .page-rule .text,
  .page-site .text {
    font-size: 16px;
  }
}

.page-privacy .txt-12,
.page-rule .txt-12,
.page-site .txt-12 {
  font-size: 12px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .page-privacy .txt-12,
  .page-rule .txt-12,
  .page-site .txt-12 {
    font-size: 16px;
  }
}

.page-privacy .box-text,
.page-rule .box-text,
.page-site .box-text {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .page-privacy .box-text,
  .page-rule .box-text,
  .page-site .box-text {
    margin-bottom: 48px;
  }
}

.page-privacy .box-text .box-title,
.page-rule .box-text .box-title,
.page-site .box-text .box-title {
  font-size: 14px;
  margin-bottom: 10px;
  padding-bottom: 6px;
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
}

@media (min-width: 768px) {
  .page-privacy .box-text .box-title,
  .page-rule .box-text .box-title,
  .page-site .box-text .box-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.page-privacy .list-txt,
.page-rule .list-txt,
.page-site .list-txt {
  font-size: 12px;
}

@media (min-width: 768px) {
  .page-privacy .list-txt,
  .page-rule .list-txt,
  .page-site .list-txt {
    font-size: 16px;
  }
}

.page-privacy .list-txt li,
.page-rule .list-txt li,
.page-site .list-txt li {
  position: relative;
  padding-left: 12px;
}

.page-privacy .list-txt li:before,
.page-rule .list-txt li:before,
.page-site .list-txt li:before {
  content: '.';
  position: absolute;
  top: -3px;
  left: 2px;
}

.page-privacy .table-info,
.page-rule .table-info,
.page-site .table-info {
  width: 100%;
}

.page-privacy .table-info td,
.page-rule .table-info td,
.page-site .table-info td {
  border: 1px solid #fb4875;
  padding: 7px 11px;
  width: 50%;
  font-size: 12px;
}

@media (min-width: 768px) {
  .page-privacy .table-info td,
  .page-rule .table-info td,
  .page-site .table-info td {
    padding: 14px 22px;
    font-size: 24px;
  }
}

.page-privacy .table-info tr td:first-child,
.page-rule .table-info tr td:first-child,
.page-site .table-info tr td:first-child {
  width: 45.5%;
}

.page-privacy .table-info tr td:last-child,
.page-rule .table-info tr td:last-child,
.page-site .table-info tr td:last-child {
  width: 54.5%;
}

.page-privacy .table-info .tr-pink td,
.page-rule .table-info .tr-pink td,
.page-site .table-info .tr-pink td {
  background-color: #ffe1e9;
}

@media (min-width: 768px) {
  .page-site .box-text .box-title {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .f-sz-20 {
    font-size: 20px !important;
  }
}

.fz-16 {
  border-right: 1px solid #fb4875 !important;
}

@media (min-width: 768px) {
  .fz-16 tr, .fz-16 td {
    font-size: 16px !important;
    width: 50%;
    float: left;
    border-bottom: 0 !important;
    border-right: 0px !important;
  }
}

.fz-16 tr:nth-child(7) {
  border-bottom: 1px solid #fb4875 !important;
}

.fz-16 tr:nth-child(8) {
  border-bottom: 1px solid #fb4875 !important;
}

@media (min-width: 768px) {
  .table-fz-16 tr, .table-fz-16 td {
    font-size: 16px !important;
  }
}

.news-inner {
  position: relative;
  padding: 18px 0px 15px;
}

@media screen and (min-width: 768px) {
  .news-inner {
    padding: 36px 0px 30px;
  }
}

.news-inner .news-inner-item {
  display: flex;
  margin-bottom: 10px;
  padding: 7px 0px;
}

@media screen and (min-width: 768px) {
  .news-inner .news-inner-item {
    padding: 14px 0px 0px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.news-inner .news-inner-item .date {
  padding-right: 12px;
  position: relative;
  /*top: -2px;*/
}

@media screen and (min-width: 768px) {
  .news-inner .news-inner-item .date {
    padding-right: 12px;
    /*top: -4px;*/
  }
}

.news-inner .news-inner-item .date-content a {
  text-decoration: underline;
  text-underline-position: under;
}

.news-inner .news-inner-item .date-content .txt-pink {
  color: #ff006e;
}

.bg_pink00 {
  padding: 4px 20px 0px;
}

@media screen and (min-width: 768px) {
  .bg_pink00 {
    padding: 8px 0px 0px;
  }
}

@media (min-width: 768px) {
  .page-news-index .news-inner {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  .page-news-index .news-inner-item {
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
    float: left;
  }
  .page-news-index .news-inner .news-inner-item {
    font-size: 16px;
  }
}

.siteMap-inner {
  padding: 0px 0px 30px;
}

@media screen and (min-width: 768px) {
  .siteMap-inner {
    padding: 0px 0px 30px;
  }
}

.siteMap-inner .siteMap-inner-item {
  margin: 21px 0px;
}

@media screen and (min-width: 768px) {
  .siteMap-inner .siteMap-inner-item {
    margin: 35px 0 10px;
  }
}

.siteMap-inner .siteMap-inner-item a {
  text-decoration: underline;
  background: #ffffff;
  border: 1px solid #ff006e;
  border-bottom: none;
  color: #ff006e;
  position: relative;
  padding: 11px 20px 11px 30px;
  text-underline-position: under;
  font-size: 12px;
  font-size: 1.2rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .siteMap-inner .siteMap-inner-item a {
    padding: 23px 40px 23px 60px;
    font-size: 2.4px;
    font-size: 2.4rem;
  }
}

.siteMap-inner .siteMap-inner-item a:first-child {
  background: #ffe1e9;
}

.siteMap-inner .siteMap-inner-item a:last-child {
  border-bottom: 1px solid #ff006e;
}

.siteMap-inner .siteMap-inner-item a:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  width: 5px;
  height: 8px;
  background: url("../img/sitemap/li.png");
  background-repeat: no-repeat;
  background-size: 5px 8px;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .siteMap-inner .siteMap-inner-item a:after {
    left: 40px;
    width: 10px;
    height: 16px;
    top: 56%;
    background-size: 8px 12px;
  }
}

.siteMap-inner .siteMap-inner-item .bg-light {
  background-color: #fff !important;
}

@media (min-width: 768px) {
  .page-site-map .siteMap-inner {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  .page-site-map .siteMap-wrap-item {
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
    float: left;
  }
  .page-site-map .siteMap-inner .siteMap-inner-item a {
    font-size: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.list-item-basic {
  padding-top: 40px;
  margin: 0 -10px;
}

@media only screen and (max-width: 768px) {
  .list-item-basic {
    margin: 0;
    padding-top: 20px;
  }
}

.list-item-basic .item {
  float: left;
  width: 50%;
  padding: 0 10px 0;
  margin-bottom: 40px;
}

.list-item-basic .item:last-child {
  background: none;
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .list-item-basic .item {
    padding: 0 0 18px;
    width: 100%;
    margin-bottom: 20px;
    float: none;
    background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
  }
}

.list-item-basic .item .item-img {
  position: relative;
}

.list-item-basic .item .item-img .ct-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background: #ff0030;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  min-width: 165px;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .list-item-basic .item .item-img .ct-center {
    min-width: 125px;
    padding: 5px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.list-item-basic .item .link-basic {
  color: #f6698a;
  text-decoration: underline;
  padding: 9px 0 6px;
  display: block;
  font-size: 20px;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  .list-item-basic .item .link-basic {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.list-item-basic .item .txt-basic {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .list-item-basic .item .txt-basic {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .txt-fz-12 {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.btn-basic {
  margin: 20px;
}

@media only screen and (max-width: 768px) {
  .btn-basic {
    margin: 0px;
  }
}

@media only screen and (max-width: 530px) {
  .btn-basic {
    width: 100%;
    padding: 10px;
  }
}

.txtBlog01 {
  color: #ff006e;
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: underline;
  text-underline-position: under;
}

.page-blog-index .dance-list-02 .items a .desc .txt-top {
  font-size: 16px;
  font-size: 1.6rem;
}

.page-blog-index .dance-list-02 .items {
  width: 48%;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .page-blog-index .dance-list-02 .items {
    width: 48%;
  }
}

.page-blog-index .dance-list-02 .items a .img {
  width: 350px;
}

.page-blog-index .dance-list-02 .items a .img img {
  width: 100%;
}

.page-blog-index .dance-list-02 .items a .desc {
  padding: 15px 15px;
}

@media screen and (max-width: 767px) {
  .page-blog-index .dance-list-02 .items a .desc {
    padding: 0;
  }
}

.tahitian-basic-inner {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner {
    margin-bottom: 60px;
  }
}

.tahitian-basic-inner .basic-item {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner .basic-item {
    display: block;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.tahitian-basic-inner .basic-item .item-img {
  position: relative;
}

.tahitian-basic-inner .basic-item .item-img img {
  width: 100%;
}

.tahitian-basic-inner .basic-item .item-img .txtAbsolute {
  font-size: 13px;
  font-size: 1.3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -57%);
  background: #ff0030;
  padding: 4px 4px;
  color: #ffffff;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner .basic-item .item-img .txtAbsolute {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 8px;
  }
}

.tahitian-basic-inner .basic-item .item-content h4 {
  margin-bottom: 5px;
  margin-top: 6px;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner .basic-item .item-content h4 {
    margin-bottom: 5px;
    margin-top: 6px;
  }
}

.tahitian-basic-inner .basic-item .item-content h4 a {
  color: #f6698a;
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: underline;
  text-underline-position: under;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner .basic-item .item-content h4 a {
    font-size: 20px;
  }
}

.tahitian-basic-inner .basic-item .hr {
  background-image: url(../img/lineup/tahitian/basic/hr.png);
  background-repeat: no-repeat;
  height: 2px;
  margin: 14px 0px 19px;
}

@media screen and (min-width: 768px) {
  .tahitian-basic-inner .basic-item .hr {
    margin: 28px 0px 38px;
    width: 100%;
    background-size: cover;
  }
}

.basic-btn {
  margin-bottom: 20px;
}

.basic-btn .btn {
  padding: 10px 20px;
  display: block;
}

@media screen and (min-width: 768px) {
  .basic-btn .btn {
    color: #ff006e;
    text-align: center;
    padding: 15px 50px;
    position: relative;
    border-bottom: 1px solid #ff006e;
    font-size: 20px;
    font-size: 2rem;
  }
}

h2.ttlBasic {
  padding: 9px 10px;
}

@media (min-width: 768px) {
  h2.ttlBasic {
    padding: 15px 10px;
  }
}

h3.ttlBasic, h2.ttlBasic {
  text-align: center;
  background: #fb4875;
  font-size: 24px;
  font-size: 2.4rem;
  color: #ffffff;
  margin: 0px -20px 19px;
  position: relative;
}

@media screen and (min-width: 768px) {
  h3.ttlBasic, h2.ttlBasic {
    font-size: 36px;
    font-size: 3.6rem;
    margin: 0px -40px 38px;
  }
}

h3.ttlBasic:before, h2.ttlBasic:before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #fb4875;
  position: absolute;
  left: 0;
  bottom: calc(100% + 3px);
}

h3.ttlBasic {
  line-height: 26px;
  padding: 13px 0px;
}

@media (min-width: 768px) {
  h3.ttlBasic {
    padding: 26px 0px;
  }
}

@media screen and (max-width: 767px) {
  .page-lineup-tahitian-summary .dance-list-03 .items .ttl {
    margin-bottom: 10px;
  }
  .page-lineup-tahitian-summary .dance-list-03 .items .item-inner .img,
  .page-lineup-tahitian-summary .dance-list-03 .items .item-inner .desc {
    width: 100%;
  }
  .page-lineup-tahitian-summary .dance-list-03 .items {
    margin-bottom: 20px;
  }
  .page-lineup-tahitian-summary .lineup-belly-summary-section-01 {
    margin-bottom: 30px;
  }
}

@media (max-width: 800px) {
  .page-lineup-tahitian-summary .btn-basic {
    width: 100%;
    margin-bottom: 25px;
  }
}

.txtTop {
  text-align: center;
  padding: 23px 0px 22px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .txtTop {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 46px 0px 44px;
  }
}

.page-lineup-tahitian-basic .txtTop {
  padding: 20px 0;
}

.page-lineup-tahitian-basic .tahitian-basic-inner .basic-item .hr {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .page-lineup-tahitian-basic .tahitian-basic-inner {
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;
  }
  .page-lineup-tahitian-basic .basic-item {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 0 20px;
  }
}

.dance-costume {
  background: #83cf84;
  padding: 30px 0 40px;
  margin-top: 45px;
}

@media only screen and (max-width: 768px) {
  .dance-costume {
    padding: 15px 0 20px;
  }
}

.dance-costume .ttl {
  text-align: center;
}

.dance-costume .ttl .ttl-costume {
  text-align: center;
  color: #fff;
  letter-spacing: -3px;
  padding: 0 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto 26px;
  font-size: 30px;
  font-size: 3rem;
}

.dance-costume .ttl .ttl-costume:before {
  display: block;
  content: '';
  background: url(../img/lineup/tahitian/summary/icon-left.png) no-repeat center center;
  width: 24px;
  height: 35px;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dance-costume .ttl .ttl-costume:after {
  display: block;
  content: '';
  background: url(../img/lineup/tahitian/summary/icon-right.png) no-repeat center center;
  width: 24px;
  height: 35px;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dance-costume .ttl .ttl-costume span {
  position: relative;
  z-index: 2;
}

.dance-costume .ttl .ttl-costume span:after {
  display: block;
  content: '';
  width: 100%;
  height: 10px;
  background: rgba(181, 229, 182, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 1;
  -webkit-transform: skewY(-1deg);
  -ms-transform: skewY(-1deg);
  transform: skewY(-1deg);
}

@media only screen and (max-width: 768px) {
  .dance-costume .ttl .ttl-costume {
    letter-spacing: -3px;
    margin: 0 auto 13px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.dance-costume .txt-costume {
  padding-top: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

.dance-costume .txt-costume span {
  color: #fff;
  padding-top: 7px;
  display: block;
}

@media only screen and (max-width: 768px) {
  .dance-costume .txt-costume {
    padding-top: 10px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.w100 {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .style-q01 {
    padding: 16px 0 !important;
  }
}

.dvd-box {
  font-size: 14px;
  font-size: 1.4rem;
}

.dvd-box img {
  max-width: 40%;
}

@media screen and (max-width: 767px) {
  .dvd-box img {
    max-width: 100%;
  }
}

.dvd-box p {
  margin-bottom: 20px;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .btn.btn-small {
    font-size: 16px !important;
  }
}

.dvd-list-vd {
  margin: 0 -10px;
}

.dvd-list-vd li {
  padding: 0 10px;
  font-size: 16px;
  font-size: 1.6rem;
  float: left;
  width: 33.333%;
}

@media screen and (max-width: 767px) {
  .dvd-list-vd li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.dvd-list-vd li img {
  width: 100%;
  margin-bottom: 10px;
}

.dvd-list-vd li .movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 10px;
}

.dvd-list-vd li .movie iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.color-blue-gray {
  color: #46a8bc;
}

.color-orange {
  color: #cf9300;
}

.color-pink {
  color: #fb4875;
}

.ttl-make-choice {
  width: 100%;
  background: url("../img/dvd/line.png") no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .ttl-make-choice {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .ttl-make-choice small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ttl-make-choice small span {
  color: #fb4875;
  display: inline-block !important;
}

.make-choice-list .item {
  float: left;
  width: 49%;
  font-size: 14px;
  font-size: 1.4rem;
}

.make-choice-list .item:nth-child(2n) {
  float: right;
}

@media screen and (max-width: 767px) {
  .make-choice-list .item {
    width: 100%;
    margin-bottom: 30px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.make-choice-list .item img {
  float: left;
  width: 30%;
}

.make-choice-list .item .item-inner {
  width: 100%;
  display: table;
}

.make-choice-list .item .am-frame {
  display: table-cell;
  vertical-align: top;
}

.make-choice-list .item .txt {
  display: table-cell;
  padding-left: 15px;
  vertical-align: top;
}

.make-choice-list .item .txt img {
  width: auto;
  max-width: 55%;
}

.make-choice-list .item span {
  display: block;
}

.make-choice-list .item .color-blue-gray, .make-choice-list .item .color-orange {
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .make-choice-list .item .color-blue-gray, .make-choice-list .item .color-orange {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.make-choice-list .item .color-pink {
  margin: 10px 0;
}

.index-section-02.af-none:after {
  display: none;
}

@media screen and (max-width: 767px) {
  .index-section-02 {
    padding-bottom: 27px;
  }
}

.main-visual-box {
  background: white;
  margin-bottom: 40px;
}

.main-visual-box .container {
  padding: 0 20px !important;
}

@media screen and (max-width: 767px) {
  .main-visual-box .main-visual-slideshow-02 {
    margin-bottom: 10px !important;
  }
}

.main-visual-box .color-pink {
  font-size: 15px;
}

@media (min-width: 768px) {
  .main-visual-box .color-pink {
    font-size: 30px;
  }
}

.page-dvd_1609 .index-section-02 .btn-container {
  width: 100%;
}

.page-dvd_1609 .index-section-02 .btn-container .btn-arrow {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.page-dvd_1609 .txt-info {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .page-dvd_1609 .section-ttl .caption-lg {
    padding: 10px 5px;
  }
  .page-dvd_1609 .point-list-block > .items .img {
    margin-bottom: 20px;
  }
  .page-dvd_1609 .txt-bottom {
    font-size: 11px;
  }
  .page-dvd_1609 .txt-info {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .page-dvd_1609 .point-list-block {
    display: block;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
  }
  .page-dvd_1609 .point-list-block > .items {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding: 0 15px;
  }
  .page-dvd_1609 .point-list-block > .items .img {
    width: 100%;
    margin: 0 auto;
  }
  .page-dvd_1609 .point-list-block > .items .img img {
    width: 100%;
  }
  .page-dvd_1609 .section-ttl .caption-sm {
    margin-bottom: 10px;
    display: inline-block;
  }
}

@media screen and (min-width: 768px) {
  .point-list-block-02 {
    display: block;
    overflow: hidden;
  }
  .point-list-block-02 .items {
    float: left;
    width: 30%;
    margin-right: 5%;
    margin-bottom: 5%;
  }
  .point-list-block-02 .items:nth-child(2n) {
    margin-right: 0;
  }
}

.section-ttl .caption-new {
  background: #ff5262;
  color: white;
  padding: 30px 0;
}

.section-ttl .caption-new .ttl {
  display: inline-block;
  position: relative;
  padding: 0 25px 0 35px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-new .ttl {
    padding: 0 23px 0 33px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.section-ttl .caption-new .ttl.ttl:before, .section-ttl .caption-new .ttl.ttl:after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-ttl .caption-new .ttl.ttl:before {
  left: 0;
  width: 30px;
  height: 30px;
  background: url("../img/hawaii/bf.png") no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-new .ttl.ttl:before {
    width: 25px;
    height: 25px;
    left: 5px;
  }
}

.section-ttl .caption-new .ttl.ttl:after {
  right: 0;
  width: 20px;
  height: 30px;
  background: url("../img/hawaii/af.png") no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-new .ttl.ttl:after {
    width: 15px;
    height: 20px;
    right: 5px;
  }
}

.section-ttl .caption-new .ttl-bottom {
  display: inline-block;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.2;
  background: url(../img/hawaii/under_line.png) no-repeat;
  background-position: bottom center;
  background-size: 100%;
}

@media screen and (max-width: 767px) {
  .section-ttl .caption-new .ttl-bottom {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 350px) {
  .section-ttl .caption-new .ttl-bottom {
    background: none;
  }
}

.banner-sm .txt-top.bf-top:before, .banner-sm .txt-top.bf-top:after {
  top: 50%;
}

.w-100 img {
  max-width: 100% !important;
}

.fs-22 {
  font-size: 12px;
}

@media (min-width: 768px) {
  .fs-22 {
    font-size: 22px;
  }
}

@media screen and (min-width: 768px) {
  .wd-50 .items {
    width: 45% !important;
  }
}

#sample-movie {
  padding-top: 90px;
  margin-top: -90px;
}

@media screen and (max-width: 767px) {
  #sample-movie {
    padding-top: 65px;
    margin-top: -65px;
  }
}

.exper-post {
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .exper-post {
    padding: 20px 0;
  }
}

.exper-post .img {
  float: left;
  width: 250px;
}

@media screen and (max-width: 767px) {
  .exper-post .img {
    width: 30%;
  }
}

.exper-post .ttl {
  width: calc(100% - 250px);
  padding-left: 30px;
  float: right;
}

@media screen and (max-width: 767px) {
  .exper-post .ttl {
    width: 68%;
    padding-left: 8px;
  }
}

.exper-post .ttl .ttl-exper {
  padding-bottom: 20px;
  color: #fb4875;
  font-weight: 600;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 767px) {
  .exper-post .ttl .ttl-exper {
    padding-bottom: 8px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.exper-post .ttl .ttl-exper span {
  position: relative;
}

.exper-post .ttl .ttl-exper span:after {
  display: block;
  content: '';
  width: 100%;
  height: 10px;
  background-color: #ffd0dc;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: -1;
  -webkit-transform: skewY(-1deg);
  -ms-transform: skewY(-1deg);
  transform: skewY(-1deg);
}

.exper-post .ttl .txt-exper {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .exper-post .ttl .txt-exper {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .accordion-faq {
    border-top: 1px solid #ff006e;
  }
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items {
    border: none;
  }
}

.accordion-faq .accordion-items .accordion-label {
  margin-top: -1px;
}

@media screen and (min-width: 768px) {
  .accordion-faq .accordion-items .accordion-label {
    border: 1px solid #ff006e;
  }
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items .accordion-label {
    padding: 0 20px;
    height: 38px;
    justify-content: start;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .accordion-faq .accordion-items .accordion-content {
    border-right: 1px solid #ff006e;
    border-left: 1px solid #ff006e;
  }
}

.accordion-faq .accordion-items .accordion-content ul li {
  width: 48.7%;
  float: left;
}

.accordion-faq .accordion-items .accordion-content ul li:nth-child(2n) {
  float: right;
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc {
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items .accordion-content ul li .ttl-acc {
    padding-bottom: 7px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc.color-ltbrown {
  color: #e69d11;
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc.color-kpop {
  color: #c8a900;
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc.color-pink {
  color: #ff5262;
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc.color-teal {
  color: #4cbcae;
}

.accordion-faq .accordion-items .accordion-content ul li .ttl-acc.color-orange {
  color: #ff8737;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-ltbrown .top {
  background-color: #e69d11;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-ltbrown .bottom {
  border: 2px solid #e69d11;
  color: #e69d11;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-kpop .top {
  background-color: #c8a900;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-kpop .bottom {
  border: 2px solid #c8a900;
  color: #c8a900;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-pink .top {
  background-color: #ff5262;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-pink .bottom {
  border: 2px solid #ff5262;
  color: #ff5262;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-teal .top {
  background-color: #4cbcae;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-teal .bottom {
  border: 2px solid #4cbcae;
  color: #4cbcae;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-orange .top {
  background-color: #ff8737;
}

.accordion-faq .accordion-items .accordion-content ul li .items.color-orange .bottom {
  border: 2px solid #ff8737;
  color: #ff8737;
}

.accordion-faq .accordion-items .accordion-content ul li .items .top {
  height: 42px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  padding: 2px 2px 2px 3px;
  line-height: 42px;
  padding: 0 20px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items .accordion-content ul li .items .top {
    height: 25px;
    line-height: 25px;
    padding: 0 11px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.accordion-faq .accordion-items .accordion-content ul li .items .bottom {
  background-color: #fff;
  padding: 2px 2px 2px 3px;
  position: relative;
  height: 46px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 46px;
  padding: 0 20px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items .accordion-content ul li .items .bottom {
    height: 25px;
    line-height: 25px;
    padding: 0 11px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.accordion-faq .accordion-items .accordion-content ul li .items .bottom:after {
  content: "";
  display: block;
  background: url(../img/index/shield-sm-green-yellow.png);
  background-size: 100% 100%;
  height: 20px;
  width: 16px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .accordion-faq .accordion-items .accordion-content ul li .items .bottom:after {
    height: 14px;
    width: 11px;
  }
}

.border-bt {
  padding-bottom: 5px !important;
}

@media screen and (max-width: 767px) {
  .border-bt {
    border-bottom: 0px !important;
  }
}

.phone-expor {
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .phone-expor {
    margin-top: 10px;
  }
}

.page-experience-index .accordion .accordion-items .accordion-label {
  text-align: left;
}

.page-experience-index .accordion-faq .accordion-items .accordion-content ul li .ttl-acc,
.page-experience-index .accordion-faq .accordion-items .accordion-content ul li .items .top,
.page-experience-index .accordion-faq .accordion-items .accordion-content ul li .items .bottom {
  font-size: 16px;
}

.page-experience-index ul li {
  list-style-type: none;
}

.page-experience-index .accordion-faq .accordion-items .accordion-content ul li .items.color-ltbrown .bottom,
.page-experience-index .accordion-faq .accordion-items .accordion-content ul li .items.color-kpop .bottom {
  border-width: 1px;
}

@media screen and (max-width: 320px) {
  .page-experience-index .phone-expor a .number-phone {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .txt-fz {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .media-item .ttl {
    margin-bottom: 12px;
  }
}

.media-item .items .img {
  width: 30.23%;
  float: left;
}

.media-item .items .img img {
  width: 100%;
}

.media-item .items .txt-md {
  width: 69.77%;
  float: right;
  padding-left: 30px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .media-item .items .txt-md {
    padding-left: 15px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 768px) {
  .media-item .ct-pr-label {
    margin-bottom: 40px;
  }
}

.btn-media {
  margin-bottom: 90px;
  margin-top: 80px;
}

@media only screen and (max-width: 768px) {
  .btn-media {
    margin-bottom: 92px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 510px) {
  .btn-media {
    padding: 10px;
    width: 100%;
  }
}

.page-media .txt-dark-pink {
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .style-ttl-pr {
    padding: 7px 10px 4px !important;
    line-height: 25px !important;
  }
}

.form-style-pr {
  font-size: 12px !important;
}

.form-style-pr .form-control {
  font-size: 12px !important;
}

.form-style-pr textarea.form-control {
  height: 78px !important;
}

.form-style-pr .form dl dt {
  margin-bottom: 5px !important;
}

.show-form-pc .txt-basic {
  font-size: 14px;
}

@media screen and (min-width: 767px) {
  .show-form-pc .btn-pr {
    padding: 15px 70px !important;
  }
  .show-form-pc .form .fl-form {
    float: left;
    width: 49%;
  }
  .show-form-pc .form .fr-form {
    float: right;
    width: 49%;
  }
  .show-form-pc .form .block {
    width: 100%;
  }
  .show-form-pc .form dl {
    display: block;
  }
  .show-form-pc .form dl dt {
    width: 100%;
    display: block;
  }
  .show-form-pc .form dl dt label {
    font-size: 2rem;
    margin-bottom: 15px;
    display: block;
  }
  .show-form-pc .form dl dt label span {
    font-size: 1.8rem;
    border: 2px solid #fb4875;
    padding: 4px 5px 2px;
  }
  .show-form-pc .form dl dd {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .show-form-pc .form dl dd:first-child {
    margin-bottom: 40px;
  }
  .show-form-pc .form dl dd .form-control {
    font-size: 1.6rem !important;
    height: 62px;
    padding: 10px 20px;
  }
  .show-form-pc .form dl dd .list-time li span {
    height: 64px;
  }
  .show-form-pc .form dl dd .list-time .custom-select {
    position: relative;
  }
  .show-form-pc .form dl dd .list-time .custom-select:after {
    display: block;
    content: '';
    background-color: #cccccc;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 1px;
    right: 9.4%;
    pointer-events: none;
  }
  .show-form-pc .form dl dd .list-time .custom-select:before {
    display: block;
    content: '';
    border: 10px solid transparent;
    border-top: 13px solid #535353;
    border-bottom: none;
    position: absolute;
    top: 34px;
    transform: translateY(-50%);
    right: 15%;
    z-index: 1;
  }
}

@media (min-width: 768px) {
  .dvd-box-ct {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  .dvd-box-ct .w-45 {
    width: 45%;
  }
  .dvd-box-ct .w-55 {
    width: 55%;
  }
  .dvd-box-ct .image {
    float: left;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
}

@media (min-width: 768px) {
  .content-recruit-pc .box-recruit .txt {
    padding-top: 20px !important;
    width: 100%;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .table-pd-recruit tr, .table-pd-recruit th, .table-pd-recruit td {
    padding: 6px 17px !important;
  }
}

@media (max-width: 768px) {
  .table-pd-recruit .h-85 {
    min-height: 65px;
  }
}

@media (max-width: 768px) {
  .list-wd-item .item img {
    width: 31% !important;
  }
  .list-wd-item .item p {
    width: 69% !important;
  }
}

.english-inner {
  padding: 25px 0px 20px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .english-inner {
    padding: 50px 0px 40px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.english-inner .txtAbout {
  margin: 8px 0px 28px;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .english-inner .txtAbout {
    margin: 16px 0px 56px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) {
  .english-inner .about-fees {
    width: 100%;
  }
}

.english-inner h2.ttlBasic {
  padding: 11px 0px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .english-inner h2.ttlBasic {
    padding: 11px 0px;
    margin: 0px -1000px 40px;
  }
}

.english-inner .txtTitleEnglish {
  margin: 0px 0px 32px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish {
    margin: 0px 0px 64px;
  }
}

.english-inner .txtTitleEnglish p.color-pink {
  background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
  margin-bottom: 7px;
  padding-bottom: 8px;
  padding-left: 5px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish p.color-pink {
    margin-bottom: 14px;
    padding-bottom: 16px;
    padding-left: 10px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.english-inner .txtTitleEnglish span.color-pink {
  display: block;
}

@media screen and (max-width: 768px) {
  .english-inner .txtTitleEnglish span.color-pink {
    margin-top: -12px;
    display: inline-block;
  }
}

.english-inner .txtTitleEnglish p {
  line-height: 1.25;
  font-size: 16px;
  font-size: 1.6rem;
}

.english-inner .txtTitleEnglish .btn-english {
  display: block;
  width: 100%;
  padding: 11px 5px;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  margin: 8px auto -4px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish .btn-english {
    padding: 22px 110px;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
    margin: 16px auto -8px;
    width: auto;
    display: inline-block;
  }
}

.english-inner .txtTitleEnglish .lh15 {
  line-height: 1.5;
}

.english-inner .txtTitleEnglish .lh14 {
  line-height: 1.4;
}

.english-inner .txtTitleEnglish .lh12 {
  line-height: 1.2;
}

.english-inner .txtTitleEnglish table {
  margin-bottom: 5px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish table.tbChinese tr td {
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish table {
    margin-bottom: 10px;
  }
}

.english-inner .txtTitleEnglish table tr:first-child td {
  padding: 6px 8px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish table tr:first-child td {
    padding: 12px 16px;
  }
}

.english-inner .txtTitleEnglish table tr:first-child td:nth-child(2), .english-inner .txtTitleEnglish table tr:first-child td:nth-child(3) {
  background: #ffe1e9;
}

.english-inner .txtTitleEnglish table tr td {
  border: 1px solid #fb4875;
  padding: 0px 8px;
  text-align: center;
}

.english-inner .txtTitleEnglish table tr td:first-child {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish table tr td {
    padding: 0px 16px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.english-inner .txtTitleEnglish table tr td:first-child {
  width: 43%;
}

.english-inner .txtTitleEnglish.txtTitleEnglish00 {
  margin-bottom: 22px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish00 {
    margin-bottom: 44px;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 {
  margin-bottom: 17px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 {
    margin-bottom: 34px;
    float: left;
    width: 45%;
  }
  .english-inner .txtTitleEnglish.txtTitleEnglish01:nth-child(2n) {
    float: right;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 > p {
  position: relative;
  padding-left: 0;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 > p {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 > p:after {
  content: '';
  display: block;
  clear: both;
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 > p img {
  float: left;
  width: 66px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 > p img {
    width: 132px;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 > p span {
  float: right;
  width: calc(100% - 66px);
  padding-left: 14px;
  position: absolute;
  bottom: 7px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 > p span {
    width: calc(100% - 132px);
    padding-left: 28px;
    bottom: 14px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 .step {
  margin: 12px 0px 10px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 .step {
    margin: 24px 0px 20px;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 .step:after {
  content: '';
  display: block;
  clear: both;
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 .step .stepLeft {
  float: left;
  width: 100px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 .step .stepLeft {
    width: 200px;
  }
}

.english-inner .txtTitleEnglish.txtTitleEnglish01 .step .stepRight {
  float: right;
  width: calc(100% - 100px);
  padding-left: 15px;
  padding-top: 2px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish.txtTitleEnglish01 .step .stepRight {
    width: calc(100% - 200px);
    padding-left: 30px;
    padding-top: 4px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.english-inner .txtTitleEnglish .img-map {
  margin: 17px 0px 0px;
}

@media screen and (min-width: 768px) {
  .english-inner .txtTitleEnglish .img-map {
    margin: 34px 0px 0px;
  }
}

.english-inner .ggMap {
  position: relative;
  margin-top: -14px;
  margin-bottom: 7px;
}

@media screen and (min-width: 768px) {
  .english-inner .ggMap {
    max-width: 593px;
    padding: 0px 20px;
    margin: -28px auto 14px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.english-inner .ggMap img {
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .english-inner .ggMap img {
    margin-bottom: 32px;
  }
}

.english-inner .ggMap a.linkMap {
  padding: 10px 13px;
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 13px;
  font-size: 1.3rem;
  color: #fb4875;
}

.english-inner .ggMap a.linkMap:after {
  right: 5px;
  bottom: -50px;
}

@media screen and (min-width: 768px) {
  .english-inner .ggMap a.linkMap {
    padding: 20px 26px;
    right: 0;
    bottom: 0;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 10px 20px 10px 6px;
  }
  .english-inner .ggMap a.linkMap:after {
    right: 10px;
  }
}

.english-inner.chinese-inner {
  padding-top: 20px;
}

.english-inner.chinese-inner .chinese-inner-top {
  padding-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .english-inner.chinese-inner .chinese-inner-top {
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .english-inner.chinese-inner {
    padding: 40px 0px 40px;
  }
}

@media screen and (max-width: 767px) {
  .english-inner.chinese-inner .point-list-block > .items .info .ttl img {
    max-width: 155px;
  }
  .english-inner.chinese-inner .point-list-block > .items .info .ttl {
    margin-bottom: 3px;
  }
  .english-inner.chinese-inner .point-list-block > .items .info .excerpt .txt-top {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 0px;
  }
  .english-inner.chinese-inner .point-list-block > .items .info .excerpt .txt-bottom {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .english-inner.chinese-inner .point-list-block > .items {
    margin-bottom: 0;
  }
  .english-inner.chinese-inner .point-list-block > .items .img {
    width: 42%;
  }
  .english-inner.chinese-inner .point-list-block > .items:nth-child(2) .img {
    position: relative;
    top: 11px;
    max-width: 130px;
  }
  .english-inner.chinese-inner .point-list-block > .items:nth-child(3) .img {
    position: relative;
    top: -2px;
    max-width: 123px;
    left: -5px;
  }
  .english-inner.chinese-inner .point-list-block > .items .info {
    width: 57%;
    padding-right: 0;
  }
}

a.tel {
  color: inherit;
  text-decoration: underline;
}

.chinese-bg {
  margin-bottom: 40px;
  background: #ffd0dc;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .chinese-bg {
    padding: 40px 20px;
  }
}

.step-form {
  margin: 0 -9px 20px;
  display: flex;
  justify-content: center;
}

.step-form .item {
  float: left;
  padding: 0 9px;
}

.step-form .item img.on {
  display: none;
}

.step-form .item img.off {
  display: block;
}

.step-form .item.active img.on {
  display: block;
}

.step-form .item.active img.off {
  display: none;
}

.show-form {
  background: #fff2f9;
  padding: 40px 0;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .show-form {
    padding: 20px 0;
    margin-bottom: 20px;
  }
}

.form {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .form {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.form dl {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.form dl.block {
  display: block;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .form dl {
    display: block;
    overflow: hidden;
  }
}

.form dl dt {
  color: #fb4875;
  float: left;
  width: 30%;
}

@media screen and (max-width: 767px) {
  .form dl dt {
    width: 100%;
    margin-bottom: 10px;
  }
}

.form dl dt label {
  font-weight: bold;
}

.form dl dt label span {
  display: inline-block;
  font-size: 10px;
  font-size: 1rem;
  border: 1px solid #fb4875;
  border-radius: 2px;
  margin-left: 7px;
  line-height: 1;
  padding: 2px;
  font-weight: 400;
}

.form dl dd {
  float: left;
  width: 70%;
}

@media screen and (max-width: 767px) {
  .form dl dd {
    width: 100%;
  }
  .form dl dd .txt-basic {
    font-size: 10px;
    font-size: 1rem;
  }
}

.form dl dd .list-time {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .form dl dd .list-time {
    display: block;
  }
}

.form dl dd .list-time li {
  width: 33.333%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .form dl dd .list-time li:nth-child(1) {
    width: 100%;
    margin-bottom: 10px;
  }
  .form dl dd .list-time li:nth-child(2), .form dl dd .list-time li:nth-child(3) {
    width: 50%;
    float: left;
  }
}

.form dl dd .list-time li select {
  float: left;
  width: calc(100% - 30px);
}

.form dl dd .list-time li span {
  float: right;
  width: 30px;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .form dl dd .list-time li span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.form a {
  text-decoration: underline;
}

.form .btn {
  background: #ff006e;
  color: white;
}

@media screen and (max-width: 767px) {
  .form .btn {
    padding: 17px 10px;
    width: 100%;
  }
}

.form .btn.btn-arrow-right:after {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.form-control {
  display: block;
  width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
  border: 1px solid #999999;
  background: white;
  padding: 5px 10px;
  height: 40px;
}

@media screen and (max-width: 767px) {
  .form-control {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.form-control::-webkit-input-placeholder {
  color: #2b2b2b;
}

.form-control:-moz-placeholder {
  color: #2b2b2b;
}

.form-control::-moz-placeholder {
  color: #2b2b2b;
}

.form-control:-ms-input-placeholder {
  color: #2b2b2b;
}

textarea.form-control {
  height: 120px !important;
}

select.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: white url(../img/contact/reference/select.png) no-repeat;
  background-size: 40px;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

.note {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .note {
    font-size: 12px;
    font-size: 1.2rem;
    text-align: left;
  }
}

.box-recruit {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .box-recruit {
    margin-bottom: 40px;
  }
}

.box-recruit .img {
  float: left;
  width: 40%;
}

@media screen and (max-width: 767px) {
  .box-recruit .img {
    width: 100%;
  }
}

.box-recruit .txt {
  float: right;
  width: 60%;
  padding-top: 29px;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .box-recruit .txt {
    width: 100%;
    padding: 0 0;
    margin-bottom: 15px;
  }
}

.box-recruit .txt .txt-basic .block {
  display: block;
  margin-bottom: 10px;
}

.box-recruit .txt .txt-basic .color-pink {
  font-weight: bold;
}

.table-box strong {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .table-box strong {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.table-box table {
  width: 100%;
}

.table-box table tr th, .table-box table tr td {
  border: 1px solid #999999;
  padding: 20px;
  vertical-align: top;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .table-box table tr th, .table-box table tr td {
    display: block;
    font-size: 12px;
    font-size: 1.2rem;
    width: 100%;
    padding: 9px 17px;
  }
}

.table-box table tr th {
  font-weight: bold;
  background: #ffe1e9;
}

@media screen and (max-width: 767px) {
  .table-box table tr th {
    border-bottom: none;
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  .table-box table tr:nth-child(1) {
    border-top: 1px solid #999999;
  }
}

.links {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  text-decoration: underline;
}

.links:before {
  content: ">";
  position: absolute;
  top: 0;
  left: 0;
}

.list-teacher {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .list-teacher {
    margin-bottom: 30px;
  }
}

.list-teacher .item {
  position: relative;
  padding: 20px 0;
  overflow: hidden;
}

.list-teacher .item:before {
  content: "";
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url("../img/contact/recruit/line.png") no-repeat;
  background-size: 100% 2px;
}

.list-teacher .item:last-child:after {
  content: "";
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/contact/recruit/line.png") no-repeat;
  background-size: 100% 2px;
}

.list-teacher .item img {
  float: left;
  width: 20%;
}

.list-teacher .item p {
  float: right;
  width: 80%;
  padding-left: 20px;
  margin-bottom: 10px;
}

.list-teacher .item p:last-child {
  margin-bottom: 0;
}

.list-teacher .item p.txt-title {
  font-size: 13px;
  font-size: 1.3rem;
}

.list-teacher .item p.txt-title .color-pink {
  font-size: 18px;
  font-size: 1.8rem;
}

.list-parent-teacher {
  margin: 20px -9px 30px;
  position: relative;
  overflow: hidden;
}

.list-parent-teacher:after {
  content: "";
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/contact/recruit/line.png") no-repeat;
  background-size: 100% 2px;
}

.list-parent-teacher .item {
  float: left;
  padding: 0 9px;
  width: 16.666%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .list-parent-teacher .item {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  .list-parent-teacher .item {
    width: 33.333%;
  }
}

.list-parent-teacher .item img {
  width: 100%;
  margin-bottom: 10px;
}

.page-recruit .table-box table tr th,
.page-recruit .table-box table tr td {
  border-color: #fb4875;
}

.page-recruit .index-section-02:after {
  display: none;
}

@media (min-width: 768px) {
  .page-recruit .dvd-box {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  .page-recruit .dvd-box .image {
    width: 50%;
    float: left;
    padding: 0 15px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  .page-recruit .dvd-box .image:last-child {
    margin-top: 10px;
  }
  .page-recruit .dvd-box .image img {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .page-hawaii .btn-block {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.page-hawaii .point-list-block > .items .info .ttl {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .page-hawaii .section-ttl .caption-lg {
    padding: 9px 10px;
  }
}

.page-hawaii .btn.btn-arrow:before {
  background-image: url("../img/index/voice-arrow-slide.png");
  height: 13px;
}

.page-hawaii .banner-sm .txt-top {
  font-weight: 600;
}

/*.hawaii-point{
	display: table;
	width: 100%;
	.info, .img{
		display: table-cell;
		vertical-align: top;
	}
	.info{
		padding-right: 10px;
	}
	.img{
		width: 170px;
	}
}*/
