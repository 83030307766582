.page-mainv
{
    display: table;

    width: 100%;
    height: 80px;

    background: url('../img/about/voice/bg-title.png') no-repeat center center /100%;
    .page-mainv-txts
    {
        display: table-cell;

        text-align: center;
        vertical-align: middle;
    }
    .page-mainv-ltxt
    {
        position: relative;

        display: inline-block;

        color: #fb4875;

        @include font-size(24);
        &:before,
        &:after
        {
            position: absolute;

            display: block;

            width: 18px;
            height: 30px;

            content: '';

            background-size: 100% 100%;
        }
        &:before
        {
            top: -10px;
            right: 102%;

            background: url('../img/index/star-pattern-left.png') no-repeat center center /100%;
        }
        &:after
        {
            top: 10px;
            left: 102%;

            background: url('../img/index/star-pattern-right.png') no-repeat center center /100%;
        }
    }
}

.about-list-block
{
    .items
    {
        width: 100%;
        margin-bottom: 15px;
        padding-bottom: 15px;

        list-style: none;

        background: url('../img/about/voice/icon-01.png') no-repeat bottom center /100%;
        .img
        {
            float: left;

            width: 26%;
        }
        .info
        {
            width: calc(64% - 10px);
            padding-left: 10px;
            .ttl
            {
                margin-bottom: 10px;

                color: #fb4875;

                @include font-size(14);
            }
            .txt-sm
            {
                display: flex;

                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }
    @media (min-width: 768px)
    {
        .items
        {
            .img
            {
                margin-right: 20px;
            }
        }
        .txt-basic
        {
        }
    }
    @media screen and (max-width: 767px)
    {
        .items
        {
            display: flex;

            justify-content: space-between;
            flex-wrap: wrap;
            .img
            {
                width: 36%;
            }
        }
    }
}

#main-footer
{
    &.about_voice-footer
    {
        border: 0;
    }
}

.paging-navigation
{
    text-align: center;
    .pagination
    {
        display: flex;

        margin: 20px 0;
        padding-left: 0;

        justify-content: center;
        align-items: center;
        .page-numbers
        {
            line-height: 30px;

            display: flex;

            width: 30px;
            height: 30px;
            margin-left: 10px;

            cursor: pointer;

            color: #fb4875;
            border: 1px solid #fb4875;
            border-radius: 50%;
            background: #fff;

            align-items: center;
            justify-content: center;
            @include smooth-transition;
            @include font-size(15);
            &:first-child
            {
                margin: 0;
            }
            &.current
            {
                color: #fff;
                border: 1px solid #fb4875;
                background-color: #fb4875;

                @include smooth-transition;
            }
        }
    }
}

.tab-content-about
{
    display: none;
    &.active
    {
        display: block;
    }
}

.yellow-box
{
    padding: 40px;

    text-align: center;

    background-color: #f9f2b0;
    @media screen and (max-width: 767px)
    {
        padding: 20px;
    }
    .ttl
    {
        font-weight: bold;

        position: relative;
        z-index: 1;

        display: inline-block;

        color: #fb4875;

        @include font-size(24);
        @media screen and (max-width: 767px)
        {
            @include font-size(20);
        }
        &:after
        {
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 10px;

            content: '';

            background-color: #fabf9e;
        }
    }
    .sub-ttl
    {
        margin-bottom: 15px;

        color: #2b2b2b;
        @media screen and (max-width: 767px)
        {
            margin-bottom: 10px;
        }
        span
        {
            display: block;

            width: 100%;

            @include font-size(16);
            @media screen and (max-width: 767px)
            {
                @include font-size(14);
            }
        }
        small
        {
            display: block;

            width: 100%;

            @include font-size(12);
            @media screen and (max-width: 767px)
            {
                @include font-size(12);
            }
        }
    }
    .img-list-02
    {
        display: flex;

        margin: 0 -10px;

        justify-content: center;
        .items
        {
            margin: 0 10px;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
            {
                width: 48%;
            }
        }
    }
    .cta
    {
        .btn
        {
            width: 100%;
            padding-right: 30px !important;
            padding-left: 45px !important;
            &:before
            {
                position: absolute;
                left: 15px;

                display: block;

                width: 25px;
                height: 17px;

                content: '';

                background: url('../img/about/index/mail-icn.png') no-repeat center center;
                background-size: 100% 100%;

                @include v-align;
            }
        }
    }
}

/*about index*/
.page-about
{
    .yellow-box
    {
        .cta .btn
        {
            @include font-size(14);
        }
        .ttl
        {
            &:after
            {
                display: none;
            }
            .txt-bdr
            {
                background:    -moz-linear-gradient(top, rgba(251,72,117,0) 60%, rgba(251,72,117,.3) 60%, rgba(251,72,117,.3) 100%);
                background: -webkit-linear-gradient(top, rgba(251,72,117,0) 60%, rgba(251,72,117,.3) 60%, rgba(251,72,117,.3) 100%);
                background:         linear-gradient(to bottom, rgba(251,72,117,0) 60%, rgba(251,72,117,.3) 60%, rgba(251,72,117,.3) 100%);
                @media screen and (max-width: 430px)
                {
                    @include font-size(16);
                }
            }
        }
        .sub-ttl
        {
            span
            {
                @media screen and (max-width: 450px)
                {
                    @include font-size(11);
                }
            }
            small
            {
                @media screen and (max-width: 450px)
                {
                    @include font-size(9);
                }
            }
        }
    }
    @media screen and (min-width: 768px)
    {
        .point-list-child-content
        {
            .ttl
            {
                margin-bottom: 20px;
            }
        }
        .yellow-box
        {
            .yellow-box-inner
            {
                display: flex;

                align-items: center;
                justify-content: space-between;
                .clm-left-pc
                {
                    width: 65%;
                    padding-right: 10px;
                }
                .img-list-02
                {
                    margin: 0;
                    .items
                    {
                        margin: 0 10px;
                    }
                }
            }
            .cta .btn
            {
                width: 50%;
                max-width: 460px;
                margin: 0 auto;

                @include font-size(16);
            }
        }
        .dance-list-03 .items
        {
            .flex-row
            {
                .img
                {
                    width: 150px;
                }
                .desc
                {
                    width: calc(100% - 165px);
                }
            }
        }
    }
    .section-ttl
    {
        .caption-lg
        {
            @media screen and (max-width: 420px)
            {
                @include font-size(18);
            }
        }
    }
    .dance-list-03
    {
        .items
        {
            .desc
            {
                .excerpt
                {
                    @media screen and (max-width: 767px)
                    {
                        @include font-size(12);
                    }
                }
            }
        }
    }
}
