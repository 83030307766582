.page-friend {
	.caption-lg.h-80 {
		padding: 15px 10px;
		@media screen and (max-width: 767px) {
			padding: 17px 10px 16px;
		}
	}
	.steps-block {
		@media screen and (min-width: 768px) {
			.items {
				.ttl {
					margin-bottom: 30px;
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					p {
						display: inline-block;
						margin-left: 10px;
						line-height: 1;
					}
				}
				.desc {
					.img {
						max-width: 200px;
						margin: 0 auto;
					}
				}
			}
			.arrows {
				padding: 0;
			}
		}
		@media screen and (max-width: 767px) {
			padding-top: 10px;
			.arrows {
				img {
					height: 25px;
				}
			}
		}
	}
}
.ttl-item {
	color: #fb4875;
	@include font-size(25);
	margin: 20px 0;
	.small{
		@include font-size(20);
	}
	@media screen and (max-width: 767px) {
		margin-top: 10px;
		@include font-size(20);
		.small{
			@include font-size(15);
		}
	}
	
}

.ttl-box {
	margin-bottom: 30px;
	@include font-size(20);
	@media screen and (max-width: 767px) {
		margin-bottom: 13px;
		@include font-size(16);
	}
	span {
		display: inline-block;
		position: relative;
		color: #fb4875;
		&:after {
			display: block;
			content: '';
			height: 15px;
			background-color: #ffd0dc;
			position: absolute;
			bottom: 0;
			left: -3px;
			right: -3px;
			z-index: -1;
			-webkit-transform: rotate(-2deg);
			-ms-transform: rotate(-2deg);
			transform: rotate(-2deg);
			@media screen and (max-width: 767px) {
				height: 10px;
			}
		}
	}
}

.benefit-list {
	.item {
		float: left;
		width: 48%;
		&:nth-child(2n) {
			float: right;
		}
		img {
			width: 100%;
		}
		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
			float: none;
			width: 100%;
			text-align: center;
			img{
				max-width: 100%;
			}
			&:nth-child(2n) {
				float: none;
			}
		}
	}
}

.box-smail {
	.benefit-list {
		display: inline-block;
		max-width: 600px;
	}
}

.shade-item {
	display: flex;
	justify-content: center;
	.item {
		padding: 0 7px;
		img {
			width: 45px;
		}
	}
}

.steps-block {
	&.steps-block-02 {
		.items {
			.desc {
				@media screen and (max-width: 767px) {
					.img {
						width: 100px;
					}
					.excerpt {
						width: calc(100% - 100px);
						padding-left: 15px;
					}
				}
			}
		}
	}
}