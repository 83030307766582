.form-container-02 {
	.form-group-wrapper {
		display: block;
		overflow: hidden;
		margin-right: -20px;
		@supports ( -ms-ime-align:auto) {
			/*----MS Edge Browser CSS Start----*/
			list-style: none;
		}
		@media screen and (max-width: 767px) {
			margin-right: 0;
			&.form-group-wrapper-block {
				.form-group {
					width: 48% !important;
					margin: 0 !important;
					&:nth-child(2) {
						float: right;
					}
					button {
						@include font-size(11);
					}
				}
			}
		}
		&.center {
			@media screen and (min-width: 768px) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-right: 0;
			}
			.form-group {
				@media screen and (min-width: 768px) {
					float: none;
					margin: 0 5px 10px;
				}
				@media screen and (max-width: 767px) {
					width: 31.74%;
					&:first-of-type {
						margin-right: 2.3% !important;
					}
					&:nth-of-type(3n) {
						margin-right: 0;
					}
				}
			}
		}
		.form-group {
			float: left;
			margin-right: 10px;
			margin-bottom: 10px;
			list-style: none;
			&.col-md {
				width: 362px;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
			&.col-sm {
				width: 176px;
				@media screen and (max-width: 767px) {
					width: 31.74%;
					margin-right: 2.3%;
					margin-bottom: 2.3%;
					&:nth-child(3n + 1) {
						margin-right: 0;
					}
				}
			}
			.custom-radio {
				input[type="radio"] {
					display: none;
					border: none;
					appearance: none;
					+ label {
						display: flex;
						justify-content: center;
						align-content: center;
						align-items: center;
						flex-wrap: wrap;
						background-color: #fff;
						border-radius: 5px;
						text-align: center;
						padding: 0 15px 0 50px;
						position: relative;
						border: 2px solid transparent;
						height: 50px;
						@include font-size(16);
						@include smooth-transition;
						@media screen and (max-width: 767px) {
							padding: 0 3px 0 20px;
							@include font-size(11);
						}
						&:before {
							display: block;
							content: '';
							width: 15px;
							height: 15px;
							border: 4px solid #787878;
							border-radius: 50%;
							background-color: #fff;
							position: absolute;
							@include v-align;
							left: 20px;
							@media screen and (max-width: 767px) {
								width: 10px;
								height: 10px;
								border-width: 2px;
								left: 10px;
							}
							@media screen and (max-width: 350px) {
								left: 5px;
							}
						}
						&:after {
							display: block;
							content: '';
							width: 11px;
							height: 11px;
							background-color: #ff006e;
							border-radius: 50%;
							position: absolute;
							@include v-align;
							left: 26px;
							opacity: 0;
							visibility: hidden;
							@include smooth-transition;
							@media screen and (max-width: 767px) {
								width: 6px;
								height: 6px;
								left: 14px;
							}
							@media screen and (max-width: 350px) {
								left: 9px;
							}
						}
						&:hover {
							cursor: pointer;
						}
						img {
							@media screen and (max-width: 767px) {
								width: 12px;
							}
						}
						small {
							display: block;
							width: 100%;
							@include font-size(13);
							@media screen and (max-width: 767px) {
								@include font-size(9);
							}
						}
					}
					&:checked {
						+ label {
							border-color: #ff006e;
							color: #ff006e;
							@include smooth-transition;
							&:after {
								opacity: 1;
								visibility: visible;
								@include smooth-transition;
							}
						}
					}
				}
			}
			button {
				display: flex;
				width: 100%;
				justify-content: center;
				text-align: center;
				align-items: center;
				align-content: center;
				background-color: #fff;
				border-radius: 5px;
				border: none;
				appearance: none;
				color: #000;
				padding: 0 15px;
				height: 50px;
				position: relative;
				@include font-size(16);
				@include smooth-transition;
				&:before {
					// Flex center align bug fix on IE
					display: block;
					content: '';
					width: 0;
					height: 0;
					background-color: transparent;
				}
				&:focus {
					outline: none;
				}
				&:hover {
					cursor: pointer;
					opacity: .7;
					@include smooth-transition;
				}
			}
		}
	}
}

.schedule-tab {
	.tab-list {
		display: flex;
		justify-content: center;
		margin-bottom: -2px;
		&.tab-list-sp {
			@media screen and (max-width: 500px) {
				display: block;
				padding: 0 20px;
				overflow: hidden;
				.tab {
					float: left;
					width: 48%;
					&:nth-child(2n) {
						float: right;
					}
					a {
						width: 100%;
						@include font-size(16);
						margin: 0;
					}
				}
			}
		}
		.tab {
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 190px;
				height: 50px;
				background-color: #ffe5ec;
				color: #2b2b2b;
				margin: 0 5px;
				border: 2px solid transparent;
				@include font-size(17);
				@include smooth-transition;
				&.active {
					border-color: #ff006e;
					color: #ff006e;
					background-color: #fff;
					@include smooth-transition;
				}
			}
		}
	}
	.schedule-calendar {
		.wrapper {
			display: block;
			margin: 0 auto;
			max-width: 632px;
			padding-left: 24px;
			position: relative;
			@media screen and (max-width: 640px) {
				padding-left: 22px;
			}
			&.wrap-scroll-date{
				max-height: 682px;
				overflow-y: auto;
				max-width: 640px;
				padding-left: 0px;
				@media screen and (max-width: 768px){
					padding-left: 3px;
					padding-right: 2px !important;
				}
			}
		}
		.calendar-header {
			position: relative;
			margin-bottom: 3px;
			&:before, &:after {
				display: block;
				content: '';
				width: 100%;
				height: 3px;
				background: url("../img/lineup/hiphop/line-pink.png") repeat-x center center;
				background-size: auto 3px;
			}
			&:before {
				bottom: 100%;
			}
			&:after {
				top: 100%;
			}
			table {
				width: 100%;
				table-layout: fixed;
				td {
					padding: 8px 5px;
					width: 13.54%;
					line-height: 1;
					vertical-align: middle;
					text-align: center;
					@include font-size(16);
					@media screen and (max-width: 640px) {
						padding: 6px 5px;
					}
				}
			}
			.sat {
				color: #1bb3ce;
			}
			.sun {
				color: #f6698a;
			}
		}
		.calendar-content {
			.table-calendar {
				margin-bottom: 15px;
				width: 100%;
				table-layout: fixed;
				margin-bottom: 15px;
				position: relative;

				&.line {
					margin-bottom: 30px;
					&:after {
						content: '';
						display: block;
						position: absolute;
						width: 100%;
						height: 3px;
						background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
						background-size: auto 3px;
						left: 0;
						bottom: -15px;
					}
				}
				.time-bg {
					width: 30px!important;
					background: #fb4875 !important;
					position: relative;
					color: #fff;
					padding-top: 8px;
					font-size: 22px;
					text-align: center;
					@media(max-width: 640px) {
						width: 20px!important;
					}
					.ver-rl {
						display: block;
						transform: rotate(90deg)
					}
				}
				td {
					width: calc(100% - 32px / 7);
					width: calc(100% / 7);
					box-sizing: border-box;
					border-right: 4px solid white;
					border-bottom: 4px solid white;
					vertical-align: top;
					background: url("../img/schedule/cal-items-bg.png") no-repeat center center;
					background-size: 100% 100%;
					@media screen and (max-width: 320px) {
						border-right: 2px solid white;
					}
					.items{
						&.color-teal {
							.top {
								background-color: #4cbcae;
							}
							.bottom {
								border: 2px solid #4cbcae;
								color: #4cbcae;
							}
						}
						&.color-pink {
							.top {
								background-color: #ff5262;
							}
							.bottom {
								border: 2px solid #ff5262;
								color: #ff5262;
							}
						}
						&.color-ltbrown {
							.top {
								background-color: #e69d11;
							}
							.bottom {
								border: 2px solid #e69d11;
								color: #e69d11;
							}
						}
						&.color-violet {
							.top {
								background-color: #9e61c5;
							}
							.bottom {
								border: 2px solid #9e61c5;
								color: #9e61c5;
							}
						}
						&.color-ltpink {
							.top {
								background-color: #f6698a;
							}
							.bottom {
								border: 2px solid #f6698a;
								color: #f6698a;
							}
						}
						&.color-orange {
							.top {
								background-color: #ff8737;
							}
							.bottom {
								border: 2px solid #ff8737;
								color: #ff8737;
							}
						}
						&.color-ltgreen {
							.top {
								background-color: #6dc96e;
							}
							.bottom {
								border: 2px solid #6dc96e;
								color: #6dc96e;
							}
						}
						&.color-cheer {
							.top {
								background-color: #ff73ae;
							}
							.bottom {
								border: 2px solid #ff73ae;
								color: #ff73ae;
							}
						}
						&.color-kpop {
							.top {
								background-color: #c8a900;
							}
							.bottom {
								border: 2px solid #c8a900;
								color: #c8a900;
							}
						}
						.top {
							position: relative;
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;
							color: white;
							padding: 8px 5px 4px;
							line-height: 1;
							@include font-size(14);
							@media screen and (max-width: 640px) {
								padding: 2px;
							}
							.date {
								position: relative;
								margin-top: 5px;
								line-height: 1;
								@include font-size(14);
								@media screen and (max-width: 500px) {
									margin-top: 3px;
									@include font-size(11);
								}
								&:after {
									content: "";
									display: block;
									position: absolute;
									right: 0;
									top: 0;
									background: url("../img/index/shield-sm-green-yellow.png");
									width: 16px;
									height: 100%;
									background-size: contain;
									background-repeat: no-repeat;
									@media screen and (max-width: 500px) {
										height: 13px;
										width: 10px;
										top: 50%;
										right: -3px;
										-webkit-transform: translate(-50%,-50%);
										-moz-transform: translate(-50%,-50%);
										-ms-transform: translate(-50%,-50%);
										-o-transform: translate(-50%,-50%);
										transform: translate(-50%,-50%);
									}
								}
							}
						}
						.bottom {
							background-color: #fff;
							padding: 8px 5px 4px 3px;
							@include font-size(14);
							font-weight: bold;
							position: relative;
							line-height: 1;
							text-align: center;
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
							@media screen and (max-width: 500px) {
								padding: 4px 0 3px;
								@include font-size(10);
							}
							@media screen and (max-width: 320px) {
								@include font-size(9);
								font-family: 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
							}
						}
					}
					> span {
						display: block;
						@include smooth-transition;
						&:hover {
							opacity: .7;
							@include smooth-transition;
						}
					}
				}
			}
		}

	}
}
.time-bg {
	background: #fff !important;
	position: relative;
	.time-navi-table{
		li > span{
			display: block;
			text-align: center;
			@include font-size(16);
			@media screen and (max-width: 640px) {
				@include font-size(12);
			}
		}
	}
}
.time-navi {
	position: absolute;
	top: 0;
	left: 5px;
	height: 100%;
	@media screen and (max-width: 500px) {
		left: 0;
	}
	li {
		position: absolute;
		@include v-align;
		&.prev {
			position: relative;
			top: 0;
			transform: translateY(0);
			a {
				display: block;
				min-width: 22px;
				padding-top: 40px;
				@media screen and (max-width: 500px) {
					padding-top: 30px;
				}
			}
			&:before {
				display: block;
				content: '';
				background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
				width: 15px;
				height: 10px;
				background-size: 100% 100%;
				position: absolute;
				left: 50%;
				top: 12%;
				transform: translateX(-50%) rotate(180deg);
				pointer-events: none;
			}
		}
		&.next {
			position: absolute;
			top: auto;
			bottom: 0;
			transform: translateY(0);
			a {
				display: block;
				min-width: 22px;
				padding-bottom: 40px;
				@media screen and (max-width: 500px) {
					padding-bottom: 30px;
				}
			}
			&:before {
				display: block;
				content: '';
				background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
				width: 15px;
				height: 10px;
				background-size: 100% 100%;
				position: absolute;
				left: 50%;
				bottom: 10%;
				transform: translateX(-50%);
				pointer-events: none;
			}
		}
		@media screen and (max-width: 500px) {
			&.current{
				left: 5px;
			}
		}
		a {
			display: block;
			text-align: center;
			color: #000;
			@include font-size(16);
			@media screen and (max-width: 500px) {
				@include font-size(11);
			}
			span.ver-rl {
				writing-mode: vertical-rl;
				text-orientation: upright;
			}
		}
	}
}
.schedule-tab .schedule-calendar #tab02{
	.slick-vertical .slick-slide{
		min-height: 52vh;
		overflow: auto;
		@media screen and (min-width: 601px) {
			min-height: 458px;
		}
	}
}
.schedule-tab .schedule-calendar #tab02{
	.slick-vertical .slick-slide{
		min-height: 52vh;
		overflow: auto;
		@media screen and (min-width: 601px) {
			min-height: 458px;
		}
	}
}

.schedule-tab .schedule-calendar .tab-content {
	width: 100%;
}

.bg_pink {
	background-color: #ffd0dc;
	padding: 24px 0;
	position: relative;
	&:before, &:after {
		content: "";
		display: block;
		height: 2px;
		width: 100%;
		background-color: #fff;
		position: absolute;
	}
	&:before {
		top: 1px;
	}
	&:after {
		bottom: 1px;
	}
	.banner-sm {
		max-width: 454px;
		display: block;
		margin: 0 auto;
		&.banner-sm-sp {
			@media screen and (max-width: 500px) {
				max-width: 100%;
				padding: 0 20px;
			}
		}
	}
	&.bg_pink_fix {
		&:before, &:after {
			display: none;
		}
	}
}

.line-bottom {
	position: relative;
	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 3px;
		background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
		background-size: auto 3px;
	}
}

.next-month {
	display: flex;
	width: 100%;
	padding-top: 5px;
	a {
		position: relative;
		color: #fb4875;
		@include font-size(16);
		font-weight: bold;
	}
	.prev {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		padding-left: 15px;
		margin-left: -45px;
		@media screen and (max-width: 500px) {
			margin-left: -15px;
		}
		&:before {
			display: block;
			content: '';
			background: url(../img/schedule/accor-arrow-left.png) no-repeat center center;
			width: 10px;
			height: 15px;
			background-size: 100% 100%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			transition: all .3s ease;
		}
	}
	.next {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		padding-right: 15px;
		margin-right: 10px;
		&:before {
			display: block;
			content: '';
			background: url(../img/schedule/accor-arrow-right.png) no-repeat center center;
			width: 10px;
			height: 15px;
			background-size: 100% 100%;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			transition: all .3s ease;
		}
	}
}

.heading-month {
	background-color: #ffd0dc;
	padding: 3px 8px;
	@include font-size(14);
	color: #000;
}

.table-caption {
	@include font-size(14);
	@media screen and (max-width: 767px) {
		@include font-size(11);
	}
}

.wrap-scroll-date02{
	max-height: 455px;
	overflow-y: auto;
	max-width: 640px !important;
	padding-left: 0px !important;
	@media screen and (max-width: 768px){
		padding-left: 3px;
		padding-right: 2px !important;
		.time-bg .time-navi-table {
			left: 3px !important;
		}
	}
}