.tahitian-basic-inner{
  margin-bottom: 30px;
  @media screen and(min-width: 768px){
    margin-bottom: 60px;
  }

  .basic-item{
    font-size: 14px;
    font-size: 1.4rem;
    @media screen and(min-width: 768px){
      display: block;
      font-size: 16px;
      font-size: 1.6rem;
    }
    .item-img{
      position: relative;
      img{
        width: 100%;
      }
      .txtAbsolute{
        font-size: 13px;
        font-size: 1.3rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -57%);
        background: #ff0030;
        padding: 4px 4px;
        color: #ffffff;
        font-weight: bold;
        @media screen and(min-width: 768px){
          font-size: 18px;
          font-size: 1.8rem;
          padding: 8px;
        }
      }
    }
    .item-content{
      h4 {
        margin-bottom: 5px;
        margin-top: 6px;
        @media screen and(min-width: 768px){
          margin-bottom: 5px;
          margin-top: 6px;
        }
        
        a {
          color: #f6698a;
          font-size: 16px;
          font-size: 1.6rem;
          text-decoration: underline;
          text-underline-position: under;
  
          @media screen and(min-width: 768px) {
            font-size: 20px;
          }
        }
      }
    }
    .hr{
      background-image: url(../img/lineup/tahitian/basic/hr.png);
      background-repeat: no-repeat;
      height: 2px;
      margin: 14px 0px 19px;
      @media screen and(min-width: 768px){
        margin: 28px 0px 38px;
        width: 100%;
        background-size: cover;
      }
    }
  }
}

.basic-btn{
  margin-bottom: 20px;
  .btn{
    padding: 10px 20px;
    display: block;
    @media screen and(min-width:768px ){
      color: #ff006e;
      text-align: center;
      padding: 15px 50px;
      position: relative;
      border-bottom: 1px solid #ff006e;
      font-size: 20px;
      font-size: 2rem;
    }
  }
}

h2.ttlBasic {
  padding: 9px 10px;
  @media(min-width: 768px) {
    padding: 15px 10px;
  }
}

h3.ttlBasic,h2.ttlBasic {
  text-align: center;
  background: #fb4875;
  font-size: 24px;
  font-size: 2.4rem;
  color: #ffffff;
  margin: 0px -20px 19px;
  position: relative;
  @media screen and(min-width: 768px){
    font-size: 36px;
    font-size: 3.6rem;
    margin: 0px -40px 38px;
  }
  &:before{
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #fb4875;
    position: absolute;
    left: 0;
    bottom: calc(100% + 3px);
  }
}

h3.ttlBasic{
  line-height: 26px;
  padding: 13px 0px;
  @media(min-width: 768px) {
    padding: 26px 0px;
  }
}

.page-lineup-tahitian-summary {
  @media screen and (max-width: 767px) {
    .dance-list-03 .items .ttl {
      margin-bottom: 10px;
    }
    
    .dance-list-03 .items .item-inner .img,
    .dance-list-03 .items .item-inner .desc {
      width: 100%;
    }
    
    .dance-list-03 .items {
      margin-bottom: 20px;
    }
    
    .lineup-belly-summary-section-01 {
      margin-bottom: 30px;
    }
  }
  
  @media(max-width: 800px) {
    .btn-basic {
      width: 100%;
      margin-bottom: 25px;
    }
  }
}

.txtTop{
  text-align: center;
  padding: 23px 0px 22px;
  font-size: 12px;
  font-size: 1.2rem;
  @media screen and(min-width: 768px){
    font-size: 16px;
    font-size: 1.6rem;
    padding: 46px 0px 44px;
  }
}

.page-lineup-tahitian-basic {
  .txtTop {
    padding: 20px 0;
  }
  
  .tahitian-basic-inner .basic-item .hr {
    margin: 20px 0;
  }
  
  @media(min-width: 768px) {
    .tahitian-basic-inner {
      overflow: hidden;
      margin-left: -20px;
      margin-right: -20px;
    }
  
    .basic-item {
      width: 50%;
      float: left;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
}