.english-inner {
  padding: 25px 0px 20px;
  font-size: 12px;
  font-size: 1.2rem;
  @media screen and(min-width: 768px) {
    padding: 50px 0px 40px;
    font-size: 24px;
    font-size: 2.4rem;
  }
  .txtAbout {
    margin: 8px 0px 28px;
    line-height: 1.4;
    @media screen and(min-width: 768px) {
      margin: 16px 0px 56px;
      font-size: 16px;
      font-size: 1.6rem;
    }
  }
  .about-fees {
    @media screen and(min-width: 768px) {
      width: 100%;
    }
  }
  h2.ttlBasic {
    padding: 11px 0px;
    margin-bottom: 20px;
    @media screen and(min-width: 768px) {
      padding: 11px 0px;
      margin: 0px -1000px 40px;
    }
  }

  .txtTitleEnglish {
    margin: 0px 0px 32px;
    @media screen and(min-width: 768px) {
      margin: 0px 0px 64px;

    }
    p.color-pink {
      background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
      margin-bottom: 7px;
      padding-bottom: 8px;
      padding-left: 5px;
      @media screen and(min-width: 768px) {
        margin-bottom: 14px;
        padding-bottom: 16px;
        padding-left: 10px;
        font-size: 20px;
        font-size: 2rem;
      }
    }
    span.color-pink{
	  display: block;
     @media screen and(max-width: 768px){
       margin-top: -12px;
       display: inline-block;
     }
    }
    p {
      line-height: 1.25;
      font-size: 16px;
      font-size: 1.6rem;
    }
    .btn-english {
      display: block;
      width: 100%;
      padding: 11px 5px;
      font-size: 18px;
      font-size: 1.8rem;
      text-align: center;
      margin: 8px auto -4px;
      @media screen and(min-width: 768px) {
        padding: 22px 110px;
        font-size: 16px;
        font-size: 1.6rem;
        text-align: center;
        margin: 16px auto -8px;
        width: auto;
        display: inline-block;

      }
    }
    .lh15 {
      line-height: 1.5;
    }
    .lh14 {
      line-height: 1.4;
    }
    .lh12 {
      line-height: 1.2;
    }
    table {
      margin-bottom: 5px;
      width: 100%;
      &.tbChinese{
        tr{
          td{
            @media screen and(min-width: 768px){
              padding: 12px 16px!important;
            }
          }
        }
      }
      @media screen and(min-width: 768px) {
        margin-bottom: 10px;
      }
      tr {
        &:first-child {
          td {
            padding: 6px 8px;
            @media screen and(min-width: 768px) {
              padding: 12px 16px;
            }
            &:nth-child(2), &:nth-child(3) {
              background: #ffe1e9;
            }
          }
        }
        td {
          &:first-child {
            text-align: left;
          }
          border: 1px solid #fb4875;
          padding: 0px 8px;
          text-align: center;
          @media screen and(min-width: 768px) {
            padding: 0px 16px;
            font-size: 16px;
            font-size: 1.6rem;
          }
          &:first-child {
            width: 43%;
          }
        }
      }
    }
    &.txtTitleEnglish00 {
      margin-bottom: 22px;
      @media screen and(min-width: 768px) {
        margin-bottom: 44px;
      }
    }
    &.txtTitleEnglish01 {
      margin-bottom: 17px;
      @media screen and(min-width: 768px) {
        margin-bottom: 34px;
        float: left;
        width: 45%;
        &:nth-child(2n){
          float: right;
        }
      }
      & > p {
        position: relative;
        padding-left: 0;
        font-size: 14px;
        font-size: 1.4rem;
        @media screen and(min-width: 768px) {
          font-size: 28px;
          font-size: 2.8rem;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          float: left;
          width: 66px;
          @media screen and(min-width: 768px) {
            width: 132px;
          }
        }
        span {
          float: right;
          width: calc(100% - 66px);
          padding-left: 14px;
          position: absolute;
          bottom: 7px;
          @media screen and(min-width: 768px) {
            width: calc(100% - 132px);
            padding-left: 28px;
            bottom: 14px;
            font-size: 20px;
            font-size: 2rem;
          }
        }
      }
      .step {
        margin: 12px 0px 10px;
        @media screen and(min-width: 768px) {
          margin: 24px 0px 20px;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        .stepLeft {
          float: left;
          width: 100px;
          @media screen and(min-width: 768px) {
            width: 200px;
          }
        }
        .stepRight {
          float: right;
          width: calc(100% - 100px);
          padding-left: 15px;
          padding-top: 2px;
          @media screen and(min-width: 768px) {
            width: calc(100% - 200px);
            padding-left: 30px;
            padding-top: 4px;
            font-size: 16px;
            font-size: 1.6rem;
          }
        }
      }
    }
    .img-map {
      margin: 17px 0px 0px;
      @media screen and(min-width: 768px) {
        margin: 34px 0px 0px;
      }
    }

  }
  .ggMap {
    position: relative;
    margin-top: -14px;
    margin-bottom: 7px;
    @media screen and(min-width: 768px) {
      max-width: 593px;
      padding: 0px 20px;
      margin: -28px auto 14px;
      font-size: 16px;
      font-size: 1.6rem;
    }
    img{
      margin-bottom: 16px;
      @media screen and(min-width: 768px) {
        margin-bottom: 32px;
      }
    }
    a.linkMap {
      padding: 10px 13px;
      position: absolute;
      right: 0;
      bottom: -25px;
      font-size: 13px;
      font-size: 1.3rem;
      color: #fb4875;
      &:after {
        right: 5px;
        bottom: -50px;
      }
      @media screen and(min-width: 768px) {
        padding: 20px 26px;
        right: 0;
        bottom: 0;
        font-size: 16px;
        font-size: 1.6rem;
        padding: 10px 20px 10px 6px;
        &:after {
          right: 10px;
        }
      }
    }
  }
  &.chinese-inner{
    padding-top: 20px;
    .chinese-inner-top{
      padding-bottom: 16px;
      @media screen and (min-width:768px ){
        padding-bottom: 32px;
      }
    }
    @media screen and (min-width:768px ){
      padding: 40px 0px 40px;
    }
    @media screen and(max-width: 767px){

      .point-list-block > .items .info .ttl img{
        max-width: 155px;
      }
      .point-list-block > .items .info .ttl{
        margin-bottom: 3px;
      }
      .point-list-block > .items .info .excerpt .txt-top {
        font-size: 12px;
        font-size: 1.2rem;
        line-height: 1.5;
        margin-bottom: 0px;
      }
      .point-list-block > .items .info .excerpt .txt-bottom {
        font-size: 12px;
        font-size: 1.2rem;
      }
      .point-list-block > .items{
        margin-bottom: 0;
      }
      .point-list-block > .items .img {
        width: 42%;
      }
      .point-list-block > .items:nth-child(2) .img {
        position: relative;
        top: 11px;
        max-width: 130px;
      }
      .point-list-block > .items:nth-child(3) .img {
        position: relative;
        top: -2px;
        max-width: 123px;
        left: -5px;
      }
      .point-list-block > .items .info {
        width: 57%;
        padding-right: 0;
      }
    }
  }
}


a.tel {
  color: inherit;
  text-decoration: underline;
}
.chinese-bg{
	margin-bottom: 40px;
  background: #ffd0dc;
  padding: 20px;
  @media screen and(min-width: 768px){
    padding: 40px 20px;
  }
}