/* ====================================================
Base style & container
==================================================== */
body {
	color: black;
	font-family: $font-gothic;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	position: relative;
	overflow-x: hidden;
	&:before {
		display: block;
		content: '';
		width: 100vw;
		height: 100vh;
		background-color: rgba(255, 255, 255, 0);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 8;
		pointer-events: none;
		@include smooth-transition;
	}
	&.has-overlay {
		&:before {
			@media screen and (max-width: 767px) {
				background-color: rgba(255, 255, 255, .8);
				@include smooth-transition;
			}
		}
	}
}

.container {
	display: block;
	margin: 0 auto;
	&.container-md {
		max-width: 1040px;
		padding: 0 20px;
	}
	&.container-pc {
		@media(min-width: 768px) {
			max-width: 1040px;
			padding: 0 20px;
		}
	}
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

/* ====================================================
Header & footer
==================================================== */
#main-header {
	padding: 8px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	background-color: #fff;
	@media screen and (max-width: 767px) {
		padding: 0 0 5px;
		height: 65px;
	}
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: 767px) {
			padding: 0;
			display: block;
		}
	}
	.logo {
		width: 272px;
		@media screen and (max-width: 1000px) {
			max-width: 210px;
			width: auto;
		}
		@media screen and (max-width: 767px) {
			width: 210px;
			margin: 15px 10px 0;
			float: left;
		}
		@media screen and (max-width: 375px) {
			max-width: calc(100% - 145px);
		}
	}
	.global-nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		max-width: 700px;
		position: relative;
		@media screen and (max-width: 767px) {
			float: right;
		}
		.main-menu {
			display: flex;
			justify-content: space-between;
			width: calc(100% - 80px);
			@media screen and (max-width: 767px) {
				position: fixed;
				top: 60px;
				left: 100%;
				width: 100%;
				z-index: 10;
				background-color: #fb4875;
				padding: 20px 20px 0;
				flex-wrap: wrap;
				@include smooth-transition;
				&.is-displayed {
					left: 0;
					@include smooth-transition;
				}
				li {
					border-top: 1px solid white;
					margin-bottom: 2.98%;
					&.menu-lg {
						width: 48.5%;
					}
					&.menu-sm {
						width: 31.34%;
					}
					&.row-sp {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						margin-top: 15px;
						margin-bottom: 0;
						padding: 15px 0;
						span {
							display: block;
							width: calc(100% - 165px);
							text-align: left;
							color: white;
							@include font-size(15);
						}
						a {
							display: block;
							text-align: right;
							color: white;
							width: 155px;
							letter-spacing: -1.5px;
							@include font-size(23);
						}
					}
				}
			}
			> li {
				display: flex;
				@include smooth-transition;
				&:hover, &.active {
					opacity: .5;
					@include smooth-transition;
				}
				> a {
					display: block;
					width: 100%;
					text-align: center;
					> img {
						width: 100%;
						@supports ( -ms-ime-align:auto) {
							/*----MS Edge Browser CSS Start----*/
							width: 99%;
						}
						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							width: 99%;
						}
						&.sp {
							@media screen and (min-width: 768px) {
								display: none;
							}
						}
						&.pc {
							@media screen and (max-width: 767px) {
								display: none;
							}
						}
					}
				}
				&.row-sp {
					@media screen and (min-width: 768px) {
						display: none;
					}
				}
			}
		}
		.cta {
			width: 60px;
			margin-left: 15px;
			@media screen and (max-width: 767px) {
				margin-left: 0;
				margin-right: 65px;
			}
		}
		.menu-sp-btn {
			@media screen and (min-width: 768px) {
				display: none;
			}
			width: 60px;
			height: 60px;
			position: absolute;
			top: 0;
			right: 0;
			.close-btn {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
				display: none;
			}
		}
	}
}

#main-footer {
	border-top: 2px solid #ff006e;
	.top-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		@media screen and (max-width: 767px) {
			align-items: flex-start;
		}
		@media screen and (min-width: 768px) {
			&.no-content {
				display: flex;
				justify-content: flex-end;
			}
		}
		.lang-switcher-menu {
			display: flex;
			align-items: center;
			padding: 20px 0;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
			li {
				margin-right: 20px;
				@media screen and (max-width: 767px) {
					margin-bottom: 20px;
					&:last-of-type {
						margin-right: 0;
					}
				}
			  
			  	@media(max-width: 360px) {
				  margin-right: 6px;
				}
			}
			.btn {
				font-weight: bold;
				padding: 0 !important;
				text-align: center;
				width: 160px;
				height: 30px;
				line-height: 30px;
				@include font-size(14);
				@media screen and (max-width: 767px) {
					width: 156px;
					@include font-size(13);
				}
			 
				@media screen and (max-width: 320px) {
				  width: 136px;
				}
			}
		}
		.back-to-top {
			width: 105px;
			@include smooth-transition;
			&:hover {
				opacity: .7;
				@include smooth-transition;
			}
			@media screen and (max-width: 767px) {
				position: absolute;
				right: -20px;
				bottom: 0;
			}
		}
		&.top-footer-c-sp {
			@media screen and (max-width: 767px) {
				display: block;
				text-align: center;
				padding: 20px 0 60px;
				.footer-bnr-sp {
					img {
						width: 100%;
					}
				}
			}
		}
	}
	.sitemap {
		padding: 20px 0;
		background-color: #fff2f9;
		border-top: 1px solid #ff006e;
		.sitemap-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			max-width: 1030px;
			padding: 0 15px;
			margin: 0 auto;
			.col {
				display: flex;
				justify-content: space-between;
				@media screen and (max-width: 767px) {
					flex-wrap: wrap;
					margin-bottom: -10px;
				}
				&.col-lg {
					width: 60%;
					@media screen and (max-width: 767px) {
						width: auto;
					}
				}
				&.col-sm {
					width: 35%;
					@media screen and (max-width: 767px) {
						width: auto;
					}
				}
			}
			ul {
				padding-right: 10px;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
				li {
					margin-bottom: 10px;
					padding-left: 15px;
					position: relative;
					@media screen and (max-width: 767px) {
						padding-left: 10px;
					}
					&:before {
						display: block;
						content: '';
						width: 6px;
						height: 6px;
						background-color: transparent;
						border-bottom: 2px solid #ff006e;
						border-right: 2px solid #ff006e;
						position: absolute;
						top: 5px;
						left: 0;
						transform: rotate(-45deg);
						@media screen and (max-width: 767px) {
							width: 4px;
							height: 4px;
						}
					}
					&:last-of-type {
						@media screen and (min-width: 768px) {
							margin-bottom: 0;
						}
					}
					a {
						color: #ff006e;
						text-decoration: underline;
						@include font-size(14);
						@include smooth-transition;
						@media screen and (max-width: 767px) {
							@include font-size(12);
						}
						&:hover {
							text-decoration: none;
							opacity: .7;
							@include smooth-transition;
						}
					}
				}
			}
		}
	}
	.copyright {
		border-top: 1px solid #ff1375;
		border-bottom: 1px solid #ff1375;
		text-align: center;
		color: #000;
		@include font-size(14);
		padding: 16px 10px;
		@media screen and (max-width: 767px) {
			padding: 10px;
			@include font-size(11);
		}
	}
	.footer-caption {
		padding: 20px 0 60px;
		color: #fb4875;
		line-height: 1.5;
		@include font-size(12);
		@media screen and (max-width: 767px) {
			@include font-size(10);
		}
		dt {
			font-weight: bold;
		}
		dd {
			margin-bottom: 25px;
			@media screen and (max-width: 767px) {
				margin-bottom: 15px;
			}
		}
	}
	.footer-bnr {
		@include smooth-transition;
		@media screen and (min-width: 768px) {
			img{
				max-width: 335px;
				height: auto;
			}
		}
		&:hover {
			//opacity: .7;
			cursor: pointer;
			@include smooth-transition;
		}
		&.fix{
			&:hover{
				cursor: inherit;
			}
		}
	}
	&.lineup-hiphop_index-footer {
		.top-footer{
			.back-to-top{
				position: absolute;
				bottom: 0;
				right: 0;
				@media screen and (max-width: 767px) {
					right: 0;
				}
			}
		}
		@media screen and (max-width: 767px) {
			border-top: none;
			.top-footer {
				min-height: unset;
				padding-left: 20px;
				padding-right: 20px;
				padding-bottom: 70px;
				text-align: center;
				justify-content: center;
				border-bottom: 1px solid #ff006e;
				position: relative;
				left: -20px;
				width: calc(100% + 40px);
			}
		}
	}
	&.schedule-footer{
		.top-footer{
			position: relative;
			display: block;
			> div {
				text-align: center;
				a img{
					margin: 0 auto;
				}
			}
			.back-to-top{
				position: absolute;
				bottom: 0;
				right: 0;
				@media screen and (max-width: 767px) {
					right: -20px;
				}
			}
		}
	}
}

/* ====================================================
Components
==================================================== */
.main-visual {
	/*background-color: #ffe1e9;*/
	&.main-visual-while {
		background-color: #fff;
	}
	.container.container-md {
		padding: 0;
		max-width: 1000px;
	}
	.main-visual-slideshow,.main-visual-slideshow-02 {
		margin-bottom: 45px !important;
		@media screen and (max-width: 767px) {
			margin-bottom: 35px !important;
		}
		.slick-arrow {
			width: 38px;
			height: 38px;
			border: 1px solid #ff167b;
			background-color: white;
			border-radius: 50%;
			z-index: 1;
			&:before {
				display: block;
				content: '';
				width: 13px;
				height: 13px;
				background-color: transparent;
				border-bottom: 2px solid #ff167b;
				border-right: 2px solid #ff167b;
				position: absolute;
			}
			&.slick-prev {
				left: 50%;
				transform: translate(-518px, -50%);
				@media screen and (max-width: 1040px) {
					left: 5px;
					transform: translate(0, -50%);
				}
				&:before {
					left: 2px;
					transform: translate(80%, -50%) rotate(135deg);
				}
			}
			&.slick-next {
				right: 50%;
				transform: translate(518px, -50%);
				@media screen and (max-width: 1040px) {
					right: 5px;
					transform: translate(0, -50%);
				}
				&:before {
					transform: translate(40%, -50%) rotate(-45deg);
					left: 1px;
				}
			}
		}
		.slick-dots {
			bottom: -30px;
			@media screen and (max-width: 767px) {
				bottom: -25px;
			}
			li {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background-color: #999999;
				margin: 0 16px;
				@include smooth-transition;
				@media screen and (max-width: 767px) {
					width: 10px;
					height: 10px;
					margin: 0 10px;
				}
				&.slick-active {
					background-color: #ff167b;
					@include smooth-transition;
				}
				button {
					display: none;
				}
			}
		}
		.slick-slide {
			&:not(.slick-current) {
				opacity: .5;
			}
		}
		.slide-items {
			position: relative;
			.cta {
				position: absolute;
				bottom: 12px;
				right: 0;
				@include smooth-transition;
				@media screen and (max-width: 767px) {
					bottom: 6px;
					right: 6px;
				}
				&:hover {
					opacity: .8;
					@include smooth-transition;
				}
				img {
					&.pc {
						@media screen and (max-width: 767px) {
							display: none;
						}
						&.img-scale{
							transform: scale(0.84);
						}
					}
					&.sp {
						width: 140px;
						height: 45px;
						@media screen and (min-width: 768px) {
							display: none;
						}
					}
				}
			}
		}
		img {
			width: 100%;
			&.sp {
				@media screen and (min-width: 768px) {
					display: none;
				}
			}
			&.pc {
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
		}
	}
}

.banner-sm {
	text-align: center;
	.txt-top {
		display: inline-block;
		color: #2b2b2b;
		font-weight: bold;
		position: relative;
		z-index: 1;
		padding: 0 40px;
		margin-bottom: 10px;
		@include font-size(24);
		@media screen and (max-width: 767px) {
			padding: 0 30px;
			margin-bottom: 5px;
			@include font-size(15);
		}
		&:before {
			display: block;
			content: '';
			background: url("../img/index/spark-left.png") no-repeat center center;
			width: 24px;
			height: 62px;
			background-size: 100% 100%;
			position: absolute;
			left: 0;
			top: 10%;
			transform: translateY(-50%);
			@media screen and (max-width: 767px) {
				top: 50%;
				width: 17px;
				height: 42px;
			}
		}
		&:after {
			display: block;
			content: '';
			background: url("../img/index/spark-right.png") no-repeat center center;
			width: 26px;
			height: 64px;
			background-size: 100% 100%;
			position: absolute;
			right: 0;
			top: 30%;
			transform: translateY(-50%);
			@media screen and (max-width: 767px) {
				top: 50%;
				width: 17px;
				height: 42px;
			}
		}
	}
	.txt-bottom {
		display: block;
		text-align: left;
		color: #666666;
		margin-top: 5px;
		@include font-size(14);
		@media screen and (max-width: 767px) {
			@include font-size(10);
		}
	}
	a img {
		margin: 0 auto;
		@include smooth-transition;
		&:hover {
			opacity: .7;
			@include smooth-transition;
		}
		&.pc {
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		&.sp {
			@media screen and (min-width: 768px) {
				display: none;
			}
		}
	}
	&.opacity-none{
		img{
			&:hover {
				opacity: 1;
			}
		}
	}
}

.box-intro {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.img {
		width: 268px;
	}
	.desc {
		width: calc(100% - 288px);
		line-height: 1.5;
		@include font-size(16);
		@media screen and (max-width: 767px) {
			@include font-size(12);
		}
	}
	@media screen and (max-width: 767px) {
		.img, .desc {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}

.accordion {
	.accordion-items {
		border: 1px solid #ff006e;
		border-bottom: none;
		margin-bottom: 40px;
		&:last-of-type {
			margin-bottom: 0;
		}
		.accordion-label {
			color: #ff006e;
			text-align: center;
			padding: 15px 50px;
			position: relative;
			border-bottom: 1px solid #ff006e;
			@include font-size(20);
			@media screen and (max-width: 767px) {
				padding: 0px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				@include font-size(18);
				small {
					display: block;
					width: 100%;
					@include font-size(13);
				}
				strong {
					display: block;
					width: 100%;
					margin-top: -10px;
				}
			}
			&:after {
				display: block;
				content: '';
				background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
				width: 15px;
				height: 10px;
				background-size: 100% 100%;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				@include smooth-transition;
				@media screen and (max-width: 767px) {
					right: 10px;
				}
			}
			&:hover {
				cursor: pointer;
			}
			&.is-clicked {
				&:after {
					transform: translateY(-50%) rotate(180deg);
					@include smooth-transition;
				}
			}
		}
		.accordion-content {
			display: none;
			padding: 30px 40px;
			border-bottom: 1px solid #ff006e;
			@media screen and (max-width: 767px) {
				padding: 20px 15px;
			}
			&.style02 {
				padding: 15px 40px 10px;
				background-color: #ffd0dc;
				@media screen and (max-width: 767px) {
					padding: 15px 10px 10px;
				}
			}
		}
	}
	&.accordion-faq {
		.accordion-items {
			border: none;
			margin-bottom: 0;
			.accordion-content {
				padding: 10px 20px 20px 20px;
			}
			.accordion-label {
				padding: 11px 25px;
			}
		}
	}
}

hr.divider {
	border: none;
	margin: 15px 0;
	padding: 0;
	width: 100%;
	height: 3px;
	background: url("../img/lineup/hiphop/line-pink.png") repeat-x center center;
	background-size: auto 3px;
}

.modal-container {
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	position: fixed;
	z-index: 10;
	display: none;
	.modal {
		max-width: 1000px;
		position: absolute;
		@include v-h-align;
		background-color: #fff;
		padding: 15px;
		border: 1px solid #ff006e;
		@media screen and (max-width: 1040px) {
			max-width: unset;
			width: 90%;
		}
		.modal-close {
			display: block;
			content: '';
			background: url("../img/index/modal-close.png") no-repeat center center;
			background-size: 100% 100%;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 10px;
			right: 10px;
		}
		.modal-ttl {
			text-align: center;
			margin-bottom: 14px;
			span {
				display: inline-block;
				color: #fb4875;
				@include font-size(16);
				position: relative;
				z-index: 1;
				@media screen and (min-width: 768px) {
					@include font-size(20);
				}
				&:before {
					display: block;
					content: '';
					width: 100%;
					height: 8px;
					background-color: #ffd0dc;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: -1;
				}
			}
		}
		&.modal-voice {
			.modal-content {
				.voice-list {
					margin-bottom: 35px;
					@media screen and (min-width: 768px) {
						margin-bottom: 45px;
					}
					.items {
						.img {
							float: left;
							width: 100px;
							position: relative;
							@media screen and (min-width: 768px) {
								width: 200px;
							}
							img {
								width: 100%;
							}
							.name-tag {
								display: block;
								width: 100%;
								color: white;
								background-color: #ffb94a;
								text-align: center;
								padding: 5px 10px;
								margin-top: -2px;
								@include font-size(12);
								@media screen and (min-width: 768px) {
									@include font-size(16);
								}
								&.hiphop {
									background-color: #f6698a;
								}
								&.hura {
									background-color: #ff5262;
								}
								&.burlesque {
									background-color: #9e61c5;
								}
								&.ballet {
									background-color: #83d3c9;
								}
								&.juzz {
									background-color: #ff8737;
								}
								&.tahiti {
									background-color: #83cf84;
								}
							}
						}
						.excerpt {
							float: right;
							width: calc(100% - 110px);
							@include font-size(12);
							@media screen and (min-width: 768px) {
								width: calc(100% - 210px);
								@include font-size(16);
							}
						}
					}
					.slick-arrow {
						&:before {
							display: none;
						}
						display: block;
						content: '';
						background: url("../img/index/voice-arrow-slide.png") no-repeat center center;
						background-size: 100% 100%;
						width: 16px;
						height: 27px;
						position: absolute;
						z-index: 1;
						bottom: -35px;
						top: auto;
						transform: translate(0, 0);
						@media screen and (min-width: 768px) {
							bottom: -40px;
						}
						&.slick-prev {
							left: 0;
							transform: translate(0, 0) rotate(180deg);
						}
						&.slick-next {
							right: 0;
						}
					}
				}
				.voice-list-02 {
					margin-bottom: 35px;
					@media screen and (min-width: 768px) {
						margin-bottom: 45px;
					}
					.items {
						.img {
							text-align: center;
							position: relative;
							width: 400px;
							margin: 0 auto;
							@media screen and (max-width: 767px) {
								width: 200px;
							}
							img {
								width: 100%;
								margin: 0 auto;
							}

						}
					}
					.slick-slide img {

					}
					.slick-arrow {
						&:before {
							display: none;
						}
						display: block;
						content: '';
						background: url("../img/index/voice-arrow-slide.png") no-repeat center center;
						background-size: 100% 100%;
						width: 16px;
						height: 27px;
						position: absolute;
						z-index: 1;
						bottom: -35px;
						top: auto;
						transform: translate(0, 0);
						@media screen and (min-width: 768px) {
							bottom: -40px;
						}
						&.slick-prev {
							left: 0;
							transform: translate(0, 0) rotate(180deg);
						}
						&.slick-next {
							right: 0;
						}
					}
				}
			}
		}
	}
}

/* ====================================================
Typography
==================================================== */
.txt-basic {
	color: #2b2b2b;
	@include font-size(16);
	@media screen and (max-width: 767px) {
		@include font-size(12);
	}
	&.txt-basic-pink {
		color: #fb4875;
	}
	&.txt-black {
		color: #000000;
	}
	&.lg {
		@include font-size(20);
		@media screen and (max-width: 767px) {
			@include font-size(12);
		}
	}
}

.txt-lead {
	@include font-size(20);
	@media screen and (max-width: 767px) {
		@include font-size(15);
	}
}

.txt-center {
	text-align: center;
}

.txt-right {
	text-align: right;
}

.txt-left {
	text-align: left;
}

.txt-sm {
	@include font-size(10);
	color: #2b2b2b;
}

.txt-while, .txt-white {
	color: #fff !important;
}

.txt-link {
	color: #ff006e;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		opacity: .7;
		@include smooth-transition;
	}
}

.txt {
	&-violet {
		color: #9e61c5;
	}
	&-yellow {
		color: #cb9f00;
	}
	&-orange {
		color: #cf8800;
	}
	&-red {
		color: #ff5262;
	}
	&-green {
		color: #6dc96e;
	}
	&-pink {
		color: #ff73ae;
	}
	&-dark-pink {
		color: #fb4875;
	}
	&-light-pink {
		color: #f6698a;
	}
	&-light-blu {
		color: #61b8ad;
	}
	&-light-orange {
		color: #ff8737;
	}
}

.txt-md {
	@include font-size(24);
	color: #2b2b2b;
	@media screen and (max-width: 767px) {
		@include font-size(12);
	}
}

.txt-pink-big {
	color: #fb4875;
	font-weight: 500;
	@include font-size(20);
	@media screen and (max-width: 767px) {
		@include font-size(16);
	}
}

.title {
	color: #2b2b2b;
	font-weight: 500;
	@include font-size(17.5);
}

.head {
	&.pc {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
	&.sp {
		@media screen and (min-width: 768px) {
			display: none;
		}
	}
}

.section-ttl {
	text-align: center;
	.caption-sm {
		display: inline-block;
		padding: 0 30px 0 35px;
		text-align: center;
		color: #2b2b2b;
		line-height: 1.3;
		@include font-size(24);
		position: relative;
		@media screen and (max-width: 767px) {
			padding: 0 20px 0 25px;
			@include font-size(16);
		}
		&.caption-sm-2 {
			@media screen and (max-width: 767px) {
				@include font-size(14);
			}
		}
		&:before {
			display: block;
			content: '';
			background: url("../img/index/star-pattern-left.png") no-repeat center center;
			width: 26px;
			height: 28px;
			background-size: 100% 100%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			@media screen and (max-width: 767px) {
				width: 18px;
				height: 19px;
			}
		}
		&:after {
			display: block;
			content: '';
			background: url("../img/index/star-pattern-right.png") no-repeat center center;
			width: 21px;
			height: 29px;
			background-size: 100% 100%;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			@media screen and (max-width: 767px) {
				width: 14px;
				height: 19px;
			}
		}
		.pinky {
			color: #fb4875;
		}
		&.caption-sm-02 {
			@media screen and (max-width: 767px) {
				@include font-size(14);
			}
		}
	}
	.caption-lg {
		background-color: #fb4875;
		color: white;
		padding: 15px 10px;
		@include font-size(36);
		position: relative;
		margin-top: 3px;
		@media screen and (max-width: 767px) {
			padding: 16px 10px;
			@include font-size(24);
		}
		&:before {
			display: block;
			content: '';
			width: 100%;
			height: 2px;
			background-color: #fb4875;
			position: absolute;
			left: 0;
			bottom: calc(100% + 3px);
			@media screen and (max-width: 767px) {
				height: 1px;
				bottom: calc(100% + 2px);
			}
		}
		small {
			@include font-size(28);
			@media screen and (max-width: 767px) {
				@include font-size(25);
			}
		}
		strong {
			font-weight: normal;
			@media screen and (max-width: 767px) {
				line-height: 1.1;
				@include font-size(24);
			}
		}
		&.style02 {
			background-color: #ffe1e9;
			position: relative;
			color: #fb4875;
			&:before, &:after {
				display: block;
				content: '';
				width: 100%;
				height: 2px;
				background-color: #fec2d1;
				border-top: 1px solid #fb4a77;
				border-bottom: 1px solid #fb4a77;
				position: absolute;
				left: 0;
			}
			&:before {
				bottom: 100%;
			}
			&:after {
				top: 100%;
			}
			span {
				display: inline-block;
				position: relative;
				padding: 0 30px;
				@media screen and (max-width: 767px) {
					padding: 0 20px 0 25px;
				}
				&:before {
					display: block;
					content: '';
					background: url("../img/index/star-pattern-left.png") no-repeat center center;
					width: 26px;
					height: 28px;
					background-size: 100% 100%;
					position: absolute;
					top: 0;
					left: 0;
					@media screen and (max-width: 767px) {
						width: 21px;
						height: 22px;
					}
				}
				&:after {
					display: block;
					content: '';
					background: url("../img/index/star-pattern-right-02.png") no-repeat center center;
					width: 23px;
					height: 36px;
					background-size: 100% 100%;
					position: absolute;
					top: 15px;
					right: 0;
					@media screen and (max-width: 767px) {
						width: 15px;
						height: 22px;
						top: 10px;
					}
				}
			}
		}
	}
}

/* ====================================================
Buttons, input, .etc
==================================================== */
.btn {
	display: inline-block;
	border: 1px solid #ff197c;
	background-color: #fff;
	color: #fb4875;
	text-decoration: none !important;
	padding: 10px 110px;
	@include font-size(16);
	@include smooth-transition;
	@media screen and (max-width: 767px) {
		padding: 10px 115px;
	}
	&.btn-arrow-right {
		position: relative;
		border-radius: 0;
		&:after {
			display: block;
			content: '';
			width: 8px;
			height: 8px;
			border-bottom: 2px solid #fb4875;
			border-right: 2px solid #fb4875;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%) rotate(-45deg);
		}
	}
	&.btn-arrow-bottom {
		position: relative;
		&:after {
			display: block;
			content: '';
			width: 8px;
			height: 8px;
			border-bottom: 2px solid #fb4875;
			border-right: 2px solid #fb4875;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
		}
	}
	&.btn-solid {
		color: white;
		background-color: #ff006e;
		&.btn-arrow-right {
			&:after {
				border-color: white;
			}
		}
	}
	&.btn-faq {
		@include font-size(18);
	}
	&:hover {
		opacity: .7;
		cursor: pointer;
		@include smooth-transition;
	}
	&.btn-arrow {
		position: relative;
		&:before {
			display: block;
			content: '';
			width: 13px;
			height: 8px;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			background: url(../img/index/arrow_down.png) no-repeat center center;
			background-size: cover;
		}
		&.close:before {
			background: url(../img/index/arrow_up.png) no-repeat center center;
			background-size: cover;
		}
	}

}

.slick-slide, .slick-slide * {
	outline: none !important;
}

.button {
	display: inline-block;
	background-color: #000;
	color: #fff;
	@include font-size(10);
	margin-right: 5px;
	padding: 2px 10px;
	border: 0;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	transition: all .3s ease;
	&.button-pink {
		background-color: #f6698a;
	}
	&.button-green {
		background-color: #83d3c9;
	}
	&.button-orange {
		background-color: #ffb94a;
	}
	&.button-red {
		background-color: #ff5262;
	}
	&.button-blu {
		background-color: #83cf84;
	}
	&.button-big {
		@include font-size(24)
	}
	&.button-big02 {
		@include font-size(25);
		@media(max-width: 767px) {
			@include font-size(12);
		}
	}
	&.button-big03 {
		@include font-size(28);
		@media(max-width: 767px) {
			@include font-size(14);
		}
	}
}

br {
	&.sp {
		@media screen and (min-width: 768px) {
			display: none;
		}
	}
	&.pc {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}

.sp {
	display: block;
	@media screen and (min-width: 768px) {
		display: none !important;
	}
	&-at-820 {
		display: none;
		@media screen and(max-width: 830px) {
			display: block !important;
		}
	}
}

.pc {
	display: block;
	@media screen and (max-width: 767px) {
		display: none !important;

	}
	&-in {
		display: inline-block;
		@media screen and (max-width: 767px) {
			display: none !important;
		}
	}
}

.margin-left-0 {
	margin-left: 0 !important;
}

.w100 {
	width: 100%;
}

.lh-1 {
	line-height: 1;
}

.bnr-map{
	margin-top: 20px;
	@media screen and (min-width: 768px) {
		margin-top:10px;
		/*display: flex;
		justify-content: center;
		align-items: center;
		background: #f9f2b0;
		border: 2px solid #ffc939;
		> div:first-of-type{
			width: 68.9%;
		}
		> div:last-of-type{
			width: 33.1%;
		}*/
	}
}
