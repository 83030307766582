.box-recruit{
	margin-bottom: 80px;
	@media screen and (max-width: 767px) {
		margin-bottom: 40px;
	}
	.img{
		float: left;
		width: 40%;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.txt{
		float: right;
		width: 60%;
		padding-top: 29px;
		padding-left: 20px;
		@media screen and (max-width: 767px) {
			width: 100%;
			padding: 0 0;
			margin-bottom: 15px;
		}
		.txt-basic{
			.block{
				display: block;
				margin-bottom: 10px;
			}
			.color-pink{
				font-weight: bold;
			}
		}
	}
}
.table-box{
	strong{
		@include font-size(16);
		margin-bottom: 10px;
		display: inline-block;
		@media screen and (max-width: 767px) {
			@include font-size(14);
		}
	}
	table{
		width: 100%;
		tr{
			th,td{
				border: 1px solid #999999;
				padding: 20px;
				vertical-align: top;
				@include font-size(14);
				@media screen and (max-width: 767px) {
					display: block;
					@include font-size(12);
					width: 100%;
					padding: 9px 17px;
				}
			}
			th{
				font-weight: bold;
				background: #ffe1e9;
				@media screen and (max-width: 767px) {
					border-bottom: none;
					border-top: none;
				}
			}
			@media screen and (max-width: 767px) {
				&:nth-child(1){
					border-top: 1px solid #999999;
				}
			}
		}
	}
}
.links{
	display: inline-block;
	position: relative;
	padding-left: 15px;
	text-decoration: underline;
	&:before{
		content: ">";
		position: absolute;
		top: 0;
		left: 0;
	}
}
.list-teacher{
	margin-bottom: 60px;
	@media screen and (max-width: 767px) {
		margin-bottom: 30px;
	}
	.item{
		position: relative;
		padding: 20px 0;
		overflow: hidden;
		&:before{
			content: "";
			height: 5px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			background: url("../img/contact/recruit/line.png")no-repeat;
			background-size: 100% 2px;
		}
		&:last-child{
			&:after{
				content: "";
				height: 5px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: url("../img/contact/recruit/line.png")no-repeat;
				background-size: 100% 2px;
			}
		}
		img{
			float: left;
			width: 20%;
		}
		p{
			float: right;
			width: 80%;
			padding-left: 20px;
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}
			&.txt-title{
				@include font-size(13);
			}
			&.txt-title{
				.color-pink{
					@include font-size(18);
				}
			}
		}
	}
}
.list-parent-teacher{
	margin: 20px -9px 30px;
	position: relative;
	overflow: hidden;
	&:after{
		content: "";
		height: 5px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: url("../img/contact/recruit/line.png")no-repeat;
		background-size: 100% 2px;
	}
	.item{
		float: left;
		padding: 0 9px;
		width: 16.666%;
		margin-bottom: 20px;
		@media screen and (max-width: 1024px) {
			width: 25%;
		}
		@media screen and (max-width: 767px) {
			width: 33.333%;
		}
		img{
			width: 100%;
			margin-bottom: 10px;
		}
	}
}

.page-recruit {
  .table-box table tr th,
  .table-box table tr td {
	border-color: #fb4875;
  }
  
  .index-section-02:after {
	display: none;
  }
  
  @media(min-width: 768px) {
	.dvd-box {
	  margin-left: -15px;
	  margin-right: -15px;
	  overflow: hidden;
	  
	  .image {
		width: 50%;
		float: left;
		padding: 0 15px;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
		
		&:last-child {
		  margin-top: 10px;
		}
		
		img {
		  width: 100%;
		  max-width: 100%
		}
	  }
	}
  }
}