.lineup-tahitians-summary-section-02 {
	.ttl {
		img {
			width: 485px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}
}
