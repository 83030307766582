.list-item-basic{
	padding-top: 40px;
	margin: 0 -10px;
	@media only screen and (max-width: 768px) {
		margin:0;
		padding-top: 20px;
	}
	.item{
		float: left;
		width:50%;
		padding:0 10px 0;
		margin-bottom: 40px;
		&:last-child{
			background: none;
			margin-bottom: 12px;
		}
		@media only screen and (max-width: 768px) {
			padding:0 0 18px;
			width:100%;
			margin-bottom: 20px;
			float: none;
			background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
		}
		.item-img{
			position: relative;
			.ct-center{
				position: absolute;
				left:50%;
				top:50%;
				transform: translate(-50%,-50%);
				display: inline-block;
				background: #ff0030;
				font-weight: 600;
				color: #fff;
				padding: 10px;
				min-width: 165px;
				text-align: center;
				@include font-size(16);
				@media only screen and (max-width: 768px) {
					min-width:125px;
					padding:5px;
					@include font-size(13);
				}
			}
		}
		.link-basic{
			color: #f6698a;
			text-decoration: underline;
			padding: 9px 0 6px;
			display: block;
			@include font-size(20);
			@media only screen and (max-width: 768px) {
				@include font-size(16);
			}
		}
		.txt-basic{
			@include font-size(16);
			@media only screen and (max-width: 768px) {
				@include font-size(12);
			}
		}
	}
}
.txt-fz-12{
	@media only screen and (max-width: 768px) {
		@include font-size(12);
	}
}
.btn-basic{
	margin: 20px;
	@media only screen and (max-width: 768px) {
		margin: 0px;
	}
	@media only screen and (max-width: 530px) {
		width:100%;
		padding:10px;
	}
}