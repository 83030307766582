.step-form{
	margin: 0 -9px 20px;
	display: flex;
	justify-content: center;
	.item{
		float: left;
		padding: 0 9px;
		img{
			&.on{
				display: none;
			}
			&.off{
				display: block;
			}
		}
		&.active{
			img{
				&.on{
					display: block;
				}
				&.off{
					display: none;
				}
			}
		}
	}
}
.show-form{
	background: #fff2f9;
	padding: 40px 0;
	margin-bottom: 40px;
	@media screen and (max-width: 767px) {
		padding: 20px 0;
		margin-bottom: 20px;
	}
}
.form{
	@include font-size(14);
	@media screen and (max-width: 767px) {
		@include font-size(12);
	}
	dl{
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		&.block{
			display: block;
			overflow: hidden;
		}
		@media screen and (max-width: 767px) {
			display: block;
			overflow: hidden;
		}
		dt{
			color: #fb4875;
			float: left;
			width: 30%;
			@media screen and (max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
			}
			label{
				font-weight: bold;
				span{
					display: inline-block;
					@include font-size(10);
					border: 1px solid #fb4875;
					border-radius: 2px;
					margin-left: 7px;
					line-height: 1;
					padding: 2px;
					font-weight: 400;
				}
			}
		}
		dd{
			float: left;
			width: 70%;
			@media screen and (max-width: 767px) {
				width: 100%;
				.txt-basic{
					@include font-size(10);
				}
			}
			.list-time{
				display: flex;
				justify-content: center;
				@media screen and (max-width: 767px) {
					display: block;
				}
				li{
					width: 33.333%;
					overflow: hidden;
					@media screen and (max-width: 767px) {
						&:nth-child(1){
							width: 100%;
							margin-bottom: 10px;
						}
						&:nth-child(2),&:nth-child(3){
							width: 50%;
							float: left;
						}
					}
					select{
						float: left;
						width: calc(100% - 30px);
					}
					span{
						float: right;
						width: 30px;
						text-align: center;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						@media screen and (max-width: 767px) {
							@include font-size(12);
						}
					}
				}
			}
		}
	}
	a{
		text-decoration: underline;
	}
	.btn{
		background: #ff006e;
		color: white;
		@media screen and (max-width: 767px) {
			padding: 17px 10px;
			width: 100%;
		}
		&.btn-arrow-right{
			&:after{
				border-bottom: 2px solid white;
				border-right: 2px solid white;
			}
		}
	}
}
.form-control{
	display: block;
	width: 100%;
	@include font-size(14);
	border: 1px solid #999999;
	background: white;
	padding: 5px 10px;
	height: 40px;
	@media screen and (max-width: 767px) {
		@include font-size(12);
	}
	&::-webkit-input-placeholder {
		color: #2b2b2b;
	}
	&:-moz-placeholder {
		color: #2b2b2b;
	}
	&::-moz-placeholder {
		color: #2b2b2b;
	}
	&:-ms-input-placeholder {
		color: #2b2b2b;
	}
}
textarea.form-control{
	height: 120px!important;
}
select.form-control{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	background: white url(../img/contact/reference/select.png) no-repeat;
	background-size: 40px;
	background-position: right;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&::-ms-expand {
		display: none;
	}
}
.note{
	font-weight: bold;
	text-align: center;
	@include font-size(16);
	@media screen and (max-width: 767px) {
		@include font-size(12);
		text-align: left;
	}
}