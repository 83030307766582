.dance-costume{
	background: #83cf84;
	padding: 30px 0 40px;
	margin-top: 45px;
	@media only screen and (max-width: 768px) {
		padding: 15px 0 20px;
	}
	.ttl{
		text-align: center;
		.ttl-costume{
			text-align: center;
			color: #fff;
			letter-spacing: -3px;
			padding: 0 30px;
			display: inline-block;
			position: relative;
			margin: 0 auto 26px;
			@include font-size(30);
			&:before{
				display: block;
				content: '';
				background: url(../img/lineup/tahitian/summary/icon-left.png) no-repeat center center;
				width: 24px;
				height: 35px;
				background-size: 100% 100%;
				position: absolute;
				left: 0;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
			&:after{
				display: block;
				content: '';
				background: url(../img/lineup/tahitian/summary/icon-right.png) no-repeat center center;
				width: 24px;
				height: 35px;
				background-size: 100% 100%;
				position: absolute;
				right: 0;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
			span{
				position: relative;
				z-index:2;
				&:after{
					display: block;
					content: '';
					width: 100%;
					height: 10px;
					background:rgba(#b5e5b6,0.7);
					position: absolute;
					bottom: 0px;
					left: 0;
					z-index: 1;
					-webkit-transform: skewY(-1deg);
					-ms-transform: skewY(-1deg);
					transform: skewY(-1deg) ;
				}
			}
			@media only screen and (max-width: 768px) {
				letter-spacing: -3px;
				margin: 0 auto 13px;
				@include font-size(20);
			}
		}
	}
	.txt-costume{
		padding-top: 20px;
		@include font-size(16);
		span{
			color: #fff;
			padding-top: 7px;
			display: block;
		}
		@media only screen and (max-width: 768px) {
			padding-top: 10px;
			@include font-size(12);
		}
	}
}
.w100{
	width: 100%;
}

.style-q01{
	@media only screen and (max-width: 768px) {
		padding:16px 0 !important;
	}
}