

.txt-fz{
	@media only screen and (max-width: 768px) {
		@include font-size(12);
	}
}
.media-item{
	.ttl {
		@media only screen and (max-width: 768px) {
			margin-bottom: 12px;
		}
	}
	.items{
		.img{
			width: 30.23%;
			float: left;
			img{
				width: 100%;
			}
		}
		.txt-md{
			width: 69.77%;
			float: right;
			padding-left: 30px;
			@include font-size(16);
			@media only screen and (max-width: 768px) {
				padding-left: 15px;
				@include font-size(12);
			}
		}
	}
	@media only screen and (min-width: 768px) {
		.ct-pr-label{
			margin-bottom: 40px;
		}
	}
}
.btn-media{
	margin-bottom: 90px;
	margin-top: 80px;
	@media only screen and (max-width: 768px) {
		margin-bottom: 92px;
		margin-top: 15px;
	}
	@media only screen and (max-width: 510px) {
		padding: 10px;
		width:100%;
	}
}

.page-media {
  .txt-dark-pink {
	font-weight: 700;
  }
}
