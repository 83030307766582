.flow-section-01 {
	.btn-container {
		display: flex;
		justify-content: center;
		.btn {
			margin: 20px;
			@media screen and (max-width: 767px) {
				margin: 5px 0;
			}
		}
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
			.btn {
				width: 100%;
			}
		}
	}
	.steps-block.style02 {
		.arrows {
			@media screen and (min-width: 768px) {
				align-items: flex-start;
				padding-top: 160px;
			}
		}
	}
}


.page-flow .mov-wrap{
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
	.mov-wrap-inner{	
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
		iframe{
			position:absolute;
			top:0;
			right:0;
			width:100%;
			max-width: 600px;
			height:100%;	
		}
	}
}
