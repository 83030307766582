.txtBlog01{
  color: #ff006e;
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: underline;
  text-underline-position: under;
}

.page-blog-index {
  .dance-list-02 .items a .desc .txt-top {
    @include font-size(16)
  }
  
  .dance-list-02 .items {
    width: 48%;
    box-sizing: border-box;
  
    @media screen and (max-width: 767px) {
      width: 48%;
    }
  }
  
  .dance-list-02 .items a .img {
    width: 350px;
    img {
      width: 100%;
    }
  }
  .dance-list-02 .items a .desc {
    padding: 15px 15px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  
}