.lineup-belly-section-01 {
	padding-top: 50px;
	position: relative;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
		.section-ttl {
			.caption-lg {
				padding: 5px 10px 7px;
				@include font-size(18);
				small {
					@include font-size(18);
					@media(max-width: 374px) {
						@include font-size(14);
					}
				}
				strong {
					@include font-size(20);
					@media(max-width: 374px) {
						@include font-size(16);
					}
				}
			}
		}
	}
	&:before {
		display: block;
		content: '';
		background: url("../img/lineup/belly/dance-girl-img.png") no-repeat center center;
		width: 222px;
		height: 487px;
		background-size: 100% 100%;
		position: absolute;
		top: 70px;
		right: 50%;
		z-index: 1;
		transform: translateX(240%);
		@media screen and (max-width: 1100px) {
			width: 200px;
			height: 439px;
			top: 140px;
		}
		@media screen and (max-width: 1000px) {
			width: 140px;
			height: 307px;
			top: 190px;
			right: 40%;
		}
		@media screen and (max-width: 840px) {
			right: 45%;
		}
		@media screen and (max-width: 767px) {
			width: 81px;
			height: 177px;
			transform: translateX(230%);
			top: 10px;
		}
		@media screen and (max-width: 480px) {
			right: 45%;
		}
		@media screen and (max-width: 400px) {
			right: 46%;
			width: 70px;
			height: 150px;
		}
		@media screen and (max-width: 374px) {
			right: 52%;
		}
	}
	.accordion-items {
		.dance-list-03 {
			.items-brt {
				padding-top: 18px;
				border-top: 1px solid #ff006e;
			}
		}
	}
}

.fix-font {
	font-size: 17px !important;
	@media screen and (max-width: 1050px) {
		font-size: 14px !important;
	}
	@media screen and (max-width: 767px) {
		font-size: 11px !important;
	}
}

// Summary page

.lineup-belly-summary-section-01 {
	margin-bottom: 40px;
}

.lineup-belly-summary-section-02 {
	margin-bottom: 40px;
	background-color: #ffb94a;
	padding: 40px;
	@media screen and (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;
	}
	.ttl {
		text-align: center;
		margin-bottom: 20px;
		img {
			@media screen and (min-width: 768px) {
				width: 500px;
				height: auto;
			}
		}
	}
}

.lineup-belly-summary-section-03, .lineup-belly-basic-section-03 {
	.btn-container {
		display: flex;
		justify-content: center;
		.btn {
			margin: 20px;
			@media screen and (max-width: 767px) {
				margin: 5px 0;
				padding: 13px 25px;
			}
		}
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
			.btn {
				width: 100%;
			}
		}
	}
}

.page-lineup-belly_summary {
  .pt-14 {
	padding-top: 14px;
  }
  
  .pb-8 {
	padding-bottom: 8px;
  }
  
  .lineup-belly-summary-section-02 .box-intro {
	margin-top: -16px;
  }
}

// Basic page

.dance-list-05 {
	@media screen and (min-width: 768px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.item {
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		@media screen and (min-width: 768px) {
			&.sm {
				width: 48%;
			}
			&.lg {
				width: 100%;
			}
		}
		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 3px;
			background: url(../img/lineup/hiphop/line-pink.png) repeat-x center center;
			background-size: auto 3px;
			position: absolute;
			left: 0;
			bottom: 0;
			@media screen and (min-width: 768px) {
				display: none;
			}
		}
		&:last-child {
			margin-bottom: 0;
		  	padding-bottom: 10px;
			&:after {
				display: none;
			}
		}
		.img {
			margin-bottom: 10px;
			img {
				width: 100%;
			}
		}
		.desc {
			a {
				color: #f6698a;
				text-decoration: underline;
				@include font-size(16);
				@media screen and (min-width: 768px) {
					@include font-size(20);
				  	display: inline-block;
				  	margin-bottom: 14px;
				}
			}
			p {
				@include font-size(12);
				@media screen and (min-width: 768px) {
					@include font-size(14);
				}
			}
			.pink{
				color: #f6698a;
				@include font-size(16);
				@media screen and (min-width: 768px) {
					@include font-size(20);
				  	display: inline-block;
				  	margin-bottom: 14px;
				}
			}
		}
	}
}

.page-lineup-belly_basic {
  @media screen and (max-width: 767px) {
	.section-ttl .caption-lg {
	  padding: 9px 10px;
	}
  }
}




