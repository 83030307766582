.siteMap-inner{
  padding: 0px 0px 30px;
  @media screen and(min-width: 768px){
    padding: 0px 0px 30px;
  }
  .siteMap-inner-item{
    margin: 21px 0px;
    
    @media screen and(min-width: 768px){
      margin: 35px 0 10px;
    }
    a{
      text-decoration: underline;
      background: #ffffff;
      border: 1px solid #ff006e;
      border-bottom: none;
      color: #ff006e;
      position: relative;
      padding: 11px 20px 11px 30px;
      text-underline-position: under;
      font-size: 12px;
      font-size: 1.2rem;
      display: block;

      @media screen and(min-width: 768px){
        padding: 23px 40px 23px 60px;
        font-size: 2.4px;
        font-size: 2.4rem;
      }
      &:first-child{
        background: #ffe1e9;
      }
      &:last-child{
        border-bottom: 1px solid #ff006e;
      }
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        width: 5px;
        height: 8px;
        background: url("../img/sitemap/li.png");
        background-repeat: no-repeat;
        background-size: 5px 8px;
        transform: translate(-50%, -50%);
        @media screen and(min-width: 768px){
          left: 40px;
          width: 10px;
          height: 16px;
          top: 56%;
          background-size: 8px 12px;
        }
      }
    }
    
    .bg-light {
      background-color: #fff !important;
    }
  }
}

.page-site-map {
  @media(min-width: 768px) {
    .siteMap-inner {
      margin-left: -15px;
      margin-right: -15px;
      overflow: hidden;
    }

    .siteMap-wrap-item {
      width: 50%;
      padding: 0 15px;
      box-sizing: border-box;
      float: left;
    }
  
    .siteMap-inner .siteMap-inner-item a {
      font-size: 16px;
      padding-top: 13px;
      padding-bottom: 13px;
    }
    
    
  }
}