.page-privacy,
.page-rule,
.page-site
{
    .text
    {
        font-size: 12px;
        @media(min-width: 768px)
        {
            font-size: 16px;
        }
    }
    
    .txt-12 {
        font-size: 12px;
        font-weight: 700;
        @media(min-width: 768px) {
            font-size: 16px;
        }
    }

    .box-text
    {
        margin-bottom: 24px;
        @media(min-width: 768px)
        {
            margin-bottom: 48px;
        }

        .box-title
        {
            font-size: 14px;
            margin-bottom: 10px;
            padding-bottom: 6px;
            background: url(../img/lineup/hiphop/line-pink.png) repeat-x center bottom;
            @media(min-width: 768px)
            {
                font-size: 28px;
                margin-bottom: 20px
            }
        }
    }

    .list-txt
    {
        font-size: 12px;
        @media(min-width: 768px)
        {
            font-size: 16px;
        }

        li
        {
            position: relative;

            padding-left: 12px;

            &:before
            {
                content: '.';
                position: absolute;
                top: -3px;
                left: 2px;
            }
        }
    }
    
    .table-info {
        width: 100%;
        td {
            border: 1px solid #fb4875;
            padding: 7px 11px;
            width: 50%;
            font-size: 12px;
            @media(min-width: 768px) {
                padding: 14px 22px;
                font-size: 24px;
            }
        }
        
        tr {
            td:first-child {
                width: 45.5%
            }
            td:last-child {
                width: 54.5%
            }
        }
        
        .tr-pink td{
            background-color: #ffe1e9;
        }
    }
}

.page-site .box-text .box-title {
    @media(min-width: 768px) {
        font-size: 20px;
    }
}
.f-sz-20{
    @media(min-width: 768px) {
        font-size: 20px !important;
    }
}
.fz-16{
    border-right: 1px solid #fb4875 !important;
    tr,td{
        @media(min-width: 768px) {
            font-size: 16px !important;
            width: 50%;
            float: left;
            border-bottom: 0 !important;
            border-right: 0px !important;
        }
    }
    tr{
       &:nth-child(7){
           border-bottom: 1px solid #fb4875 !important;
       }
        &:nth-child(8){
            border-bottom: 1px solid #fb4875 !important;
        }
    }
}
.table-fz-16{
    tr,td {
        @media(min-width: 768px) {
            font-size: 16px !important;
        }
    }
}

