// Section settings
.lineup-hiphop-section-01 {
	padding-top: 50px;
	position: relative;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
	}
	&:before {
		display: block;
		content: '';
		background: url("../img/lineup/hiphop/dance-girl-img.png") no-repeat center center;
		width: 204px;
		height: 414px;
		background-size: 100% 100%;
		position: absolute;
		top: 130px;
		left: 50%;
		z-index: 1;
		transform: translateX(-245%);
		@media screen and (max-width: 767px) {
			width: 80px;
			height: 164px;
			transform: translateX(-230%);
			top: 20px;
		}
	}
	span.pc {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
	.section-ttl {
		.caption-lg {
			line-height: 1.2;
			letter-spacing: -2px;
			strong, small {
				font-weight: normal;
				@media screen and (max-width: 767px) {
					@include font-size(20);
				}
			}
			.font-19 {
				@media screen and (max-width: 767px) {
					@include font-size(19);
				}
			}
			.font19 {
				@media screen and (max-width: 1000px) {
					@include font-size(19);
				}
			}
		}
	}
}

.lineup-hiphop-section-02 {
	padding-top: 50px;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
	}
	.caption-lg {
		@media screen and (max-width: 767px) {
			padding: 8px 10px 10px;
			line-height: 1.2;
			@include font-size(13);
		}
	}
	&.style02 {
		.caption-lg {
			@media screen and (max-width: 767px) {
				@include font-size(24);
			}
		}
	}
}

.lineup-hiphop-section-03 {
	padding-top: 50px;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
	}
	.section-ttl {
		.caption-lg {
			padding: 22px 10px 25px;
			line-height: 1.2;
			@media screen and (max-width: 767px) {
				padding: 4px 10px 10px;
			}
			small {
				@media screen and (max-width: 767px) {
					@include font-size(13);
				}
			}
		}
	}
}

.lineup-hiphop-section-04 {
	padding-top: 40px;
	@media screen and (max-width: 767px) {
		padding-top: 20px;
	}
	.section-ttl {
		.caption-lg {
			line-height: 1.2;
			@media screen and (max-width: 767px) {
				padding: 4px 10px 12px;
			}
			small {
				@media screen and (max-width: 767px) {
					@include font-size(13);
				}
			}
		}
	}
}

.lineup-hiphop-section-05 {
	padding-bottom: 40px;
	@media screen and (max-width: 767px) {
		padding-bottom: 20px;
	}
}

// Box
.section-heading {
	text-align: center;
	p {
		display: inline-block;
		padding: 5px 10px;
		line-height: 1.2;
		position: relative;
		@include font-size(40);
		@media screen and (max-width: 767px) {
			@include font-size(20);
		}
		small {
			@include font-size(26);
			@media screen and (max-width: 767px) {
				@include font-size(13);
			}
		}
		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 20px;
			background-color: #ffd0dc;
			position: absolute;
			bottom: 5px;
			left: 0;
			z-index: -1;
			transform: rotate(-1deg);
			@media screen and (max-width: 767px) {
				height: 10px;
			}
		}
	}
	img {
		display: block;
		margin: 20px auto;
		max-width: 510px;
		width: 100%;
		@media screen and (max-width: 767px) {
			margin: 10px auto 12px;
		}
	}
}

.dance-list-03 {
	.items {
		margin-bottom: 30px;
		.item-inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			@media screen and (max-width: 767px) {
				display: block;
			}
			.img {
			  	width: 350px;
				img {
				  width: 100%;
				}
				
				@media screen and (max-width: 767px) {
					width: 100%;
					margin-bottom: 10px;
					
				}
			}
			.desc {
			  	width: calc(100% - 370px);
				
				@media screen and (max-width: 767px) {
					width: 100%;
				}
				.excerpt {
					color: #000;
					line-height: 1.5;
					@include font-size(16);
					@media screen and (max-width: 767px) {
						@include font-size(11);
					}
				}
			}
		}
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		.ttl {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			@media screen and (max-width: 767px) {
				margin-bottom: 5px;
			}
			img {
				width: 39px;
				height: 39px;
			}
			p {
				padding-left: 10px;
				color: #fb4875;
				font-weight: bold;
				@include font-size(20);
				text-align: left;
				@media screen and (max-width: 767px) {
					@include font-size(14);
				}
			}
		}
		.flex-row {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;
			.img {
				width: 31.34%;
				img {
					width: 100%;
				}
			}
			.desc {
				width: 64.18%;
				.excerpt {
					color: #000;
					line-height: 1.5;
					@include font-size(16);
					@media screen and (max-width: 767px) {
						@include font-size(11);
					}
				}
			}
		}
	}
}

.lineup-hiphop-intro {
	.ttl-lg {
		display: flex;
		justify-content: center;
		text-align: center;
		padding: 11px 20px;
		@include font-size(20);
		@media screen and (max-width: 767px) {
			padding: 5px;
			height: 40px;
			align-items: center;
			@include font-size(18);
		}
		&.style01 {
			color: white;
			background-color: #fb4875;
		}
		&.style02 {
			color: #fb4875;
			background-color: #ffd0dc;
		}
		img {
			margin: 0 10px;
			@media screen and (max-width: 767px) {
				width: 18px;
				height: 22px;
			}
		}
	}
	.txt-basic {
		@include font-size(16);
		@media screen and (max-width: 767px) {
			@include font-size(10);
		}
	}
	.desc-list-lv1 {
		margin: 10px 0 0;
		> dt {
			display: inline-block;
			color: #fb4875;
			margin-bottom: 5px;
			position: relative;
			@include font-size(20);
			height: 28px;
			@media screen and (max-width: 767px) {
				@include font-size(15);
				height: 21px;
			}
			@media screen and (max-width: 350px) {
				@include font-size(13);
				height: 18px;
			}
			&:after {
				display: block;
				content: '';
				width: 100%;
				height: 10px;
				background-color: #ffe2e9;
				position: absolute;
				bottom: 3px;
				left: 0;
				z-index: -1;
				@media screen and (max-width: 767px) {
					height: 7px;
				}
				@media screen and (max-width: 350px) {
					height: 5px;
				}
			}
		}
		> dd {
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
			.desc-list-lv2 {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				> dt, > dd {
					color: #000;
					@include font-size(16);
					@media screen and (max-width: 767px) {
						@include font-size(10);
					}
				}
				.img-right {
					float: right;
					margin-left: 10px;
					margin-bottom: 10px;
					@media screen and (max-width: 767px) {
						margin-bottom: 5px;
					}
				}
				.img-left {
					float: left;
					margin-right: 10px;
					margin-bottom: 10px;
					@media screen and (max-width: 767px) {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}

.lineup-hiphop-intro-02 {
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.blockL {
		width: 56%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 15px;
		}
		.img {
			width: 135px;
			@media screen and (max-width: 767px) {
				width: 67px;
			}
		}
		.excerpt {
			width: calc(100% - 150px);
			color: #2b2b2b;
			@include font-size(20);
			@media screen and (max-width: 767px) {
				width: calc(100% - 82px);
				@include font-size(14);
			}
		}
	}
	.blockR {
		width: 40%;
		@media screen and (max-width: 767px) {
			width: 100%;
			text-align: center;
		}
	}
}

.lineup-hiphop-intro-03 {
	.map-preview {
		margin: 0;
		padding: 0;
		figcaption {
			display: flex;
			justify-content: space-between;
			.txt {
				width: calc(100% - 235px);
				@media screen and (max-width: 767px) {
					width: calc(100% - 135px);
				}
			}
			.cta {
				width: 220px;
				@media screen and (max-width: 767px) {
					width: 110px;
					text-align: right;
				}
				.btn {
					padding: 10px 60px;
					@media screen and (max-width: 767px) {
						padding: 10px 20px 10px 8px;
						display: inline-block;
						width: 102px;
						@include font-size(13);
						&.btn-arrow-right {
							&:after {
								right: 6px;
							}
						}
					}
				}
			}
		}
	}
	ul {
		li {
			position: relative;
			padding-left: 15px;
			&:before {
				display: block;
				content: '＊';
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	a {
		text-decoration: underline;
		@include smooth-transition;
		&:hover {
			opacity: .7;
			text-decoration: none;
			@include smooth-transition;
		}
	}
	.txt {
		@include font-size(14);
		@media screen and (max-width: 767px) {
			@include font-size(11);
		}
	}
	.ttl {
		margin-bottom: 15px;
		@media screen and (max-width: 767px) {
			margin-bottom: 10px;
		}
		span {
			display: inline-block;
			position: relative;
			color: #fb4875;
			padding: 0 10px;
			@include font-size(20);
			@media screen and (max-width: 767px) {
				padding: 0 5px;
				@include font-size(15);
			}
			&:after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background-color: #ffd0dc;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: -1;
				transform: skewY(-3deg);
				@media screen and (max-width: 767px) {
					height: 10px;
				}
			}
		}
	}
	table {
		th, td {
			border: 2px solid #fb4875;
			padding: 15px 10px;
			@include font-size(14);
			@media screen and (max-width: 767px) {
				padding: 10px;
				@include font-size(11);
				padding: 5px 10px;
			}
			@media screen {
				border-width: 1px;
			}
		}
		th {
			text-align: center;
			background-color: #ffe1e9;
		}
	}
}

.person-list-02 {
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.items {
		display: flex;
		justify-content: space-between;
		width: 49%;
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.img {
			width: 180px;
			@media screen and (max-width: 767px) {
				width: 90px;
			}
		}
		.excerpt {
			text-align: justify;
			width: calc(100% - 200px);
			@include font-size(14);
			@media screen and (max-width: 767px) {
				width: calc(100% - 100px);
				@include font-size(10);
			}
		}
	}
}

.steps-block {
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.items {
		width: 30%;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		.ttl {
			display: flex;
			align-items: flex-end;
			background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
			padding-bottom: 10px;
			margin-bottom: 15px;
			width: 100%;
			img {
				transform: scale(1.1, 1.1);
				-ms-transform: scale(1.1, 1.1);
				-webkit-transform: scale(1.1, 1.1);
			}
			@media screen and (max-width: 767px) {
				position: relative;
				padding-left: 65px;
				padding-bottom: 5px;
				img {
					width: 55px;
					position: absolute;
					left: 0;
					top: 20%;
					transform: translateY(-50%);
				}
			}
			p {
				margin-top: 5px;
				display: block;
				font-weight: bold;
				color: #fb4875;
				margin-left: 10px;
				@include font-size(20);
				@media screen and (max-width: 767px) {
					margin-top: 0;
					margin-left: 0;
					@include font-size(14);
				}
			}
		}
		.desc {
			@media screen and (max-width: 767px) {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
			.img {
				text-align: center;
				@media screen and (max-width: 767px) {
					width: 158px;
				}
			}
			.excerpt {
				margin-top: 20px;
				@include font-size(16);
				@media screen and (max-width: 767px) {
					margin-top: 10px;
					width: calc(100% - 168px);
					@include font-size(11);
				}
			}
		}
	}
	.arrows {
		width: 5%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 .5%;
		@media screen and (max-width: 767px) {
			width: 100%;
			margin: 15px 0 10px;
			img {
				transform: rotate(90deg);
				height: 25px;
			}
		}
		&:before {
			display: block;
			content: '';
			width: 0;
			height: 0;
			background-color: transparent;
		}
	}
	&.style02 {
		.ttl {
			padding-bottom: 20px;
			@media screen and (max-width: 767px) {
				padding-bottom: 10px;
			}
		}
		.desc {
			.img {
				@media screen and (max-width: 767px) {
					width: 100px;
				}
			}
			.excerpt {
				@media screen and (max-width: 767px) {
					margin-top: 0;
					width: calc(100% - 115px);
				}
			}
		}
	}
	&.style03 {
		flex-wrap: wrap;
		.items, .arrows {
			width: 100%;
		}
		.arrows {
			margin-top: 20px;
			img {
				transform: rotate(90deg);
			}
		}
		.flex-row {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.img {
				width: 200px;
			}
			.desc {
				display: flex;
				align-content: center;
				flex-wrap: wrap;
				width: calc(100% - 225px);
				@media screen and (max-width: 767px) {
					flex-wrap: wrap;
					align-content: center;
				}
				.ttl {
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					padding-left: 0;
					img {
						position: relative;
						top: auto;
						left: auto;
						margin-bottom: 5px;
						transform: translate(0, 0);
					}
					p {
						margin-left: 15px;
						@media screen and (max-width: 767px) {
							margin-left: 5px;
						}
					}
				}
				.excerpt {
					margin-top: 0;
				}
			}
		}
		.items.sp {
			.desc {
				.img {
					@media screen and (max-width: 767px) {
						width: 100px;
					}
				}
				.excerpt {
					@media screen and (max-width: 767px) {
						width: calc(100% - 115px);
						margin-top: 0;
					}
				}
			}
		}
	}
}

.report-block {
	background-color: #fffaa3;
	padding: 0 40px 40px;
	position: relative;
	min-height: 240px;
	@media screen and (max-width: 767px) {
		padding: 0 20px 20px;
		min-height: auto;
	}
	.ttl {
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		padding: 10px 0;
		width: 73.91%;
		margin-bottom: 25px;
		background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
		@media screen and (max-width: 767px) {
			width: 100%;
			text-align: left;
			justify-content: flex-start;
			margin-bottom: 15px;
		}
		img {
			margin: 0 20px;
			@media screen and (max-width: 767px) {
				margin: 0 15px 0;
				width: 62px;
			}
		}
		p {
			color: #fb4875;
			font-weight: bold;
			@include font-size(20);
			@media screen and (max-width: 767px) {
				@include font-size(16);
			}
		}
	}
	.excerpt {
		width: 73.91%;
		overflow: hidden;
		@include font-size(16);
		@media screen and (max-width: 767px) {
			width: 100%;
			@include font-size(11);
		}
		strong {
			@include font-size(20);
			@media screen and (max-width: 767px) {
				@include font-size(11);
			}
		}
		img {
			@media screen and (min-width: 768px) {
				width: 19.9%;
				position: absolute;
				top: 10%;
				right: 4%;
			}
			@media screen and (max-width: 767px) {
				float: right;
				width: 100px;
				margin-left: 10px;
			}
		}
	}
}

.full-width-banner {
	background-color: #ffe1e9;
	padding: 20px 0;
	position: relative;
	@media screen and (max-width: 767px) {
		padding: 10px 0;
	}
	&:before {
		display: block;
		content: '';
		width: 100%;
		height: 0;
		border-top: 2px solid #ffe1e9;
		border-bottom: 2px solid white;
		position: absolute;
		bottom: 100%;
		left: 0;
	}
	.container {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-right: 230px;
		@media screen and (max-width: 767px) {
			padding-right: 160px;
		}
		@media screen and (max-width: 445px) {
			padding-right: 140px;
		}
	}
	small {
		color: #fb4875;
		@include font-size(28);
		font-weight: bold;
		@media screen and (max-width: 767px) {
			@include font-size(13);
		}
	}
	strong {
		display: inline-block;
		color: #fb4875;
		position: relative;
		z-index: 1;
		line-height: 1.2;
		padding-right: 10px;
		@include font-size(36);
		@media screen and (max-width: 767px) {
			@include font-size(25);
			display: block;
		}
		@media screen and (max-width: 445px) {
			@include font-size(18);
		}
		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 20px;
			background-color: #fff;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;
			@media screen and (max-width: 767px) {
				height: 15px;
			}
			@media screen and (max-width: 445px) {
				height: 9px;
			}
			@media screen and (max-width: 350px) {
				height: 5px;
			}
		}
	}
	img {
		position: absolute;
		bottom: -20px;
		right: 20px;
		@media screen and (max-width: 767px) {
			width: 130px;
			bottom: -10px;
		}
	}
}

.form-container {
	.form-ttl {
		display: block;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		max-width: 1040px;
		padding: 0 20px 0 65px;
		@media screen and (max-width: 767px) {
			padding-left: 20px;
			margin-bottom: 25px;
		}
		&:before {
			display: block;
			content: '';
			background: url("../img/lineup/hiphop/shield-icon.png") no-repeat center center;
			background-size: 100% 100%;
			width: 58px;
			height: 63px;
			position: absolute;
			top: -10px;
			left: 0;
			@media screen and (max-width: 767px) {
				top: 2px;
				width: 24px;
				height: 27px;
				left: 16px;
			}
		}
		.txt-top {
			color: #fb4875;
			@include font-size(22);
			@media screen and (max-width: 767px) {
				font-weight: bold;
				padding-left: 25px;
				padding-top: 10px;
				@include font-size(14);
			}
			&.fix-fonts {
				@media screen and (max-width: 767px) {
					@include font-size(10);
				}
			}
		}
		.txt-bottom {
			color: #000;
			@include font-size(16);
			@media screen and (max-width: 767px) {
				@include font-size(11);
			}
		}
	}
	.form-content {
		background-color: #fff2f9;
		padding: 40px 0;
		@media screen and (max-width: 768px) {
			padding: 20px 0;
		}
		.form-group {
			margin-bottom: 35px;
			@media screen and (max-width: 768px) {
				margin-bottom: 15px;
			}
			&:last-of-type {
				margin-bottom: 20px;
				@media screen and (max-width: 768px) {
					margin-bottom: 10px;
				}
			}
		}
		.txt-sm {
			color: #000;
		}
		.label {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 15px;
			/*padding-bottom: 10px;
			background: url("../img/lineup/hiphop/line-pink.png") repeat-x center bottom;
			background-size: auto 3px;*/
			@media screen and (max-width: 767px) {
				background: none;
				margin-bottom: 5px;
				padding-bottom: 5px;
			}
			.tag {
				border: 2px solid;
				padding: 0 5px;
				border-radius: 3px;
				margin-left: 15px;
				@include font-size(18);
				@media screen and (max-width: 767px) {
					border-width: 1px;
					margin-left: 10px;
					@include font-size(10);
				}
				&.required {
					color: #ff006e;
					border-color: #ff006e;
				}
			}
		}
		.txt-lg {
			font-weight: bold;
			@include font-size(20);
			@media screen and (max-width: 767px) {
				@include font-size(12);
			}
		}
		.txt-sm {
			margin-left: 10px;
			@include font-size(16);
			@media screen and (max-width: 767px) {
				@include font-size(10);
			}
		}
		.form-group .row:nth-of-type(2) .txt-sm {
			margin-left: 0;
		}
		.flex-row {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.col-24p {
				width: 24%;
				@media screen and (max-width: 767px) {
					width: 49%;
				}
			}
			.col-49p {
				width: 49%;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
		}
		.custom-radio {
			input[type="radio"] {
				appearance: none;
				display: none;
				+ label {
					display: flex;
					align-items: center;
					content: '';
					height: 100%;
					color: #2b2b2b;
					background-color: white;
					border: 1px solid #999999;
					padding: 19px 15px 19px 70px;
					position: relative;
					border-radius: 0;
					@include font-size(16);
					@media screen and (max-width: 767px) {
						padding: 9px 15px 9px 45px;
						@include font-size(11);
					}
					@media screen and (max-width: 374px) {
						padding: 9px 15px 9px 35px;
						@include font-size(11);
					}
					&:before {
						display: block;
						content: '';
						width: 15px;
						height: 15px;
						border: 4px solid #787878;
						border-radius: 50%;
						background-color: #fff;
						position: absolute;
						@include v-align;
						left: 20px;
						@media screen and (max-width: 767px) {
							border-width: 2px;
							width: 13px;
							height: 13px;
							left: 10px;
						}
					}
					&:after {
						display: block;
						content: '';
						width: 7px;
						height: 7px;
						background-color: #fb4875;
						border-radius: 50%;
						position: absolute;
						@include v-align;
						left: 28px;
						opacity: 0;
						visibility: hidden;
						@include smooth-transition;
						@media screen and (max-width: 767px) {
							left: 15px;
						}
					}
				}
				&:checked + label:after {
					opacity: 1;
					visibility: visible;
					@include smooth-transition;
				}
			}
		}
		.custom-select {
			position: relative;
			border: 1px solid #999999;
			height: 64px;
			@media screen and (max-width: 768px) {
				height: auto;
			}
			&:before {
				display: block;
				content: '';
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-top: 13px solid #535353;
				border-bottom: none;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 19px;
				z-index: 1;
				@media screen and (max-width: 768px) {
					right: 10px;
				}
			}
			&:after {
				display: block;
				content: '';
				background-color: #cccccc;
				width: 62px;
				height: 62px;
				position: absolute;
				top: 0;
				right: 0;
				pointer-events: none;
				@media screen and (max-width: 767px) {
					width: 40px;
					height: 40px;
					background-size: 14px 8px;
				}
			}
			select {
				appearance: none;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 0;
				border: none;
				padding: 19px 15px;
				background-color: white;
				color: #2b2b2b;
				@include font-size(16);
				@media screen and (max-width: 767px) {
					padding: 12px 15px;
					height: 40px;
					@include font-size(11);
					line-height: 1;
					box-sizing: border-box;
				}
				&:focus {
					outline: none;
				}
			}
		}
		.custom-input {
			position: relative;
			.input-placeholder {
				position: absolute;
				@include v-align;
				left: 0;
				width: 100%;
				padding: 0 20px;
				color: #2b2b2b;
				@include font-size(16);
				pointer-events: none;
				@media screen and (max-width: 767px) {
					@include font-size(11);
				}
			}
			input[type='text'], input[type='tel'], input[type='email'] {
				appearance: none;
				display: block;
				width: 100%;
				background-color: white;
				padding: 19px 15px;
				border: 1px solid #999999;
				border-radius: 0;
				color: #2b2b2b;
				@include font-size(16);
				@media screen and (max-width: 767px) {
					padding: 12px 15px;
					@include font-size(11);
				}
				&:focus {
					outline: none;
				}
			}
		}
		.form-notice {
			@include font-size(14);
			@media screen and (max-width: 767px) {
				@include font-size(9);
			}
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			&.style02 {
				li {
					position: relative;
					padding-left: 10px;
					&:before {
						content: "※";
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	.form-cta {
		.btn {
			padding: 15px 70px;
			@media screen and (max-width: 767px) {
				padding-left: 15px;
				padding-right: 15px;
				width: 100%;
			}
		}
	}
}

.video-container {
	display: block;
	max-width: 670px;
	margin-left: auto;
	margin-right: auto;
	.videoWrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
		text-align: center;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		video {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	img {
		@media screen and (max-width: 767px) {
			padding: 0 20px;
			margin-bottom: 10px;
		}
	}
}

.video-wrapper {
	background-color: #fffaa3;
	padding: 15px 20px 40px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	@media screen and (max-width: 767px) {
		padding: 15px 20px;
	}
	.ttl-top {
		width: 100%;
		text-align: center;
		color: #fb4875;
		margin-bottom: 5px;
		@include font-size(24);
		@media screen and (max-width: 767px) {
			@include font-size(18);
		}
	}
	.ttl-bottom {
		width: 100%;
		text-align: center;
		margin-bottom: 5px;
		@include font-size(16);
		@media screen and (max-width: 767px) {
			@include font-size(12);
		}
	}
}

.page-lineup-jazz_index {
	.lineup-hiphop-section-01 {
		.section-ttl {
			.caption-lg {
				@media screen and (max-width: 767px) {
					padding: 7px 10px;
				}
				small {
					@media screen and (max-width: 767px) {
						@include font-size(18);
					}
				}
				strong {
					@media screen and (max-width: 370px) {
						letter-spacing: -3px;
					}
				}
			}
		}

	}
	.index-section-04 {
		.section-ttl {
			.caption-lg {
				@media screen and (max-width: 767px) {
					padding: 1px 10px 3px;
				}
			}
		}
	}
	.lineup-hiphop-section-04 {
		.section-ttl {
			.caption-lg {
				@media screen and (max-width: 767px) {
					padding: 4px 10px 10px;
				}
			}
		}
	}
}

.page-lineup-hiphop-summary {
  @media screen and (max-width: 767px) {
	.dance-list-03 .items .ttl {
	  margin-bottom: 10px;
	}
 
	.dance-list-03 .items .item-inner .img,
	.dance-list-03 .items .item-inner .desc {
	  width: 100%;
	}
 
	.dance-list-03 .items {
	  margin-bottom: 20px;
	}
	
	.lineup-belly-summary-section-01 {
	  margin-bottom: 30px;
	}
  }
}

.page-lineup-hiphop_basic {
  .list-item-basic .item {
	width: 50%;
	padding: 0 10px 0;
	float: left;
	margin-bottom: 40px;
  }
  @media only screen and (max-width: 767px) {
	.list-item-basic .item {
	  padding: 20px 0;
	  margin: 0;
  	}
  
	.section-ttl .caption-lg {
	  padding: 9px 10px;
	}
 
	.list-item-basic .item {
	  width: 100%;
	  float: none;
	}
  }
  
  @media(min-width: 768px) {
	.list-item-basic .item {
	  background-image: none;
	}
  }
  
  
  
  @media(max-width: 800px) {
	.btn-basic {
	  width: 100%;
	  margin-bottom: 25px;
	}
  }
}


@media screen and(max-width: 768px){
  .hChange{
	.dance-list-03 .items .item-inner .desc{
	  width: auto;
	}
  }
}