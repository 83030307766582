.page-hawaii {
  .btn-block {
	@media(max-width: 767px) {
	  width: 100%;
	  padding-left: 0;
	  padding-right: 0;
	}
  }
  
  .point-list-block > .items .info .ttl {
	margin-bottom: 10px;
  }
  
  @media screen and (max-width: 767px) {
	.section-ttl .caption-lg {
	  padding: 9px 10px;
	}
  }
  
  .btn.btn-arrow:before {
	background-image: url('../img/index/voice-arrow-slide.png');
	height: 13px;
  }
  
  .banner-sm .txt-top {
	font-weight: 600;
  }
}
/*.hawaii-point{
	display: table;
	width: 100%;
	.info, .img{
		display: table-cell;
		vertical-align: top;
	}
	.info{
		padding-right: 10px;
	}
	.img{
		width: 170px;
	}
}*/