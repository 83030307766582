.point-list-block {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	position: relative;
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
		margin-bottom: 0;
	}
	> .items {
		width: 30%;
		@media screen and (max-width: 767px) {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.info {
			.ttl {
				margin-bottom: 15px;
				text-align: center;
				img {
					max-width: 188px;
					height: auto;
				}
			}
			@media screen and (max-width: 767px) {
				width: 60%;
				padding-right: 10px;
				.ttl {
					text-align: left;
					img {
						max-width: 170px;
					}
				}
			}
			.excerpt {
				margin-bottom: 15px;
				.txt-top {
					color: #fb4875;
					line-height: 1.4;
					margin-bottom: 5px;
					font-weight: bold;
					@include font-size(20);
					@media screen and (max-width: 767px) {
						@include font-size(15);
					}
				}
				.txt-bottom {
					color: #2b2b2b;
					@include font-size(16);
					@media screen and (max-width: 767px) {
						@include font-size(11);
					}
					&.txt-bottom-fix {
						@media screen and (max-width: 767px) {
							@include font-size(11);
						}
					}
				}
			}
		}
		.img {
			text-align: center;
			@media screen and (max-width: 767px) {
				width: 40%;
				img {
					width: 100%;
				}
			}
		}
	}
}

.btn-show-hidden {
	position: relative;
	padding: 6px 28px 6px 10px;
	z-index: 2;
	@include font-size(13);
	border: 0!important;
	text-decoration: underline!important;
	&.sp {
		@media screen and (min-width: 768px) {
			display: none !important;
		}
	}
	&.pc, &.pc-in {
		border-width: 2px;
		&.is-clicked {
			border-bottom-color: white;
			transition: ease 0ms;
		}
		&:hover {
			opacity: 1 !important;
			transition: ease 0ms;
		}
		@media screen and (max-width: 767px) {
			display: none !important;
		}
	}
	&:after {
		display: block;
		content: '';
		background: url("../img/lineup/hiphop/accor-arrow-down.png") no-repeat center center;
		width: 14px;
		height: 9px;
		background-size: 100% 100%;
		position: absolute;
		right: 10px;
		@include v-align;
		@include smooth-transition;
	}
	&.is-clicked {
		&:after {
			transform: translateY(-50%) rotate(180deg);
			@include smooth-transition;
		}
	}
}

.hidden-content {
	display: none;
}

.point-list-child-content {
	width: 100%;
	background-color: #fff;
	&.sp {
		margin-top: 15px;
		z-index: 1;
		@media screen and (min-width: 768px) {
			display: none !important;
		}
	}
	&.pc {
		/*margin-top: -2px;*/
		@media screen and (max-width: 767px) {
			display: none !important;
		}
		border: 2px solid #ff006e;
		padding: 40px;
		width: 100%;
		position: absolute;
		left: 0;
		z-index: 1;
	}
	.ttl {
		text-align: center;
		margin-bottom: 10px;
		.ttl-top {
			padding: 0 5px;
			@include font-size(15);
			position: relative;
			z-index: 1;
			@media screen and (min-width: 768px) {
				@include font-size(19);
			}
			&:after {
				display: block;
				content: '';
				width: 100%;
				height: 10px;
				background-color: #ffd0dc;
				position: absolute;
				bottom: -2px;
				left: 0;
				z-index: -1;
				transform: skewY(-1deg);
			}
			&.no-stroke {
				&:after {
					display: none;
				}
			}
		}
		.ttl-bottom {
			@include font-size(20);
			@media screen and (min-width: 768px) {
				@include font-size(25);
			}
			&.stroke {
				position: relative;
				z-index: 1;
				&:after {
					display: block;
					content: '';
					width: 100%;
					height: 10px;
					background-color: #ffd0dc;
					position: absolute;
					bottom: -2px;
					left: 0;
					z-index: -1;
					transform: skewY(-1deg);
				}
			}
		}
	}
	.flex-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
		}
		.boxL {
			width: 45.65%;
			@media screen and (max-width: 767px) {
				width: 100%;
				margin-bottom: 13px;
			}
		}
		.boxR {
			width: 52.72%;
			@media screen and (max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
			}
		}
		.img-list {
			margin: 0;
			&.col-2 {
				@media screen and (min-width: 768px) {
					padding-right: 11.5%;
				}
			}
		}
	}
	.img-list {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		margin: 15px 0 10px;
		img {
			@media screen and(max-width: 767px) {
				width: 100%;
			}
		}
		&.col-3 {
			.items {
				width: 31.43%;
			}
			&.col-3-fix {
				.items {
					width: 32.3%;
				}
			}
		}
		&.col-2 {
			.items {
				width: 47.76%;
			}
			&.col-2-fix {
				.items {
					width: 48.76%;
				}
			}
		}
	}
	.caption-bottom {
		color: #2b2b2b;
		@include font-size(12);
		@media screen and (min-width: 768px) {
			@include font-size(16);
		}
	}
}

.dance-list {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.items {
		width: 32%;
		@include smooth-transition;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		&:hover {
			opacity: .7;
			@include smooth-transition;
		}
		img {
			width: 100%;
		}
	}
}

.dance-list-02 {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media screen and (max-width: 767px) {
		padding: 0 20px;
	}
	.items {
		width: 32%;
		margin-bottom: 20px;
		@include smooth-transition;
		@media screen and (max-width: 767px) {
			width: 48%;
		}
		&:hover {
			opacity: .7;
			@include smooth-transition;
		}
		a {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
			.img {
				width: 150px;
				@media screen and (max-width: 767px) {
					width: 100%;
					margin-bottom: 10px;
					img {
						width: 100%;
					}
				}
			}
			.desc {
				width: calc(100% - 160px);
				@media screen and (max-width: 767px) {
					width: 100%;
				}
				.txt-top {
					line-height: 1.2;
					@include font-size(20);
					@media screen and (max-width: 767px) {
						@include font-size(16);
					}
				}
				.txt-bottom {
					color: #000;
					@include font-size(15);
					@media screen and (max-width: 767px) {
						@include font-size(12);
					}
				}
			}
		}
	}
}

.dance-list-04 {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.items {
		width: 31.34%;
		margin-bottom: 2.99%;
		figure {
			margin: 0;
			img {
				width: 100%;
			}
			figcaption {
				text-align: center;
				text-decoration: underline;
				@include font-size(16);
				margin-top: 5px;
				@media screen and (max-width: 767px) {
					@include font-size(12);
				}
			}
		}
	}
	&.dance-list-04-fix {
		.items {
			margin-bottom: 2.3%;
		}
	}
}

.person-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.items {
		width: 9.5%;
		margin-bottom: .6%;
		@media screen and (max-width: 767px) {
			width: 19%;
			margin-bottom: 1.2%;
		}
		img {
			width: 100%;
		}
		a {
			display: block;
		}
	}
}
.page-index {
	.scrollbar {
		/*@media screen and (min-width: 768px) {
			height: 245px;
			overflow-y: scroll;
		}*/
		@media screen and (max-width: 768px) {
			overflow-y: scroll;
		}
	}
}

.gallery-list {
	padding: 0 10px 0 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media screen and (max-width: 767px) {
		padding: 0;
	}
	.items {
		width: 12%;
		margin-bottom: 2.15%;
		@media screen and (max-width: 767px) {
			width: 32.39%;
			margin-bottom: 1.8%;
		}
		&.sp {
			@media screen and (min-width: 768px) {
				display: none;
			}
		}
	}
}

.quick-menu-link {
	background-color: #ffd0dc;
	padding: 40px 0;
	@media screen and (max-width: 767px) {
		padding: 20px 0;
	}
	ul {
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
			margin-bottom: -2.98%;
		}
		li {
			display: flex;
			margin-right: 1.2%;
			@media screen and (max-width: 767px) {
				margin-right: 0;
				margin-bottom: 2.98%;
			}
			&:last-of-type {
				margin-right: 0;
			}
			a {
				display: block;
				width: 100%;
				text-align: center;
				@include smooth-transition;
				img {
					width: 100%;
				}
				&:hover {
					opacity: .7;
					@include smooth-transition;
				}
			}
			@media screen and (max-width: 767px) {
				&.menu-lg {
					width: 48.5%;
				}
				&.menu-sm {
					width: 31.34%;
				}
			}
		}
	}
}

// Margin, padding, other settings.
.index-section-01 {
	text-align: center;
	@media screen and (max-width: 767px) {
		.container {
			padding: 0 20px;
		}
	}
}

.index-section-02 {
	padding: 50px 0;
	position: relative;
	@media screen and (max-width: 767px) {
		padding: 25px 0 45px;
	}
	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 6px;
		background: url("../img/index/stripe-divider.png") repeat-x center center;
		background-size: auto 6px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.section-ttl {
		margin-bottom: 30px;
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
		}
	}
	.btn-container {
		margin-bottom: 45px;
		@media screen and (max-width: 767px) {
			margin-bottom: 25px;
			.btn {
				width: 100%;
				padding-left: 15px;
				padding-right: 15px;
				@include font-size(18);
			}
		}
	}
}

.index-section-03 {
	padding-top: 30px;
	.section-ttl {
		margin-bottom: 30px;
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
		}
		.caption-sm {
			position: relative;
			padding: 0 25px;
			@include font-size(32);
			@media screen and (max-width: 767px) {
				@include font-size(13);
				padding: 0 10px;
				&:before {
					display: block;
					content: '';
					width: 78px;
					height: 39px;
					background: url("../img/index/dance-lineup-txt.png") no-repeat right top;
					background-size: cover;
					position: absolute;
					left: 96%;
					top: 40%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
				}
			}
			&:after {
				display: none;
			}
			@media screen and (min-width: 768px) {
				&:before {
					display: block;
					content: '';
					background: url("../img/index/star-pattern-left.png") no-repeat center center;
					width: 26px;
					height: 28px;
					background-size: 100% 100%;
					position: absolute;
					left: 0;
					top: 8%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
				}
				&:after {
					display: block;
					content: '';
					background: url("../img/index/star-pattern-right.png") no-repeat center center;
					width: 21px;
					height: 29px;
					background-size: 100% 100%;
					position: absolute;
					right: 0;
					top: 50%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
				}
			}
			@media screen and (max-width: 767px) {
				.icn-sparcle {
					position: relative;
					padding: 0 20px;
					&:before {
						display: block;
						content: '';
						background: url("../img/index/star-pattern-left.png") no-repeat center center;
						width: 20px;
						height: 22px;
						background-size: 100% 100%;
						position: absolute;
						left: 0;
						top: 8%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
					&:after {
						display: block;
						content: '';
						background: url("../img/index/star-pattern-right.png") no-repeat center center;
						width: 18px;
						height: 26px;
						background-size: 100% 100%;
						position: absolute;
						right: 0;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
			}
			.pinky {
				position: relative;
				@include font-size(36);
				@media screen and (max-width: 767px) {
					@include font-size(25);
				}
				@media screen and (min-width: 768px) {
					&:before {
						display: block;
						content: '';
						width: 116px;
						height: 58px;
						background: url("../img/index/dance-lineup-txt.png") no-repeat right top;
						background-size: cover;
						position: absolute;
						right: -165px;
						top: 0;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	.container {
		@media screen and (max-width: 767px) {
			padding: 0;
		}
	}
}

.index-section-04 {
	padding-top: 30px;
	padding-bottom: 45px;
	@media screen and (max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 25px;
	}
	.section-ttl {
		margin-bottom: 40px;
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
			.caption-lg {
				padding: 4px 10px 10px;
				small {
					@include font-size(14);
				}
				strong {
					line-height: 1;
				}
			}
		}
	}
	.btn-container {
		margin-top: 14px;
	}
}

.index-section-05 {
	padding-bottom: 50px;
	@media screen and (max-width: 767px) {
		padding-bottom: 30px;
	}
	.section-ttl {
		margin-bottom: 40px;
		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}
		.caption-lg {
			@media screen and (max-width: 767px) {
				padding: 6px 10px 10px;
			}
			small {
				@include font-size(32);
				@media screen and (max-width: 767px) {
					@include font-size(13);
					strong {
						@include font-size(25);
					}
				}
			}
		}
	}
	.btn-container {
		@media screen and (max-width: 767px) {
			padding-top: 15px;
			.btn {
				padding-left: 90px;
				padding-right: 90px;
			}
		}
	}
}

.index-section-extra {
	padding-bottom: 50px;
	@media screen and (max-width: 767px) {
		padding-bottom: 25px;
	}
	.quick-menu-link {
		margin-bottom: 60px;
		@media screen and (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
}

//Width of modal

.page-index {
	.modal-container .modal {
		width: 90%;
		@include pfs('max-width', (
			320px: 320px,
			768px: 500px
		))
	}
}