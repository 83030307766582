.exper-post{
	padding:40px 0;
	@media screen and (max-width: 767px) {
		padding:20px 0;
	}
	.img{
		float: left;
		width: 250px;
		@media screen and (max-width: 767px) {
			width: 30%;
		}
	}
	.ttl{
		width: calc(100% - 250px);
		padding-left: 30px;
		float: right;
		@media screen and (max-width: 767px) {
			width: 68%;
			padding-left: 8px;
		}
		.ttl-exper{
			padding-bottom: 20px;
			color: #fb4875;
			font-weight: 600;
			@include font-size(24);
			@media screen and (max-width: 767px) {
				padding-bottom: 8px;
				@include font-size(12);
			}
			span{
				position: relative;
				&:after{
					display: block;
					content: '';
					width: 100%;
					height: 10px;
					background-color: #ffd0dc;
					position: absolute;
					bottom: -2px;
					left: 0;
					z-index: -1;
					-webkit-transform: skewY(-1deg);
					-ms-transform: skewY(-1deg);
					transform: skewY(-1deg);
				}
			}
		}
		.txt-exper{
			@include font-size(20);
			@media screen and (max-width: 767px) {
				@include font-size(12);
			}
		}
	}
}
.accordion-faq{
	@media screen and (max-width: 767px) {
		border-top: 1px solid #ff006e;
	}
	.accordion-items{
		@media screen and (max-width: 767px) {
			border: none;
		}
		.accordion-label{
			margin-top: -1px;
			@media screen and (min-width: 768px) {
				border: 1px solid #ff006e;
			}
			@media screen and (max-width: 767px) {
				padding: 0 20px;
				height: 38px;
				justify-content:start;
				@include font-size(12);
			}
		}
		.accordion-content{
			@media screen and (min-width: 768px) {
				border-right: 1px solid #ff006e;
				border-left: 1px solid #ff006e;
			}
			ul{
				li{
					width: 48.7%;
					float: left;
					&:nth-child(2n){
						float: right;
					}
					.ttl-acc{
						font-weight: 600;
						padding-bottom: 5px;
						@include font-size(20);
						@media screen and (max-width: 767px) {
							padding-bottom: 7px;
							@include font-size(12);
						}
						&.color-ltbrown{
							color: #e69d11;
						}
						&.color-kpop{
							color: #c8a900;
						}
						&.color-pink{
							color: #ff5262;
						}
						&.color-teal{
							color: #4cbcae;
						}
						&.color-orange{
							color: #ff8737;
						}
					}
					.items{
						&.color-ltbrown{
							.top{
								background-color: #e69d11;
							}
							.bottom{
								border: 2px solid #e69d11;
								color:#e69d11;
							}
						}
						&.color-kpop{
							.top{
								background-color: #c8a900;
							}
							.bottom{
								border: 2px solid #c8a900;
								color:#c8a900;
							}
						}
						&.color-pink{
							.top{
								background-color: #ff5262;
							}
							.bottom{
								border: 2px solid #ff5262;
								color:#ff5262;
							}
						}
						&.color-teal{
							.top{
								background-color: #4cbcae;
							}
							.bottom{
								border: 2px solid #4cbcae;
								color:#4cbcae;
							}
						}
						&.color-orange{
							.top{
								background-color: #ff8737;
							}
							.bottom{
								border: 2px solid #ff8737;
								color:#ff8737;
							}
						}
						.top {
							height: 42px;
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;
							color: white;
							padding: 2px 2px 2px 3px;
							line-height: 42px;
							padding: 0 20px;
							@include font-size(20);
							@media screen and (max-width: 767px) {
								height: 25px;
								line-height: 25px;
								padding: 0 11px;
								@include font-size(12);
							}
						}
						.bottom {
							background-color: #fff;
							padding: 2px 2px 2px 3px;
							position: relative;
							height: 46px;
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
							line-height: 46px;
							padding: 0 20px;
							@include font-size(20);
								@media screen and (max-width: 767px) {
									height: 25px;
									line-height: 25px;
									padding: 0 11px;
									@include font-size(12);
								}
							&:after{
								content: "";
								display: block;
								background: url(../img/index/shield-sm-green-yellow.png);
								background-size: 100% 100%;
								height: 20px;
								width: 16px;
								position: absolute;
								right: 6px;
								top: 50%;
								transform: translateY(-50%);
								@media screen and (max-width: 767px) {
									height: 14px;
									width: 11px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.border-bt{
	@media screen and (max-width: 767px) {
		border-bottom: 0px !important;
	}
	padding-bottom: 5px !important;
}

.phone-expor{
	margin-bottom: 50px;
	margin-top: 20px;
	text-align: center;
	@media screen and (max-width: 767px) {
		margin-top: 10px;

	}
}

.page-experience-index {
  .accordion .accordion-items .accordion-label {
	text-align: left;
  }
  
  .accordion-faq .accordion-items .accordion-content ul li .ttl-acc,
  .accordion-faq .accordion-items .accordion-content ul li .items .top,
  .accordion-faq .accordion-items .accordion-content ul li .items .bottom {
	font-size: 16px;
  }
  
  ul li {
	list-style-type:none;
  }
  
  .accordion-faq .accordion-items .accordion-content ul li .items.color-ltbrown .bottom,
  .accordion-faq .accordion-items .accordion-content ul li .items.color-kpop .bottom {
	border-width: 1px;
  }
  
  @media screen and (max-width: 320px) {
	.phone-expor a .number-phone {
	  font-size: 14px;
	}
  }
}