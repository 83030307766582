.lineup-hula-section-01 {
	padding-top: 50px;
	position: relative;
	@media screen and (max-width: 767px) {
		padding-top: 25px;
		.section-ttl {
			.caption-lg {
				padding: 5px 10px 7px;
				* {
					@include font-size(19);
				}
			}
		}
	}
	&:before {
		display: block;
		content: '';
		background: url("../img/lineup/hula/dance-girl-img.png") no-repeat center center;
		width: 246px;
		height: 444px;
		background-size: 100% 100%;
		position: absolute;
		top: 65px;
		left: 50%;
		z-index: 1;
		transform: translateX(-237%);
		@media screen and (max-width: 767px) {
			width: 93px;
			height: 168px;
			transform: translateX(-230%);
			top: 24px;
		}
		@media screen and (max-width: 375px){
			top: 40px;
			left: 52%;
		}
	}
}

.lineup-hula-summary-section-01 {
	padding-bottom: 60px;
	@media screen and (max-width: 767px) {
		padding-bottom: 30px;
	}
}

.lineup-hula-summary-section-02 {
	background-color: #ff5f71;
	margin-bottom: 40px;
	padding: 20px;
	.ttl {
		img {
			width: 485px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}
}

.lineup-hula-summary-section-03, .lineup-hula-basic-section-02 {
	.btn-container {
		display: flex;
		justify-content: center;
		.btn {
			margin: 20px;
			@media screen and (max-width: 767px) {
				margin: 5px 0;
				padding: 10px 25px;
			}
		}
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
			.btn {
				width: 100%;
			}
		}
	}
}

.page-lineup-hula_summary {
  .lineup-hula-summary-section-02 {
	margin-bottom: 60px
  }
  @media screen and (max-width: 767px) {
	.dance-list-03 .items .item-inner .desc {
	  width: 100%;
	}
 
	.dance-list-03 .items .item-inner .img {
	  width: 100%;
	}
	
	.lineup-hula-summary-section-02 {
	  padding: 20px 0;
	}
 
	.lineup-hula-summary-section-03 .btn-container .btn {
	  padding: 13px 25px;
	}
  }
  
  @media(min-width: 768px) {
	.lineup-hula-summary-section-03 .btn {
	  padding: 10px 60px;
	}
  }
  
  @media(min-width: 1000px) {
	.lineup-hula-summary-section-03 .btn {
	  padding: 10px 110px;
	}
  }
}

.page-lineup-hula_basic {
  @media screen and (max-width: 767px) {
	.section-ttl .caption-lg {
	  padding: 9px 10px;
	}
 
	.dance-list-05 .item:after {
	  display: none;
	}
 
	.lineup-hula-basic-section-02 .btn-container .btn {
	  padding: 13px 25px;
	}
	
	.dance-list-05 .item {
	  margin: 0;
	  padding-bottom: 5px;
	}
  }
}
